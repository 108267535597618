import React, { Component } from 'react';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ImageList from 'modules/imageCapture/components/imageList/index';
import Paper from '@material-ui/core/Paper';
import { apiHost } from 'api2';
import { apiKey } from 'config';
import { dataDogInit } from 'utils/logger';
import { getEnv } from 'utils/ChurnZero';

let churnzeroTimer = null

export class App extends Component {
  state = {
    open: false,
    loading: true,
  };

  componentDidMount() {
    console.info('Current Environment:',apiHost) // eslint-disable-line
    dataDogInit()
    // churnzero account setting
    churnzeroTimer = setInterval(() => {
      if (window.ChurnZero) {
        window.ChurnZero.push(['setAppKey', getEnv() === 'prod' ? apiKey.churnzero.prod : apiKey.churnzero.dev]);
        clearInterval(churnzeroTimer)
        this.setState({
          loading: false,
        });
      }
    }, 100)
  }

  componentWillUnmount() {
    if (churnzeroTimer) {
      clearInterval(churnzeroTimer)
    }
  }

  handleToggle = () => {
    const { location: { pathname } } = this.props;
    const page = pathname.split('/')[1];

    if (page === 'conference') {
      this.setState((prevstate) => {
        this.props.openSidebar({ open: !prevstate.open });
        return { open: !prevstate.open };
      });
    }
  };

  render() {
    const {
      classes,
      children
    } = this.props;

    const { open, loading } = this.state;

    return (
      <div className={classes.container}>

        <Drawer
            open={open}
            width={200}
            variant="persistent"
            anchor="left"
        >
          <Paper>
            <IconButton onClick={this.handleToggle}>
              <ChevronLeftIcon />
            </IconButton>
            <ImageList />
          </Paper>
        </Drawer>

        {!loading && children}
      </div>
    );
  }
}
