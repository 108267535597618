import * as React from 'react';

import * as Modal from './Modal';

class NoDevicesModal extends React.PureComponent {
  render() {
    const { isOpen } = this.props;

    return (
      <>
        <Modal.Wrapper
          backdrop
          keyboard
          isOpen={isOpen}
          size="sm"
        >
          <Modal.Header>
            <div><b>No camera or microphone found</b></div>
          </Modal.Header>
          <Modal.Body>
            <p>
              TeleDent requires access to your camera or microphone so participants can see or hear you. <br />
              Please close this window and rejoin with an active camera or microphone
            </p>
          </Modal.Body>
        </Modal.Wrapper>
      </>
    );
  }
}

export default NoDevicesModal;
