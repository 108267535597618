import { acceptingParticipantsSelector, vcInfoSelector, waitingParticipantsSelector } from '../../../../selectors/videoRoom';

import WaitingItem from './WaitingItem';
import { acceptParticipantIoT } from 'modules/chat/actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useStyles } from './WaitingItem-styles';
import { withStyles } from '@material-ui/core';

const mapStateToProps = (state) => ({
  waitingParticipants: waitingParticipantsSelector(state),
  vcInfo: vcInfoSelector(state),
  acceptingParticipants: acceptingParticipantsSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  acceptParticipant: (participant) => dispatch(acceptParticipantIoT(participant)),
});

export default compose(
  withStyles(useStyles),
  connect(mapStateToProps, mapDispatchToProps)
)(WaitingItem);
