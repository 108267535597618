import React, { useEffect } from "react";

import MicSpeakingSvg1 from "../../../../../../assets/mic-speaking1.svg";
import MicSpeakingSvg2 from "../../../../../../assets/mic-speaking2.svg";
import MicSpeakingSvg3 from "../../../../../../assets/mic-speaking3.svg";

const TalkingAnimeIcon = () => {
  const [index, setIndex] = React.useState(0)

  useEffect(() => {
    const interval = setInterval(() => setIndex(prev => prev + 1), 400)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const getSource = () => {
    if (index % 3 === 0) return MicSpeakingSvg1
    if (index % 3 === 1) return MicSpeakingSvg2
    return MicSpeakingSvg3
  }
  return (
    <img
      src={getSource()}
      alt="talking"
    />
  );
};

export default TalkingAnimeIcon;
