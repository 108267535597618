import { get } from 'lodash';

export const currentUserSelector = ({ login: state }) => get(state, 'userInfo');
export const authTokenSelector = ({ token: state }) => `Bearer ${get(state, 'token.token')}`;
export const accountRolesSelector = ({ login: state }) => get(state, 'userInfo.account_roles');
export const isPatient = ({ login: state }) => get(state, 'userInfo.is_patient');
export const consentPolicySelector = ({ login: state }) => get(state, 'groupInfo.group_consent_policy');
export const isPatientInactiveSelector = ({ login: state }) => {
  return get(state, 'userInfo.is_patient') && (get(state, 'userInfo.status') !== 'active');
};
export const userStatus = ({ login: state }) => get(state, 'userInfo.status');
export const viewPermsSelector = ({ login: state }) => get(state, 'userInfo.view_permission');
