import Loading from 'components/Loading';
import moment from 'moment';
import React, { Component } from 'react';

class ChatBody extends Component {
	isRemoteUser = (messageUserId) => {
	  const { user } = this.props;

	  return user.id !== messageUserId;
	};

	userName = (userId) => {
	  const { participants } = this.props;
	  const participant = participants.find(({ id }) => id === userId);

	  	return participant ? `${participant.first_name} ${participant.last_name}` : '';
	};

	time = (timeStamp) => moment(timeStamp).format('h:mm A');

	render() {
	  const { messages, theme, loading, containerRef } = this.props;

	  return loading ? <Loading /> : (
			<div className={theme.body} ref={containerRef}>
				<div className={theme.bodyWrapper}>
					{messages.map(({ message, timeStamp, userId }) => (
						<div
							className={!this.isRemoteUser(userId) ? theme.message : theme.messageRemote}
							key={timeStamp}
						>
							<div className={theme.messageHeader}>
								{this.isRemoteUser(userId) && <p className={theme.author}>{`${this.userName(userId)}`}</p>}
								<p className={theme.date}>{this.time(timeStamp)}</p>
							</div>
							<p className={theme.messageBody}>{message}</p>
						</div>
					))}
				</div>
			</div>
	  );
	}
}

export default ChatBody;
