import BSModal from 'react-bootstrap/Modal';

import React from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

/**
 * Modal wrapper
 */

export function Wrapper({ size, className, isOpen, close, backdrop, keyboard, onEntered, ...props }) {
  return (
    <BSModal
      animation={false}
      backdropClassName={styles.backdrop}
      centered
      dialogClassName={cn(styles.dialog, className, size ? styles[size] : styles.lg)}
      show={isOpen}
      onHide={close}
      backdrop={backdrop}
      keyboard={keyboard}
      onEntered={onEntered}
      {...props}
    />
  );
}

Wrapper.defaultProps = {
  backdrop: 'static',
  keyboard: false
};

/**
 * Header
 */

export function Header(props) {
  return (
    <BSModal.Header className={styles.header} {...props} />
  );
}

/**
 * Header button
 */

export function HeaderButton(props) {
  return (
    <div ref={props.innerRef} className={`${styles.headerButton} ${props.className}`} onClick={props.onClick}>
      {props.children}
    </div>
  );
}

/**
 * Footer
 */

export function Footer({ className, ...props }) {
  return (
    <BSModal.Footer className={cn(styles.footer, className)} {...props} />
  );
}

/**
 * Body
 */

export function Body({ className, ...props }) {
  return (
    <BSModal.Body className={cn(styles.body, className)} {...props} />
  );
}
