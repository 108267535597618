import React from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    color: '#d16764',
    listStyle: 'none',
    padding: 0,
  },
  item: {},
}));

// Iterate over each error object and print them
// in an unordered list
const Errors = (props) => {
  const classes = useStyles();
  const { errors } = props;

  return (
    <div>
      <ul className={classes.root}>
        {errors.map((error) => (
          <li key={error.time} className={classes.item}>{error.body}</li>
        ))}
      </ul>
    </div>
  );
};

export default Errors;
