import { activeFeedSelector, pinnedTrackIdSelector } from 'modules/conference/selectors/videoRoom';

import MobileActiveFeed from './MobileActiveFeed';
import { addNewImage } from '../../../imageCapture/actions';
import { connect } from 'react-redux';
import { isPatient } from 'modules/login/selectors';

const mapStateToProps = (state) => ({
  activeFeed: activeFeedSelector(state),
  pinnedTrackId: pinnedTrackIdSelector(state),
  isPatient: isPatient(state)
});
const mapDispatchToProps = (dispatch) => ({
  addNewImage: (data) => dispatch(addNewImage(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileActiveFeed);
