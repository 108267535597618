import { connect } from 'react-redux';
import ImageList from './ImageList';
import { addNewImage } from '../../actions';
import { imageSelector, tokenSelector } from '../../selectors';

const mapStateToProps = (state) => ({
  images: imageSelector(state),
  token: tokenSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  addNewImage: (image) => dispatch(addNewImage(image)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageList);
