import * as s from '../../selectors/videoRoom';

import LeftSideBarItem from './LeftSideBarItem';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { unreadMessagesSelector } from 'modules/chat/selectors';
import { useStyles } from './LeftSideBarItem-styles';
import { withStyles } from '@material-ui/core';

const mapStateToProps = (state) => ({
  isRecord: s.isRecordSelector(state),
  unreadMessages: unreadMessagesSelector(state),
  participants: s.participantsSelector(state),
});

export default compose(
  withStyles(useStyles),
  connect(mapStateToProps, null)
)(LeftSideBarItem);
