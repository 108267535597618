import { get, uniqBy } from 'lodash';
import { createSelector } from 'reselect';

export const photosListSelector = ({ pastConference: state }) => get(state, 'photosList', []);
export const videosListSelector = ({ pastConference: state }) => get(state, 'videosList', []);
export const mediaListSelector = ({ pastConference: state }) => get(state, 'mediaList', []);
export const patientsListSelector = ({ pastConference: state }) => get(state, 'patients', []);
export const selectedPatientSelector = ({ pastConference: state }) => get(state, 'selectedPatient', {});
export const selectedMediaSelector = ({ pastConference: state }) => {
  const selectedMedia = get(state, 'selectedMedia', new Set([]));

  return [...selectedMedia];
};
export const tokenSelector = ({ token: state }) => get(state, 'token', '');
export const accountIdSelector = ({ login: state }) => get(state, 'userInfo.id', null);
export const messagesSelector = (state) => uniqBy(state.chat.messages, 'timeStamp');
export const loadingSelector = ({ pastConference: state }) => get(state, 'loading', false);
export const safetyUserIdSelector = createSelector(
  accountIdSelector,
  (accountId) => {
  	const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));

	  return accountId || userInfo.id;
  }
);

export const patientsOptionsSelector = createSelector(
  patientsListSelector,
  (patients) => patients.map(({ display_name, first_name, last_name, id }) => ({
    key: id,
    label: display_name || `${first_name} ${last_name}`
  })),
);
