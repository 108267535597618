import { connect } from 'react-redux';
import { trackPubsToTracks } from 'utils/utils';
import SwitchMicOnOffButtonContainer from './SwitchMicOnOffButtonContainer';
import { localFeedsSelector, previewStreamSelector } from '../../selectors/videoRoom';
import { toggleMic } from '../../actions';
import { enqueueSnackbar } from '../../../notifier/actions';


const mapStateToProps = (state) => {
  const previewStream = previewStreamSelector(state);
  const localFeeds = localFeedsSelector(state);
  const localFeed = localFeeds[0];
  const audioTracks = localFeed ? trackPubsToTracks(localFeed.audioTracks) : [];

  return ({
    previewStream,
    audioTracks
  });
};

const mapDispatchToProps = (dispatch) => ({
  onClick: () => dispatch(toggleMic()),
  enqueueSnackbar: (message) => dispatch(enqueueSnackbar(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwitchMicOnOffButtonContainer);
