import { connect } from 'react-redux';
import ScreenSharingButton from './ScreenSharingButton';
import { shareScreenSelector, localFeedsSelector } from '../../selectors/videoRoom';
import { toggleScreenshare } from '../../actions';

const mapStateToProps = (state) => {
  const currentShareScreen = shareScreenSelector(state);
  const localFeeds = localFeedsSelector(state);
  const isLocalShare = currentShareScreen && currentShareScreen.participant === localFeeds[0];

  const isDisabled = currentShareScreen && !isLocalShare;

  const isActive = isLocalShare;

  return ({
    isActive,
    isDisabled
  });
};

const mapDispatchToProps = (dispatch) => ({
  onClick: () => dispatch(toggleScreenshare()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScreenSharingButton);
