export const useStyles = () => ({
  waitingJoinOverlay: {
    position: 'absolute',
    top: 100,
    left: '50%',
    transform: 'translateX(-50%)',
    padding: '10px 24px',
    border: '1px solid #1EBFA2',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: 6,
    background: 'white',
    width: '100%',
    maxWidth: 350,
    zIndex: 100
  },
  acceptButton: {
    alignItems: 'center',
    backgroundColor: '#49a6ff !important',
    borderRadius: '6px',
    color: '#fff',
    fontSize: '14px',
    height: 30,
    padding: '4px 16px',
  },
  center: {
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  actionContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  actionItemContainer: {
    cursor: 'pointer',
    fontSize: 16,
    lineHeight: '32px',
    textAlign: 'center',
    margin: '0 10px',
  },
  accept: {
    color: '#49A6FF'
  },
  decline: {
    color: '#FF0000A6'
  },
  participantItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  participantName: {
    color: '#4C575D',
    fontSize: 16,
    lineHeight: '32px',
    marginBottom: 0,
    maxWidth: 320
  },
  border: {
    height: 1,
    background: '#CCCED0',
    margin: '16px 0',
    width: '100%'
  }
});
