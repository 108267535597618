import React, { Component } from 'react';

import Avatar from '../Avatar';
import Box from '@material-ui/core/Box';
import { CORE_CAMERA_PREFIX } from 'modules/conference/saga';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import IconButton from '@material-ui/core/IconButton';
import Loading from '../../../../components/Loading';
import MicOffIcon from '@material-ui/icons/MicOff';
import { ReactComponent as MirrorDisabledSvg } from '../../../../assets/mirror_disabled.svg';
import { ReactComponent as MirrorSvg } from '../../../../assets/mirror.svg';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import PictureInPictureAltIcon from '@material-ui/icons/PictureInPictureAlt';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { Typography } from '@material-ui/core';
import blurIcon from '../../../../assets/blur.svg';
import classNames from 'classnames';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { findDOMNode } from 'react-dom';
import { get } from 'lodash';
import { isSupported } from '@twilio/video-processors';
import pinIcon from '../../../../assets/pin.svg';
import screenfull from 'screenfull';
import { trackPubsToTracks } from 'utils/utils';
import unBlurIcon from '../../../../assets/unblur.svg';
import unpinIcon from '../../../../assets/unpin.svg';

class Stream extends Component {
  static propTypes = {
    addNewImage: PropTypes.func.isRequired,
    classes: PropTypes.any.isRequired,
    detachLocalFeed: PropTypes.func.isRequired,
    feed: PropTypes.object.isRequired,
    pip: PropTypes.bool.isRequired,
    pipToggle: PropTypes.func.isRequired,
    stream: PropTypes.any,
    setPinnedStream: PropTypes.func,
    onVideoReady: PropTypes.func,
    onAudioReady: PropTypes.func,
    roomId: PropTypes.string.isRequired,
  };

  state = {
    audioTrack: null,
    videoTrack: null,
    avatarSize: 0,
    fontSize: 0,
  };

  constructor(props) {
    super(props);
    this.menuRef = React.createRef();
  }

  updateTrack = (track) => {
    const { feed, isMasterFeed, videoTrackId } = this.props;
    const { videoTrack: curVideoTrack, audioTrack: curAudioTrack } = this.state;

    const videoTracks = trackPubsToTracks(feed.videoTracks);
    const audioTracks = trackPubsToTracks(feed.audioTracks);

    const videoTrack = videoTracks.find((t) => (isMasterFeed ? t.name?.startsWith(CORE_CAMERA_PREFIX) : (t.id || t.sid) === videoTrackId));
    const audioTrack = audioTracks.find((t) => t.isEnabled);

    if (!track || track.kind === 'video') {
      if (curVideoTrack && this.video) {
        curVideoTrack.detach(this.video);
        this.video.load();
      }

      if (videoTrack) {
        videoTrack.attach(this.video);
      }
    }

    if (!track || track.kind === 'audio') {
      if (curAudioTrack) {
        curAudioTrack.detach(this.audio);
      }

      if (isMasterFeed && audioTrack && this.props.remote) {
        audioTrack.attach(this.audio);
        this.props.onAudioReady(this.audio);
      }
    }

    this.setState({ audioTrack, videoTrack })
  }

  getCurrentVideoTrack = () => {
    const { feed, isMasterFeed, videoTrackId } = this.props;

    const videoTracks = trackPubsToTracks(feed.videoTracks);

    const videoTrack = videoTracks.find((t) => (isMasterFeed ? t.name?.startsWith(CORE_CAMERA_PREFIX) : (t.id || t.sid) === videoTrackId));
    return videoTrack
  }

  componentDidMount() {
    const { feed, isMasterFeed } = this.props;

    this.initialize(feed, isMasterFeed);

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    const { feed, isMasterFeed, share } = this.props;

    if (!share) {
      this.state.audioTrack?.detach()
    }
    this.uninitialize(feed, isMasterFeed);
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps) {
    const { isMasterFeed, videoTrackId, feed } = this.props;

    if (feed.sid !== prevProps.feed.sid || videoTrackId !== prevProps.videoTrackId) {
      this.uninitialize(prevProps.feed, prevProps.isMasterFeed);
      this.initialize(feed, isMasterFeed);
      return;
    }
  }

  initialize = (feed, isMasterFeed) => {
    this.updateTrack();

    if (isMasterFeed) {
      feed.on('trackSubscribed', this.updateTrack);
      feed.on('trackUnsubscribed', this.updateTrack);
      feed.on('trackEnabled', this.updateTrack);
      feed.on('trackDisabled', this.updateTrack);
    }
  }

  uninitialize = (feed, isMasterFeed) => {
    if (isMasterFeed) {
      feed.off('trackSubscribed', this.updateTrack);
      feed.off('trackUnsubscribed', this.updateTrack);
      feed.off('trackEnabled', this.updateTrack);
      feed.off('trackDisabled', this.updateTrack);
    }
  }

  handleResize = () => {
    if (this.video) {
      const approxWidth = Math.ceil(this.video.clientWidth / 3);
      const approxHeight = Math.ceil(this.video.clientHeight / 2);
      const estimatedSize = approxWidth < approxHeight ? approxWidth : approxHeight;
      const fontSize = Math.max(Math.ceil(estimatedSize / 5), 15);

      this.setState({
        avatarSize: estimatedSize,
        fontSize: Math.min(fontSize, 50),
      });
    }
  }

  setMuted = (value) => () => { this.setState({ isMuted: value }); }

  // For mobile only
  get isStreamPinned() {
    const { pinnedFeed, pinnedTrackId, videoTrackId } = this.props;

    return window.innerWidth < 768 && pinnedFeed && pinnedTrackId === videoTrackId;
  }

  videoRef = (player) => {
    if (player) {
      this.player = player;
      this.video = player.getInternalPlayer();
      this.updateTrack();

      this.video.addEventListener('enterpictureinpicture', () => {
        this.props.pipToggle(true);
      }, false);
      this.video.addEventListener('leavepictureinpicture', () => {
        this.props.pipToggle(false);
      }, false);

      this.handleResize();
      this.props.onVideoReady(this.video);
    }
  };

  audioRef = (player) => {
    if (player) {
      this.audio = player;
    }
  };

  handleToggleMic = () => {
    const { remote } = this.props;

    if (!remote) {
      this.props.toggleMic();
    }
  }

  handleScreenShot = () => {
    const { ctx, video } = this;

    if (!video) {
      return null;
    }
    if (!ctx) {
      const canvas = document.createElement('canvas');
      const aspectRatio = video.videoWidth / video.videoHeight;

      canvas.width = video.clientWidth;
      canvas.height = video.clientWidth / aspectRatio;

      this.canvas = canvas;
      this.ctx = canvas.getContext('2d');
    }
    if (this.flipScreen()) {
      this.ctx.save();
      this.ctx.scale(-1, 1);
      this.ctx.drawImage(this.video, 0, 0, -this.canvas.width, this.canvas.height);
      this.ctx.restore();
    } else {
      this.ctx.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);
    }

    const dataUrl = this.canvas.toDataURL();

    this.ctx.canvas.toBlob((blob) => {
      this.props.addNewImage({
        roomId: this.props.roomId,
        title: `image-${new Date().getTime()}`,
        dataUrl,
        status: 'loading',
        blob
      });
    });
    this.props.openCapturedImages()
    return this.canvas;
  };

  handleFullScreen = () => {
    screenfull.request(findDOMNode(this.player));
  };

  handlePipClick = async () => {
    if (document.pictureInPictureEnabled) {
      try {
        await this.video.requestPictureInPicture();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    } else {
      await document.exitPictureInPicture();
    }
  };

  handleFlip = () => {
    const { feed, flippedStreams, setFlippedStreamsIoT } = this.props;
    const streams = flippedStreams.includes(feed.sid)
      ? flippedStreams.filter((s) => s !== feed.sid)
      : flippedStreams.concat(feed.sid);

    setFlippedStreamsIoT(streams);
  }

  handleRemove = () => {
    const { videoTrackId, detachLocalFeed } = this.props;
    detachLocalFeed(videoTrackId);
  }

  flipScreen = () => {
    const { feed, flippedStreams, pip, share } = this.props;

    return !pip && !share && flippedStreams.includes(feed.sid);
  }

  handleBlur = () => {
    const { isBlurred, videoTrackId, blurTrack, unBlurTrack } = this.props;

    if (isBlurred) {
      unBlurTrack(videoTrackId);
    } else {
      blurTrack(videoTrackId);
    }
  }

  handleTogglePin = () => {
    const { pinnedTrackId, videoTrackId, feed, unpinFeed, setPinnedFeed } = this.props;

    if (videoTrackId === pinnedTrackId) {
      unpinFeed();
    } else {
      setPinnedFeed(feed, videoTrackId);
    }
  }

  getIsMuted = () => {
    const { feed, mutedStreams } = this.props;

    return mutedStreams.includes(feed.sid);
  }

  getIsVideoMuted = () => {
    const { feed, videoMutedStreams } = this.props;

    return videoMutedStreams.includes(feed.sid);
  }

  renderRecordingIcon = () => (
    <div className={this.props.classes.recordingIcon} />
  )

  renderMutedIcon = () => (
    <div className={this.props.classes.micOffIcon}><MicOffIcon /></div>
  )

  renderCardActions = (name) => {
    const {
      classes,
      isPatient,
      isBlurred,
      pip,
      pinnedTrackId,
      remote,
      share,
      videoTrackId,
      hidePin,
      layoutMode
    } = this.props;
    const showBlurIcon = !remote && !share && !this.getIsVideoMuted() && isSupported
    const showFlipScreenIcon = !remote && !share

    return (
      <CardActions disableSpacing className={classes.footer}>
        <Typography className={classes.title} variant="h6">
          {name}
        </Typography>
        {!hidePin && layoutMode !== 'spotlight' && (
          <IconButton
            aria-label="Pin"
            onClick={this.handleTogglePin}
            size="small"
            color="secondary"
          >
            <img
              className={classes.pinIcon}
              src={pinnedTrackId === videoTrackId ? unpinIcon : pinIcon}
              alt="pin"
            />
          </IconButton>
        )}
        <IconButton
          aria-label="Fullscreen"
          onClick={this.handleFullScreen}
          size="small"
          color="secondary"
        >
          <FullscreenIcon />
        </IconButton>
        {!isPatient && (
          <IconButton
            onClick={this.handleScreenShot}
            aria-label="Make photo from camera"
            size="small"
            color="secondary"
          >
            <PhotoCameraIcon />
          </IconButton>
        )}
        {document.pictureInPictureEnabled && (!pip) && (
          <IconButton
            size="small"
            onClick={this.handlePipClick}
            aria-label="Picture in picture"
            color="secondary"
          >
            <PictureInPictureAltIcon />
          </IconButton>
        )}
        {showFlipScreenIcon && (
          <IconButton
            aria-label="Flip screen"
            onClick={this.handleFlip}
            size="small"
            color="secondary"
            disabled={pip}
          >
            {!pip ? (
              <MirrorSvg className={classes.icon} />
            ) : (
              <MirrorDisabledSvg className={classes.icon} />
            )}
          </IconButton>
        )}
        {showBlurIcon && (
          <IconButton
            aria-label="Blur"
            onClick={this.handleBlur}
            size="small"
            color="secondary"
          >
            <img
              className={classes.blurIcon}
              src={isBlurred ? unBlurIcon : blurIcon}
              alt="blur"
            />
          </IconButton>
        )}
      </CardActions>
    )
  }

  render() {
    const {
      classes,
      feed,
      isRecord,
      forMobileLocal,
      remote,
      vcInfo,
      share,
      shareScreen,
      isMasterFeed
    } = this.props;
    const { avatarSize, fontSize } = this.state;

    let name = feed.identity;

    if (share && shareScreen) {
      const identity = shareScreen.track.name?.split('_')[1];
      const participantInfo = vcInfo && vcInfo?.participants?.find((v) => v.id === identity);

      name = participantInfo ? `${participantInfo.first_name} ${participantInfo.last_name}` : identity;
    } else if (!share) {
      const participantInfo = vcInfo && vcInfo?.participants?.find((v) => v.id === feed.identity);

      name = participantInfo ? `${participantInfo.first_name} ${participantInfo.last_name}` : feed.identity;
    }

    const nameFirstLetters = name && name.split(' ').filter((e) => e).map(([v]) => v.toUpperCase());

    const isVideoMuted = false;

    const isConnected = feed.state === 'connected';

    const cardClass = classNames(this.props.className, {
      [classes.card_no_video]: isVideoMuted,
      [classes.card_remote]: remote,
      [classes.card_local]: !remote,
      [classes.card_talking]: get(feed, 'isTalking', false),
    });

    let border = 'none';

    if (window.innerWidth < 768) {
      border = (this.isStreamPinned ? '2px solid #49A6FF' : '2px solid #fff');
    }
    if (forMobileLocal) {
      return (
        <>
          {isRecord && this.renderRecordingIcon()}
          <Box className={classes.card_only_video}>
            {this.getIsMuted() && this.renderMutedIcon()}
            {(isConnected && !remote) && (
              <ReactPlayer
                ref={this.videoRef}
                className={classNames('streamWrapper', classes.video, { [classes.flip]: this.flipScreen() })}
                url={[]}
                controls={false}
                playsinline
                autoPlay
                playing
              />
            )}
            {remote && <audio ref={this.audioRef} autoPlay />}
          </Box>
        </>
      );
    }

    const showXIcon = !share && !remote && !isMasterFeed

    return (
      <Card className={`${classes.card} ${cardClass}`}>
        {isRecord && this.renderRecordingIcon()}
        {this.getIsMuted() && this.renderMutedIcon()}
        <CardContent className={classes.content}>
          {(isConnected || !remote) && (
            <>
              <ReactPlayer
                ref={this.videoRef}
                className={classNames('streamWrapper', classes.video, { [classes.flip]: this.flipScreen() })}
                style={{ border }}
                url={[]}
                controls={false}
                playsinline
                autoPlay
                playing
              />
              {remote && <audio ref={this.audioRef} autoPlay />}
            </>
          )}
          {window.innerWidth < 768 && (
            <div className={classes.userBlock}>
              <div className={classes.userName}>{name}</div>
            </div>
          )}
          {(isConnected || !remote) ? (
            <div className={classes.avatar}>
              <span>
                {nameFirstLetters}
              </span>
            </div>
          ) : <Loading text={`Connection status: ${feed.state}`} />}
          {!share && !this.getCurrentVideoTrack()?.isEnabled && (
            <div className={classes.accountAvatar} style={{ width: avatarSize, height: avatarSize }}>
              <Avatar accountId={feed.identity} fontSize={fontSize} />
            </div>
          )}
        </CardContent>
        {isConnected && this.renderCardActions(name)}
        {showXIcon && (
          <IconButton
            aria-label="Remove"
            onClick={this.handleRemove}
            size="small"
            color="secondary"
            className={classes.removeVideo}
          >
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        )}
      </Card>
    );
  }
}

export default Stream;
