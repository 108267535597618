import { connect } from 'react-redux';
import { setAuthorizationHeader } from 'modules/login/actions';
import PastConference from './PastConference';
import { fetchMedia, fetchPatientsList } from '../../actions';
import { loadingSelector, tokenSelector } from '../../selectors';

const mapStateToProps = (state) => ({
  loading: loadingSelector(state),
  token: tokenSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchMedia: (roomId) => dispatch(fetchMedia(roomId)),
  fetchPatientsList: () => dispatch(fetchPatientsList()),
  setAuthorizationHeader: (token) => dispatch(setAuthorizationHeader(token))
});

export default connect(mapStateToProps, mapDispatchToProps)(PastConference);
