import React, { useRef } from 'react';
import Tippy from '@tippyjs/react';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

import styles from './styles.module.scss';

const UpgradeSubscriptionLink = 'https://www.mouthwatch.com/upgrade';

const EssentialTooltip = ({ children, content, placement, enabled, disableClickOnChildren }) => {
  const refTrigger = useRef(null);

  if (!enabled) return <>{children}</>;

  function onChildClick(ev) {
    if (disableClickOnChildren) {
      ev.stopPropagation();
    }
  }

  const html = (
    <div className={styles.wrapper}>
      <div className={styles.content}>{content}</div>
      <a
        className={styles.learnMore}
        href={UpgradeSubscriptionLink}
        target="_blank"
        rel="noreferrer"
      >
        Learn More
      </a>
    </div>
  );

  return (
    <Tippy
      arrow
      content={html}
      interactive
      placement={placement}
      theme="light"
    >
      <span ref={refTrigger} className={styles.trigger} onClick={onChildClick}>
        {children}
      </span>
    </Tippy>
  );
};

EssentialTooltip.defaultProps = {
  placement: 'top',
  enabled: true,
  disableClickOnChildren: true
};

export default EssentialTooltip;
