import { LOGIN_ERROR, LOGIN_REQUESTING, LOGIN_SUCCESS, LOGOUT, SET_AUTHORIZATION_HEADER, TOKEN_SET, TOKEN_UNSET, } from './actionTypes';

export function setToken(token) {
  return {
    type: TOKEN_SET,
    token,
  };
}

export function unsetToken() {
  return {
    type: TOKEN_UNSET,
  };
}

export const logoutRequest = function logoutRequest() {
  return {
    type: LOGOUT,
  };
};

export const loginRequest = function loginRequest({ username, password }) {
  return {
    type: LOGIN_REQUESTING,
    username,
    password,
  };
};

export const loginSuccess = function loginSuccess() {
  return {
    type: LOGIN_SUCCESS,
  };
};

export const loginFailure = function loginFailure(error) {
  return {
    type: LOGIN_ERROR,
    error
  };
};

export const setAuthorizationHeader = (token) => ({
  type: SET_AUTHORIZATION_HEADER,
  payload: token
});

export function checkAuthorization(dispatch) {
  const storedToken = localStorage.getItem('token');

  if (storedToken) {
    const token = JSON.parse(storedToken);

    // const createdDate = new Date(token.created)
    // const created = Math.round(createdDate.getTime() / 1000)
    // const ttl = 1209600
    // const expiry = created + ttl

    // if (created > expiry) return false

    dispatch(setToken(token));
    dispatch(setAuthorizationHeader());
    return true;
  }

  return false;
}
