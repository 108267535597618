import * as at from './actionTypes';

export const sendMessage = (data) => {
  data = data || null;
  const text = JSON.stringify(data);

  const messageData = {
    title: 'title',
    body: text
  };

  return {
    type: at.SEND_MESSAGE,
    payload: { messageData }
  };
};

export const loadLocalData = (data) => ({
  type: at.ROOM_LOCAL_DATA,
  payload: data
});

export const loadRemoteData = (data) => ({
  type: at.ROOM_DATA_CHAT,
  payload: data
});

export const loadLocalError = (message) => ({
  type: at.ROOM_LOCAL_DATA_ERROR,
  payload: message,
});

export const loadChat = () => ({
  type: at.LOAD_CHAT
});

export const loadMessages = (messages) => ({
  type: at.LOAD_MESSAGES,
  payload: messages
});

export const resetUnreadMessages = () => ({ type: at.RESET_UNREAD_MESSAGES });

export const setLoading = (loading) => ({
  type: at.SET_LOADING,
  payload: loading,
});

export const connectIoT = (roomId) => ({
  type: at.IOT_CONNECT,
  payload: { roomId }
});

export const joinWaitingRoomIoT = ({ participant }) => ({
  type: at.IOT_CONNECT,
  payload: { participant }
})

export const setFlippedStreamsIoT = (flippedStreams) => ({
  type: at.IOT_SET_FLIPPED_STREAMS,
  payload: { flippedStreams }
});

export const setIsRecordingIoT = (isRecording) => ({
  type: at.IOT_SET_IS_RECORDING,
  payload: { isRecording }
});


export const setMutedStreamsIoT = (stream, isMuted) => ({
  type: at.IOT_SET_MUTED_STREAMS,
  payload: { stream, isMuted }
});

export const setVideoMutedStreamsIoT = (stream, isMuted) => ({
  type: at.IOT_SET_VIDEO_MUTED_STREAMS,
  payload: { stream, isMuted }
});

export const requestJoinIoT = () => ({
  type: at.IOT_REQUEST_JOIN,
});

export const onWaitingRoomIoT = () => ({
  type: at.IOT_ON_WAITING_ROOM,
});

export const leaveWaitingRoomIoT = () => ({
  type: at.IOT_LEAVE_WAITING_ROOM,
});

export const hostJoinedIoT = () => ({
  type: at.IOT_HOST_JOINED,
});

export const declineParticipantIoT = (participantId) => ({
  type: at.IOT_DECLINE_PARTICIPANT,
  payload: { participantId }
});

export const acceptParticipantIoT = (participant) => ({
  type: at.IOT_ACCEPT_PARTICIPANT,
  payload: { participant }
});

export const removeParticipantIoT = (participantId) => ({
  type: at.IOT_REMOVE_PARTICIPANT,
  payload: { participantId }
});

export const updateHostIoT = (hostId) => ({
  type: at.IOT_UPDATE_HOST,
  payload: { hostId }
});

export const endCallIoT = () => ({
  type: at.IOT_END_CALL,
});

export const muteParticipantIoT = (participantId) => ({
  type: at.IOT_MUTE_PARTICIPANT,
  payload: { participantId }
});
