export const useStyles = ({ palette: { primary, secondary, common } }) => ({
  card: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 0px 15px #00000033',
    position: 'relative',
  },

  card_no_video: {
    '& $footer': {
      background: '#FFFFFF',
      height: 85,
      borderRadius: '0 0 5px 5px',
      paddingTop: 10
    },

    '& $content': {
      background: '#265C7F',
      height: '100%'
    },

    '& $avatar': {
      position: 'absolute',
      left: '50%',
      top: 'calc(50% - 55px)',
      transform: 'translate(-50%, -50%)',
      background: '#B1B1B1',
      zIndex: '1',
      width: '25%',
      height: '25%',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 76,
      color: '#fff'
    },

    '& $video': {
      display: 'none'
    },

    '& $title ': {
      color: '#4E575C',
      lineHeight: '25px',
      fontSize: 20,
      marginBottom: 5
    },
  },
  title: {
    color: primary.contrastText,
    textAlign: 'left',
    marginBottom: 5,
    textTransform: 'capitalize',
  },
  avatar: {
    display: 'none',
  },
  icon: {
    width: 24,
    height: 24,
    stroke: secondary.main
  },
  blurIcon: {
    width: 24,
    height: 24,
  },
  pinIcon: {
    width: 18,
    height: 18,
  },
  removeVideo: {
    position: 'absolute',
    top: 10,
    left: 10,
  },
  footer: {
    padding: '0 0 20px 30px',
    bottom: 0,
    display: 'block',
    width: '100%',
    boxSizing: 'border-box',
    position: 'absolute',
    borderRadius: 5,
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%)',
  },
  content: {
    padding: 0,
    width: '100%',
    background: common.black,
    flex: 1,
    position: 'relative',
    height: '100%',
  },
  accountAvatar: {
    position: 'absolute',
    maxWidth: 250,
    maxHeight: 250,
    minWidth: 50,
    minHeight: 50,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  video: {
    maxWidth: '100%',
    display: 'flex',
    width: '100%!important',
    height: '100%!important',
    '& video': {
      width: '100%!important',
      height: 'auto!important',
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'cover',
    },
  },
  flip: {
    transform: 'scale(-1, 1);',
  },
  underline: {
    '&:before': {
      // normal
      borderBottom: `0px solid ${common.white}`
    },
    '&:after': {
      // focused
      borderBottom: `0px solid ${common.white}`
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      // hover
      borderBottom: `0px solid ${common.white}`
    }
  },
  action: {
    marginBottom: -8
  },
  card_local: {
  },
  micOffIcon: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    zIndex: 100,
    color: '#fff',
    opacity: '0.7',
    '& svg': {
      width: '3rem',
      height: '3rem'
    }
  },
  // mobile - landscape
  [`@media (max-width: 930px) and (orientation: landscape)`]: {
    card_talking: {
      '&:not $card_local': {
        zIndex: 99,
      }
    },
    micOffIcon: {
      color: '#000',

      '& svg': {
        width: '1.5rem',
        height: '1.5rem'
      }
    },
  },
  // mobile - portrait
  [`@media (max-width: ${768}px)`]: {
    footer: {
      display: 'none'
    },

    removeVideo: {
      top: 15,
      left: 15,
    },

    card_talking: {
      '&:not $card_local': {
        zIndex: 99,
      }
    },
    card: {
      height: '100px',
      flex: 'unset'
    },
    video: {
      width: '30% !important',
      height: '98% !important'
    },
    card_only_video: {
      width: '100%',
      height: '100%',
      position: 'relative',

      '& $video': {
        width: '100% !important',
        height: '100% !important'
      },
    },
    micOffIcon: {
      color: '#000',

      '& svg': {
        width: '1.5rem',
        height: '1.5rem'
      }
    },
    content: {
      background: '#fff',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '10px'
    },
    accountAvatar: {
      top: '49%',
      left: '15%',
    },
    userBlock: {
      display: 'flex',
      margin: 'auto 15px auto 15px',
      flex: '1 1 auto',
      justifyContent: 'space-between',
    },
    userName: {

    }
  },
  recordingIcon: {
    animation: 'blinker 1s linear infinite',
    backgroundColor: '#ff0000',
    borderRadius: 30,
    height: '20px',
    position: 'absolute',
    right: '20px',
    top: '20px',
    width: '20px',
    zIndex: 100
  }
});
