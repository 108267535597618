import {
  activeFeedSelector,
  blurredTrackSelector,
  flippedStreamsSelector,
  layoutModeSelector,
  mutedStreamsSelector,
  pinnedFeedSelector,
  pinnedTrackIdSelector,
  shareScreenSelector,
  vcInfoSelector,
  videoMutedStreamsSelector
} from 'modules/conference/selectors/videoRoom';
import { blurTrack, setPinnedFeed, toggleMic, unBlurTrack, unpinFeed, updateRemoteFeed } from '../../actions';

import Stream from './Stream';
import { addNewImage } from '../../../imageCapture/actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isPatient } from 'modules/login/selectors';
import { setFlippedStreamsIoT } from 'modules/chat/actions';
import { useStyles } from './Stream-styles';
import { withStyles } from '@material-ui/core';

const mapStateToProps = (state, ownProps) => ({
  shareScreen: shareScreenSelector(state),
  activeFeed: activeFeedSelector(state),
  pinnedFeed: pinnedFeedSelector(state),
  pinnedTrackId: pinnedTrackIdSelector(state),
  isPatient: isPatient(state),
  vcInfo: vcInfoSelector(state),
  flippedStreams: flippedStreamsSelector(state),
  mutedStreams: mutedStreamsSelector(state),
  videoMutedStreams: videoMutedStreamsSelector(state),
  isBlurred: blurredTrackSelector(state).includes(ownProps.videoTrackId),
  layoutMode: layoutModeSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  addNewImage: (data) => dispatch(addNewImage(data)),
  setFlippedStreamsIoT: (streams) => dispatch(setFlippedStreamsIoT(streams)),
  updateRemoteFeed: (id, feedOptions) => dispatch(updateRemoteFeed(id, feedOptions)),
  toggleMic: () => dispatch(toggleMic()),
  blurTrack: (videoTrackId) => dispatch(blurTrack(videoTrackId)),
  unBlurTrack: (videoTrackId) => dispatch(unBlurTrack(videoTrackId)),
  setPinnedFeed: (pinnedFeed, videoTrackId) => dispatch(setPinnedFeed(pinnedFeed, videoTrackId)),
  unpinFeed: () => dispatch(unpinFeed()),
});

export default compose(
  withStyles(useStyles),
  connect(mapStateToProps, mapDispatchToProps)
)(Stream);
