import { faEllipsisV, faVideo, faVideoSlash } from '@fortawesome/free-solid-svg-icons'

import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import MicConnectedSvg from "../../../../../../assets/mic-connected.svg";
import MicMutedSvg from "../../../../../../assets/mic-muted.svg";
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import React from 'react'
import TalkingAnimeIcon from './TalkingAnimeIcon';
import cn from 'classnames'

const ParticipantItem = ({
  classes,
  vcInfo,
  mutedStreams,
  videoMutedStreams,
  participantFeed,
  removeParticipant,
  updateHost,
  userInfo,
  muteParticipant,
  toggleMic,
  toggleVideo,
  isHost,
  isTalking
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const name = React.useMemo(() => {
    let result = participantFeed.identity;

    const participantInfo = vcInfo && vcInfo.participants.find((v) => v.id === participantFeed.identity);
    result = participantInfo ? `${participantInfo.first_name} ${participantInfo.last_name}` : participantFeed.identity;

    return result;
  }, [participantFeed, vcInfo])

  const isMe = React.useMemo(() => {
    return userInfo.id === participantFeed.identity;
  }, [userInfo, participantFeed])

  const isProvider = React.useMemo(() => {
    const participant = vcInfo?.participants.find(item => item.id === participantFeed.identity);
    return participant && !participant.is_patient;
  }, [participantFeed, vcInfo])

  const isVideoMuted = React.useMemo(() => videoMutedStreams.includes(participantFeed.sid), [videoMutedStreams, participantFeed]);
  const isAudioMuted =  React.useMemo(() => mutedStreams.includes(participantFeed.sid), [mutedStreams, participantFeed]);

  const canMuteAudio = React.useMemo(() => (isMe || (isHost && !isAudioMuted)), [isMe, isHost, isAudioMuted]);
  const canMuteVideo = React.useMemo(() => isMe, [isMe]);

  const open = React.useMemo(() => Boolean(anchorEl), [anchorEl]);

  const poperId = React.useMemo(() => {
    return open ? `simple-popper-${participantFeed.identity}` : undefined
  }, [open, participantFeed]);

  const handleClickAway = () => {
    setAnchorEl(null)
  };

  const onMoreClick = (event) => {
    setAnchorEl(prevAnchorEl => prevAnchorEl ? null : event.currentTarget);
  }

  const onRemove = () => {
    removeParticipant(participantFeed.identity);
  }

  const onChangeAssign = () => {
    updateHost(participantFeed.identity);
  }

  const onAudioMute = () => {
    if (isMe) {
      toggleMic();
      return;
    }
    if (isHost && !isAudioMuted) {
      muteParticipant(participantFeed.identity)
    }
  }

  const onVideoMute = () => {
    if (isMe) {
      toggleVideo();
    }
  }

  return (
    <div className={classes.participantItem}>
      <p className={classes.participantName}>{name}</p>
      <div className={classes.actionContainer}>
        {isHost && !isMe && (
          <Button className={classes.actionItemContainer} onClick={onMoreClick}>
            <FontAwesomeIcon className={classes.actionItem} icon={faEllipsisV} />
            <Popper
              id={poperId}
              open={open}
              anchorEl={anchorEl}
              placement="top"
              transition
              modifiers={{
                arrow: {
                  enabled: true,
                },
              }}
            >
              {({ TransitionProps }) => (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <Fade {...TransitionProps}>
                    <Paper>
                      <List classes={{
                        root: classes.list,
                      }}
                      >
                        {isHost && isProvider && (
                          <ListItemText
                            classes={{
                              root: classes.listItemText,
                              primary: classes.listItemTextPrimary,
                            }}
                            primary='Assign as host'
                            aria-describedby={poperId}
                            onClick={onChangeAssign}
                          />
                        )}
                        {isHost && (
                          <ListItemText
                            classes={{
                              root: classes.listItemText,
                              primary: classes.listItemTextSecondary,
                            }}
                            primary='Remove from call'
                            aria-describedby={poperId}
                            onClick={onRemove}
                          />
                        )}
                      </List>
                    </Paper>
                  </Fade>
                </ClickAwayListener>
              )}
            </Popper>
          </Button>
        )}
        <Button 
          className={cn(classes.actionItemContainer, {[classes.actionDisabled]: !canMuteVideo})} 
          disabled={!canMuteVideo}
        >
          <FontAwesomeIcon className={classes.actionItem} icon={isVideoMuted ? faVideoSlash : faVideo} onClick={onVideoMute} />
        </Button>
        <Button 
          className={cn(classes.actionItemContainer, {[classes.actionDisabled]: !canMuteAudio})} 
          onClick={onAudioMute}
          disabled={!canMuteAudio}
        >
          {isTalking ? (
            <TalkingAnimeIcon />
          ) : (
            <img src={isAudioMuted ? MicMutedSvg : MicConnectedSvg} alt="mic" />
          )}
        </Button>
      </div>
    </div>
  )
}

export default ParticipantItem
