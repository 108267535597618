import PropTypes from 'prop-types';
import React from 'react';
import { faMicrophoneAltSlash, faMicrophoneAlt } from '@fortawesome/free-solid-svg-icons';
import { CircleButtonWithText } from '../CircleButtonWithText/CircleButtonWithText';

const SwitchMicOnOffButton = ({ onClick, isActive, isDisabled }) => {
  return (
      <CircleButtonWithText
        onClick={onClick}
        icon={isActive ? faMicrophoneAltSlash : faMicrophoneAlt}
        text="Mute"
        isDisabled={isDisabled}
        isActive={isActive}
      />
  );
};

export default SwitchMicOnOffButton;

SwitchMicOnOffButton.propTypes = {
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func
};

SwitchMicOnOffButton.defaultProps = {
  isActive: false,
  isDisabled: false,
  onClick: () => {}
};
