import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { trackPubsToTracks } from 'utils/utils';
import SwitchVideoOnOffButton from './SwitchVideoOnOffButton';

const SwitchVideoOnOffButtonContainer = ({ onClick, previewStream, previewVideoTrackEnabled, localFeed, enqueueSnackbar }) => {
  const [isActive, setActive] = useState(false);
  const [isDisabled, setDisabled] = useState(true);

  const setActiveCallback = useCallback((track) => {
    if (track.kind === 'video') setActive(true);
  }, []);

  const setInactiveCallback = useCallback((track) => {
    if (track.kind === 'video') setActive(false);
  }, []);

  useEffect(() => {
    if (localFeed) {
      const videoTracks = trackPubsToTracks(localFeed.videoTracks);

      localFeed.on('trackPublished', setInactiveCallback);
      localFeed.on('trackUnpublished', setActiveCallback);
      setDisabled(videoTracks.length === 0);
      setActive(videoTracks.length === 0);

      return () => {
        localFeed.off('trackPublished', setInactiveCallback);
        localFeed.off('trackUnpublished', setActiveCallback);
      };
    } else if (previewStream) {
      const previewVideoTracks = previewStream?.getVideoTracks();

      if (!previewVideoTracks || previewVideoTracks.length === 0) {
        setDisabled(true);
        setActive(true);
      } else {
        setDisabled(false);
        setActive(!previewVideoTrackEnabled);
      }
    } else {
      setDisabled(true);
      setActive(true);
    }
  }, [localFeed, previewStream, previewVideoTrackEnabled, setActiveCallback, setInactiveCallback]);

  const handleClick = useCallback(() => {
    if (isDisabled) {
      enqueueSnackbar('No active devices found')
    } else {
      onClick();
    }
  }, [enqueueSnackbar, onClick, isDisabled]);

  return (
    <SwitchVideoOnOffButton
      onClick={handleClick}
      isActive={isActive}
    />
  );
};

export default SwitchVideoOnOffButtonContainer;

SwitchVideoOnOffButtonContainer.propTypes = {
  previewStream: PropTypes.any,
  localFeed: PropTypes.any,
  onClick: PropTypes.func,
  enqueueSnackbar: PropTypes.func
};

SwitchVideoOnOffButtonContainer.defaultProps = {
  onClick: () => {},
};
