import * as a from './actions';
import * as chatActions from '../chat/actions';
import * as t from './actions/actionTypes';

import APImethods2, { uploadFileToS3 } from '../../api2';
import { GaussianBlurBackgroundProcessor, isSupported } from '@twilio/video-processors';
import { call, fork, put, select, take, takeEvery, takeLatest, takeLeading } from '@redux-saga/core/effects';
import {
  isAcceptedSelector,
  isHostSelector,
  isIotConnectedSelector,
  isJoiningSelector,
  layoutSelector,
  localFeedsSelector,
  mutedStreamsSelector,
  pinnedTrackIdSelector,
  previewStreamSelector,
  remoteFeedsSelector,
  shareScreenSelector,
  twilioConfigSelector,
  twilioTokenSelector,
  vcInfoSelector,
  videoMutedStreamsSelector,
} from './selectors/videoRoom';
import { setMutedStreamsIoT, setVideoMutedStreamsIoT } from 'modules/chat/actions';

import { Howl } from 'howler';
import Video from 'twilio-video';
import { currentUserSelector } from 'modules/login/selectors';
import { defaultVideoDeviceSelector } from '../imageCapture/selectors';
import { enqueueSnackbar } from '../notifier/actions';
import { eventChannel } from '@redux-saga/core';
import isMobile from 'is-mobile';
import joinAudio from '../../assets/audios/join.mp3';
import { logger } from 'utils/logger';
import { mediaListSelector } from '../pastConference/selectors';
import { setMediaList } from '../pastConference/actions';
import { store } from '../../store';
import { trackEvent } from 'utils/ChurnZero';
import { trackPubsToTracks } from 'utils/utils';
import userMedia from '../../utils/userMedia';

export const SHARE_SCREEN = 'shareScreen';
export const CORE_CAMERA_PREFIX = 'camera-core';
export const EXTRA_CAMERA_PREFIX = 'camera-extra';

export const makeDisplay = (user, feedName) => `${user.id}___${feedName}___${user.name}`;
export const getUserFromDisplay = (display) => {
  // eslint-disable-next-line
  let [id, feedName, name] = (display).split('___');

  if (!name) { name = id; }
  return { id, feedName, name, display };
};

const AUDIO_DEBOUNCE_DELAY_IN_SEC = 8;
const AUDIO_REPEAT_INTERVAL_IN_SEC = 10;

let debounceTimer = null;
let repeatTimer = null;

function* addParticipantSaga({ payload: { userId } }) {
  const vcInfo = yield select(vcInfoSelector);
  const roomId = vcInfo?.id

  try {
    let updatedVCInfo;
    if (!vcInfo?.appointment_id) {
      // get unique participantIds
      const participantIds = vcInfo?.participants.map((p) => p.id)
        .filter((value, index, self) => self.indexOf(value) === index);
      const { data: { data: updateVCInfo } } =
        yield call([APImethods2, 'patch'], `/video_conference_rooms/${vcInfo?.id}`, {
          conflict_resolved: true,
          conflict_update_time: null,
          data: {
            participants: [userId, ...participantIds]
          }
        });
      updatedVCInfo = updateVCInfo
    } else {
      // get unique providerIds
      const providerIds = vcInfo?.participants.filter((p) => !p.is_patient).map((p) => p.id)
        .filter((value, index, self) => self.indexOf(value) === index);
      yield call([APImethods2, 'patch'], `/appointments/${vcInfo?.appointment_id}`, {
        conflict_resolved: true,
        conflict_update_time: null,
        data: {
          provider_ids: [userId, ...providerIds]
        },
        fromVC: true
      });

      const { data: { data: updateVCInfo } } = yield call([APImethods2, 'get'], `/video_conference_rooms/${roomId}`);
      updatedVCInfo = updateVCInfo
    }
    yield put(enqueueSnackbar('Participant invited'));
    // remove user from state.accessibleProviders.providerOptions
    yield put(a.removeAccessibleProvider(userId));
    // set updated VC Info
    yield put(a.setVCInfo(updatedVCInfo));
  } catch (e) {
    yield put(enqueueSnackbar('Invite failed. Please check your connection and try again.'));
    // eslint-disable-next-line
    console.log(e);
  }
}

function* getAccessibleProvidersSaga() {
  const params = {
    per_page: 30,
    inactive: false
  };

  let response = {
    page: 0
  }

  try {
    const { participants } = yield select(vcInfoSelector);
    const participantIds = participants.map((p) => p.id);

    do {
      // fetch accessible providers by page until we have them all
      params.page = response.page + 1
      const resp = yield call([APImethods2, 'get'], `accessibleProviders`, params);
      response = resp.data

      // get an array of provider Options with out current participants
      const accessibleProviderOptions = response.data?.filter((p) => !participantIds.includes(p.id))?.map((p) => {
        return { label: `${p.first_name} ${p.last_name}`, value: p.id }
      });
      yield put(a.updateAccessibleProvidersList(response.count, accessibleProviderOptions));

    } while (response.data.length === response.per_page);
  } catch (e) {
    yield put(a.getAccessibleProvidersError());
    yield put(enqueueSnackbar('Unable to fetch accessible providers.'));
    console.log(e);
  }
}

function* setDevicesSaga() {
  logger.info('setDevicesSaga started', {
    step: 'set.device.saga',
  })
  const videoDevices = yield userMedia.getVideoDevices();
  logger.info('Succesfully get list of available video devices', {
    step: 'set.device.saga',
    videoDevices
  })
  const audioDevices = yield userMedia.getAudioDevices();
  logger.info('Succesfully get list of available audio devices', {
    step: 'set.device.saga',
    audioDevices
  })

  yield put(a.setVideoDevices(videoDevices));
  yield put(a.setAudioDevices(audioDevices));
  logger.info('setDevicesSaga ended', {
    step: 'set.device.saga',
  })
}

function* putFromChannel(value) {
  yield put(value);
}

function* subscribeToRemoveRemoteFeed() {
  while (true) {
    const { payload: { participant } } = yield take(t.ROOM_PUBLISHERS_LEFT);
    const localFeeds = yield select(localFeedsSelector);
    const localFeed = localFeeds[0];

    if (!localFeed) continue;

    const vcInfo = yield select(vcInfoSelector);
    const participantInfo = vcInfo && vcInfo.participants.find((v) => v.id === participant.identity);

    if (participantInfo) {
      const message = `${participantInfo.first_name} ${participantInfo.last_name} left the room`;

      if (participant.sid !== localFeed.sid) {
        yield put(enqueueSnackbar(message));
      }
    }
  }
}

function* subscribeSessionStartSaga() {
  while (true) {
    yield take(t.CREATE_SESSION_SUCCESS);
    yield take(t.ROOM_STREAM_READY_TO_PUBLISH);

    let video = true;

    let feedName = 'default';
    const { deviceId, label } = yield select(defaultVideoDeviceSelector);

    if (deviceId && deviceId === '') {
      feedName = label.substring(0, 10);
      video = { deviceId };
    }
    yield put(a.attachLocalFeed(feedName, video));
  }
}

function* subscribeToReplaceFeedSaga() {
  while (true) {
    const { offer } = yield take(t.ROOM_LOCAL_FEED_REPLACE);
    const localFeeds = yield select(localFeedsSelector);

    const localVideoTrack = yield call(Video.createLocalVideoTrack, {
      name: `${CORE_CAMERA_PREFIX}-${Date.now()}`,
      deviceId: { exact: offer }
    });
    const localFeed = localFeeds[0];
    const videoTracks = trackPubsToTracks(localFeed.videoTracks).filter((t) => t.name?.startsWith(CORE_CAMERA_PREFIX));

    videoTracks.forEach((videoTrack) => {
      videoTrack.stop();
      localFeed.unpublishTrack(videoTrack);
      localFeed.emit('trackUnsubscribed', videoTrack);
    });
    yield call([localFeed, 'publishTrack'], localVideoTrack);
    localFeed.emit('trackSubscribed', localVideoTrack);
    yield put(a.setTwilioLocalParticipant(localFeed));
  }
}

function* subscribeToSwitchScreenShareSaga() {
  while (true) {
    yield take(t.ROOM_LOCAL_FEED_TOGGLE_SCREEN_SHARE);
    const localFeeds = yield select(localFeedsSelector);
    const localFeed = localFeeds[0];

    if (!localFeed) continue;
    const currentShareScreen = yield select(shareScreenSelector);

    if (currentShareScreen && currentShareScreen.participant !== localFeed) {
      continue;
    }
    if (!currentShareScreen) {
      yield fork(subscribeShareScreenChannelSaga, localFeed);
    } else {
      const track = currentShareScreen.track;

      track.stop();
      localFeed.unpublishTrack(track);
      localFeed.emit('trackUnsubscribed', track);
      yield put(a.twilioShareScreen(localFeed, null));
    }
  }
}

function* switchLocalFeedMicSaga() {
  const localFeeds = yield select(localFeedsSelector);
  const localFeed = localFeeds[0];
  const previewStream = yield select(previewStreamSelector);

  if (localFeed) {
    const audioTracks = trackPubsToTracks(localFeed.audioTracks);
    const audioTrack = audioTracks[0];

    if (audioTrack) {
      if (audioTrack.isEnabled) {
        audioTrack.disable();

        yield put(setMutedStreamsIoT(localFeed.sid, true));
      } else {
        audioTrack.enable();

        yield put(setMutedStreamsIoT(localFeed.sid, false));
      }
    } else {
      yield put(enqueueSnackbar('No active devices found'));
    }
  } else if (previewStream) {
    // Mute/unmute audio track
    if (!previewStream.getAudioTracks() || previewStream.getAudioTracks().length === 0) {
      return false;
    }
    const audioTrack = previewStream.getAudioTracks()[0];

    audioTrack.enabled = !audioTrack.enabled;
    yield put(a.setPreviewStream(previewStream));
  }
}

function* switchLocalFeedVideoSaga() {
  const localFeeds = yield select(localFeedsSelector);
  const localFeed = localFeeds[0];
  const previewStream = yield select(previewStreamSelector);

  if (localFeed) {
    const videoTracks = trackPubsToTracks(localFeed.videoTracks).filter((t) => t.name?.startsWith(CORE_CAMERA_PREFIX));
    const videoTrack = videoTracks[0];

    if (videoTrack) {
      videoTrack.stop();
      const localTrackPublication = localFeed.unpublishTrack(videoTrack);
      // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592

      localFeed.emit('trackUnpublished', localTrackPublication);
      localFeed.emit('trackUnsubscribed', videoTrack);

      yield put(setVideoMutedStreamsIoT(localFeed.sid, true));
    } else {
      const deviceId = localStorage.getItem('videoInputDeviceId');

      let videoTrack
      try {
        videoTrack = yield call(Video.createLocalVideoTrack, {
          name: `${CORE_CAMERA_PREFIX}-${Date.now()}`,
          ...(deviceId && { deviceId: { exact: deviceId } }),
        });
      } catch (error) {
        console.log('Video muted error', error)

        yield put(enqueueSnackbar('No active devices found'));
        throw error
      }

      yield call([localFeed, 'publishTrack'], videoTrack);

      localFeed.emit('trackSubscribed', videoTrack);

      yield put(setVideoMutedStreamsIoT(localFeed.sid, false));
    }
  } else if (previewStream) {
    // Mute/unmute audio track
    if (!previewStream.getVideoTracks() || previewStream.getVideoTracks().length === 0) {
      return false;
    }
    const videoTrack = previewStream.getVideoTracks()[0];

    videoTrack.enabled = !videoTrack.enabled;
    yield put(a.setPreviewStream(previewStream));
  }
}

function* subscribeToAttachLocalFeedSaga() {
  while (true) {
    const { payload: { deviceId } } = yield take(t.ROOM_LOCAL_FEED_ATTACH);
    const localFeeds = yield select(localFeedsSelector);
    const localFeed = localFeeds[0];

    if (localFeed) {
      const videoTrack = yield call(Video.createLocalVideoTrack, {
        name: `${EXTRA_CAMERA_PREFIX}-${Date.now()}`,
        ...(deviceId && { deviceId: { exact: deviceId } }),
      });

      yield call([localFeed, 'publishTrack'], videoTrack);

      localFeed.emit('trackSubscribed', videoTrack);

      yield put(a.setTwilioLocalParticipant(localFeed));
    }
  }
}

function* subscribeToDetachLocalFeedSaga() {
  while (true) {
    const { payload } = yield take(t.ROOM_LOCAL_FEED_DEATTACH);
    const localFeeds = yield select(localFeedsSelector);
    const localFeed = localFeeds[0];

    if (localFeed) {
      const track = trackPubsToTracks(localFeed.videoTracks).find(track => track.id === payload.id || track.sid === payload.id);
      if (track) {
        track.stop();
        localFeed.unpublishTrack(track);
        localFeed.emit('trackUnsubscribed', track);
        yield put(a.setTwilioLocalParticipant(localFeed));
      }
    }
  }
}

// function onBeforeUnload (e) {
//   const dialogText = 'Your recording start but did not upload. Do you really want close this page';
//   e.preventDefault();
//   // attempts at changing dialog message, unsuccessful
//   e.returnValue = dialogText;
//   return dialogText;
// }

function* removeDataFromURLSaga({ payload: videoSessionId }) {
  yield window.history.replaceState('', '', `/conference/${videoSessionId}`);
}

function* destroySessionHandler() {

}

function* roomPublishersJoinedHandler({ payload: { participant } }) {
  const vcInfo = yield select(vcInfoSelector);
  const participantInfo = vcInfo && vcInfo.participants.find((v) => v.id === participant.identity);

  if (participantInfo) {
    const message = `${participantInfo.first_name} ${participantInfo.last_name} joined the room`;

    yield put(enqueueSnackbar(message));
  }

  const localFeeds = yield select(localFeedsSelector);
  const localFeed = localFeeds[0];
  const mutedStreams = yield select(mutedStreamsSelector);
  const videoMutedStreams = yield select(videoMutedStreamsSelector);
  const isVideoMuted = videoMutedStreams.includes(localFeed.sid);
  const isAudioMuted = mutedStreams.includes(localFeed.sid);

  if (isAudioMuted) {
    yield put(setMutedStreamsIoT(localFeed.sid, true));
  }

  if (isVideoMuted) {
    yield put(setVideoMutedStreamsIoT(localFeed.sid, true));
  }
}

function* roomShareScreenStartedHandler({ payload: { track } }) {
  const vcInfo = yield select(vcInfoSelector);
  const localFeeds = yield select(localFeedsSelector);
  const remoteFeeds = yield select(remoteFeedsSelector);
  const identity = track.name.substring(SHARE_SCREEN.length + 1);
  const participantInfo = vcInfo && vcInfo.participants.find((v) => v.id === identity);

  const feed = [...localFeeds, ...remoteFeeds].find(feed => feed.identity === identity);

  if (participantInfo) {
    const message = `${participantInfo.first_name} ${participantInfo.last_name} is sharing screen`;

    yield put(enqueueSnackbar(message));
  }

  if (feed) {
    yield put(a.setPinnedFeed(feed, track.id || track.sid));
  }
}

function* roomShareScreenFinishedHandler({ payload: { track } }) {
  const vcInfo = yield select(vcInfoSelector);
  const identity = track.name.substring(SHARE_SCREEN.length + 1);
  const participantInfo = vcInfo && vcInfo.participants.find((v) => v.id === identity);

  const pinnedTrackId = yield select(pinnedTrackIdSelector);

  if (participantInfo) {
    const message = `${participantInfo.first_name} ${participantInfo.last_name} is no longer sharing screen`;

    yield put(enqueueSnackbar(message));
  }

  if (pinnedTrackId === (track.id || track.sid)) {
    yield put(a.unpinFeed());
  }
}

function* createSessionHandler({ config: { videoSessionId, shouldGetToken } }) {
  let sessionId = videoSessionId;

  if (sessionId) {
    yield sessionStorage.setItem('videoSessionId', sessionId);
    yield put(a.removeDataFromURL(sessionId));
  } else {
    sessionId = sessionStorage.getItem('videoSessionId');
  }

  if (shouldGetToken) {
    try {
      const {
        data: { token },
      } = yield call(
        [APImethods2, 'get'],
        `video_conference_rooms/${sessionId}/twilioToken`
      );

      yield put(
        a.createSessionSuccess({ twilioToken: token, roomId: sessionId })
      );
    } catch ({
      response: {
        data: { error },
      },
    }) {
      yield put(
        enqueueSnackbar(error, { variant: 'error', preventDuplicate: true })
      );
    }
  }
}

function* uploadRecordingSaga({ payload: { blob } }) {
  const { id: accountId } = yield select(currentUserSelector);
  const { id: roomId } = yield select(vcInfoSelector);
  const filename = `${accountId}-recording-${new Date().getTime()}.webm`;

  yield put(enqueueSnackbar('Video uploading to cloud'));
  try {
    const { data: createdMedia } = (yield call([APImethods2, 'post'], '/media', {
      properties: {
        file_name: filename,
        mime_type: blob.type,
        encoding: '7bit',
        type: blob.type.startsWith('image') ? 'exam-img' : 'exam-vid'
      },
      association: 'video_conference_room',
      association_id: roomId
    })).data;

    yield call(uploadFileToS3, createdMedia.url, blob);
    yield put(enqueueSnackbar('Video succesfully uploaded'));

    if (window.location.pathname.startsWith('/media')) {
      const mediaList = yield select(mediaListSelector);
      yield put(setMediaList([createdMedia, ...mediaList,]))
    }
  } catch ({ response: { data: { error } } }) {
    yield put(enqueueSnackbar(error));
  }
}

function* joinToTwilioRoomHandler() {
  yield put(a.setIsJoining(true));
  const twilioToken = yield select(twilioTokenSelector);
  const twilioConfig = yield select(twilioConfigSelector);
  const isHost = yield select(isHostSelector)

  const userInfo = yield select(currentUserSelector);
  const vcInfo = yield select(vcInfoSelector);

  logger.info('Joining the video conference room', {
    step: 'join.to.twilio.room.saga',
    userInfo,
    vcInfo,
    isHost,
  })

  try {
    // close preview stream
    const previewStream = yield select(previewStreamSelector);
    logger.info('Got the preview stream', {
      step: 'join.to.twilio.room.saga',
      previewStream
    })
    const tracks = [];

    const deviceId = localStorage.getItem('videoInputDeviceId');
    logger.info('Got the video deviceId', {
      step: 'join.to.twilio.room.saga',
      deviceId
    })

    let previewVideoWasMuted = true
    let previewWasMuted = true

    if (previewStream) {
      const previewAudioTracks = previewStream?.getAudioTracks();
      logger.info('Got the audio tracks of previewStream', {
        step: 'join.to.twilio.room.saga',
        previewAudioTracks
      })
      if (previewAudioTracks && previewAudioTracks.length > 0) {
        previewWasMuted = previewAudioTracks.length > 0 && !previewAudioTracks[0].enabled;
        logger.info('Got if preview audio was muted', {
          step: 'join.to.twilio.room.saga',
          previewWasMuted
        })
        const audioTrack = yield call(Video.createLocalAudioTrack);
        logger.info('Created local audio track', {
          step: 'join.to.twilio.room.saga',
          audioTrack
        })
        if (previewWasMuted) {
          audioTrack.disable();
          logger.info('Disabled audio track as it is muted', {
            step: 'join.to.twilio.room.saga',
            audioTrack
          })
        }

        tracks.push(audioTrack);
      }

      if (deviceId) {
        const videoTrack = yield call(Video.createLocalVideoTrack, {
          name: `${CORE_CAMERA_PREFIX}-${Date.now()}`,
          ...(deviceId && { deviceId: { exact: deviceId } }),
        });
        logger.info('Created local video track', {
          step: 'join.to.twilio.room.saga',
          videoTrack,
          name: `${CORE_CAMERA_PREFIX}-${Date.now()}`,
          ...(deviceId && { deviceId: { exact: deviceId } }),
        })
        const previewVideoTracks = previewStream.getVideoTracks();
        logger.info('Got preview video tracks', {
          step: 'join.to.twilio.room.saga',
          previewVideoTracks
        })
    
        if (previewVideoTracks && previewVideoTracks.length > 0 && previewVideoTracks[0].enabled) {
          tracks.push(videoTrack);
        }

        previewVideoWasMuted = previewVideoTracks && previewVideoTracks.length > 0 && !previewVideoTracks[0].enabled;
        logger.info('Got if preview video was muted', {
          step: 'join.to.twilio.room.saga',
          previewVideoWasMuted
        })
      }
    }


    const room = yield Video.connect(twilioToken, { ...twilioConfig, tracks: tracks.filter((track) => track !== undefined) });
    logger.info('Created twilio room', {
      step: 'join.to.twilio.room.saga',
      room,
      tracks
    })
    // yield call(subscribeRoomStatsSaga, room);

    previewStream?.getTracks().forEach((track) => track.stop());
    logger.info('Stopped all tracks by default', {
      step: 'join.to.twilio.room.saga',
    })

    if (previewWasMuted) {
      const localFeed = room.localParticipant;
      yield put(setMutedStreamsIoT(localFeed.sid, true));
      logger.info('Sent audio muted iot event', {
        step: 'join.to.twilio.room.saga',
      })
    }

    if (previewVideoWasMuted) {
      const localFeed = room.localParticipant;
      yield put(setVideoMutedStreamsIoT(localFeed.sid, true));
      logger.info('Sent video muted iot event', {
        step: 'join.to.twilio.room.saga',
      })
    }

    yield fork(subscribeTwilioRoomChannelSaga, room);
    yield put(a.setIsJoining(false));
    if (isHost) {
      yield put(chatActions.hostJoinedIoT());
      logger.info('Sent host joined iot event', {
        step: 'join.to.twilio.room.saga',
      })

      const hasPatient = vcInfo?.participants.findIndex(participant => participant.is_patient) > -1;

      if (hasPatient) {
        trackEvent(
          'Provider to Patient VC started',
          'A video conference that includes at least 1 provider and a patient begins',
          userInfo,
          {
            username: userInfo.username,
            email: userInfo.email,
            channel: userInfo.group_channel,
            sessionId: vcInfo?.id,
          }
        )
      } else {
        trackEvent(
          'Provider to Provider VC started',
          'A video conference that includes providers only begins',
          userInfo,
          {
            username: userInfo.username,
            email: userInfo.email,
            channel: userInfo.group_channel,
            sessionId: vcInfo?.id,
          }
        )
      }
    }
  } catch (error) {
    logger.info('Failed to join the video conference room', {
      step: 'join.to.twilio.room.saga',
      userInfo,
      vcInfo,
      isHost,
      error: JSON.stringify(error)
    })
    yield put(
      enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: true,
      })
    );
  }
}

function* subscribeShareScreenChannelSaga(localFeed) {
  try {
    const stream = yield call([navigator.mediaDevices, 'getDisplayMedia'], ({
      audio: false,
      video: {
        frameRate: 10,
        height: 1080,
        width: 1920,
      },
    }));
    const track = stream.getTracks()[0];
    const pub = yield call([localFeed, 'publishTrack'], track, { name: `${SHARE_SCREEN}_${localFeed.identity}` });

    localFeed.emit('trackSubscribed', pub.track);
    yield put(a.twilioShareScreen(localFeed, pub.track));
    const channel = yield call(screenMediaEventChannel, track, localFeed, pub);

    yield put(a.setPinnedFeed(localFeed, pub.track.id || pub.track.sid));

    yield takeEvery(channel, putFromChannel);
    yield take(t.TWILIO_UPDATE_SHARE_SCREEN);
    channel.close();

    const pinnedTrackId = yield select(pinnedTrackIdSelector);
    if (pinnedTrackId === (track.id || track.sid)) {
      yield put(a.unpinFeed());
    }
  } catch (err) {
    console.log(err);
  }
}

function roomStatsEventChannel(room) {
  return eventChannel(emitter => {
    const iv = setInterval(() => {
      // Update room stats
      room.getStats().then(stats => {
        if (stats.length > 0) {
          emitter(a.setAudioTrackStats([...stats[0].localAudioTrackStats, ...stats[0].remoteAudioTrackStats]))
        }
      })

      // Update room participants
      emitter(a.refreshTwilioRemoteParticipants(Array.from(room.participants.values())))

    }, 500);
    return () => {
      clearInterval(iv)
    }
  }
  )
}

function* refreshTwilioRoomParticipantsHandler({ payload: { participants } }) {
  const remoteFeeds = yield select(remoteFeedsSelector);

  if (remoteFeeds.length === participants.length) return;

  yield put(a.setTwilioRemoteParticipants(participants))
}

function* subscribeTwilioRoomChannelSaga(room) {
  yield put(a.setTwilioDominantSpeaker(room.dominantSpeaker));
  logger.info('Set dominant speaker', {
    step: 'subscribe.twilio.room.channel',
    dominantSpeaker: room.dominantSpeaker
  })
  yield put(a.setTwilioLocalParticipant(room.localParticipant));
  logger.info('Set local participant', {
    step: 'subscribe.twilio.room.channel',
    localParticipant: room.localParticipant
  })

  yield put(a.setTwilioRemoteParticipants(Array.from(room.participants.values())));
  logger.info('Set remote participant', {
    step: 'subscribe.twilio.room.channel',
    remoteParticipant: Array.from(room.participants.values())
  })

  const channel = yield call(twilioRoomEventChannel, room);
  logger.info('Created twilio room channel', {
    step: 'subscribe.twilio.room.channel',
    room,
  })

  yield takeEvery(channel, putFromChannel);

  const statsChannel = yield call(roomStatsEventChannel, room);
  yield takeEvery(statsChannel, putFromChannel);

  yield take(t.DESTROY_SESSION);
  logger.info('Twilio session is destroyed', {
    step: 'subscribe.twilio.room.channel',
  })

  // Close video/audio tracks for local feed
  const localFeeds = yield select(localFeedsSelector);
  const localFeed = localFeeds[0];
  if (localFeed) {
    trackPubsToTracks(localFeed.tracks).forEach((track) => {
      track.stop();
    })
  }

  room.disconnect();

  channel.close();
}

function screenMediaEventChannel(track, localFeed, pub) {
  return eventChannel((emitter) => {
    track.onended = () => {
      localFeed.unpublishTrack(track);
      localFeed.emit('trackUnsubscribed', pub.track);
      emitter(a.twilioShareScreen(localFeed, null));
    };

    return () => { };
  });
}

function twilioRoomEventChannel(room) {
  return eventChannel((emitter) => {
    const participantConnected = (participant) => {
      logger.info('Participant joined', {
        step: 'twilio.room.event.channel',
        participant,
      })
      emitter(a.roomPublisherJoined(participant));
      emitter(a.addTwilioRemoteParticipant(participant));
      emitter(a.removeOnWaitingRoomParticipants(participant.identity));
    };

    const participantDisconnected = (participant) => {
      logger.info('Participant disconnected', {
        step: 'twilio.room.event.channel',
        participant
      })
      emitter(a.roomPublisherLeft(participant));
      emitter(a.removeTwilioRemoteParticipant(participant));
    };

    const dominantSpeakerChanged = (dominantSpeaker) => {
      emitter(a.setTwilioDominantSpeaker(dominantSpeaker));
    };

    const trackSubscribed = (track, participant) => {
      logger.info('Track subscribed', {
        step: 'twilio.room.event.channel',
        track,
        participant
      })
      if (track.name?.startsWith(SHARE_SCREEN)) {
        emitter(a.roomShareScreenStarted(track));
        emitter(a.twilioShareScreen(participant, track));
      }
      emitter(a.updateTwilioRemoteParticipants());
    };

    const trackUnsubscribed = (track, participant) => {
      logger.info('Track unsubscribed', {
        step: 'twilio.room.event.channel',
        track,
        participant
      })
      if (track.name?.startsWith(SHARE_SCREEN)) {
        emitter(a.roomShareScreenFinished(track));
        emitter(a.twilioShareScreen(participant, null));
      }
      emitter(a.updateTwilioRemoteParticipants());
    };

    const disconnect = () => room.disconnect()

    window.addEventListener('beforeunload', disconnect)
    room.once('disconnected', () => {
      window.removeEventListener('beforeunload', disconnect)
      isMobile() && window.removeEventListener('pagehide', disconnect)
    })

    room.on('participantConnected', participantConnected);
    room.on('participantDisconnected', participantDisconnected);
    room.on('dominantSpeakerChanged', dominantSpeakerChanged);
    room.on('trackSubscribed', trackSubscribed);
    room.on('trackUnsubscribed', trackUnsubscribed);
    return () => {
      room.off('participantConnected', participantConnected);
      room.off('participantDisconnected', participantDisconnected);
      room.off('trackSubscribed', trackSubscribed);
      room.off('trackUnsubscribed', trackUnsubscribed);
    };
  });
}

function* blurTrackHandler({ payload: { videoTrackId } }) {
  const localFeeds = yield select(localFeedsSelector);
  const localFeed = localFeeds[0];

  if (localFeed) {
    const videoTrack = trackPubsToTracks(localFeed.videoTracks).find(track => track.id === videoTrackId || track.sid === videoTrackId);

    if (videoTrack) {
      if (isSupported) {
        const bg = new GaussianBlurBackgroundProcessor({
          assetsPath: '/',
          maskBlurRadius: 10,
          blurFilterRadius: 5,
        });

        yield call([bg, 'loadModel']);

        ['_tflite', '_currentMask', '_dummyImageData', '_masks'].forEach(key => {
          Object.defineProperty(bg, key, {
            configurable: true,
            enumerable: false,
            value: bg[key],
            writable: true,
          })
        })
        videoTrack.addProcessor(bg)
      }
    }
  }
}

function* unBlurTrackHandler({ payload: { videoTrackId } }) {
  const localFeeds = yield select(localFeedsSelector);
  const localFeed = localFeeds[0];

  if (localFeed) {
    const videoTrack = trackPubsToTracks(localFeed.videoTracks).find(track => track.id === videoTrackId || track.sid === videoTrackId);

    if (videoTrack && videoTrack.processor) {
      videoTrack.removeProcessor(videoTrack.processor);
    }
  }
}

function* receiveJoinRequestSaga() {
  const layout = yield select(layoutSelector);

  if (layout.length === 0) {
    return;
  }
  const audio = new Howl({
    src: [joinAudio]
  });
  if (!debounceTimer) {
    try {
      yield call([audio, 'play']);

      debounceTimer = setTimeout(() => {
        debounceTimer = null;
      }, AUDIO_DEBOUNCE_DELAY_IN_SEC * 1000);
    } catch (error) {
      console.log(error)
    }
  }

  if (repeatTimer) {
    clearInterval(repeatTimer);
  }

  repeatTimer = setInterval(() => {
    const { waitingParticipants } = store.getState().conference;
    if (waitingParticipants.length > 0) {
      try {
        audio.play();
      } catch (error) {
        console.log(error)
      }
    }
  }, AUDIO_REPEAT_INTERVAL_IN_SEC * 1000);
}

function* receiveEvent({ payload }) {
  logger.info('Received event through iot', {
    step: 'receive.event.saga',
    payload,
  })
  const { eventName } = payload;
  const vcInfo = yield select(vcInfoSelector);
  const userInfo = yield select(currentUserSelector);
  const isJoining = yield select(isJoiningSelector);
  const isAccepted = yield select(isAcceptedSelector);
  const localFeeds = yield select(localFeedsSelector);
  const isIotConnected = yield select(isIotConnectedSelector)
  const isRequested = yield select(({ chat }) => chat.isRequested)
  const localFeed = localFeeds[0];
  const isHost = vcInfo?.host_id === userInfo?.id;
  const sessionId = sessionStorage.getItem('videoSessionId');

  switch (eventName) {
    case 'host_joined':
      if (isJoining && !isAccepted) {
        logger.info('Hosted join so sending request to join again', {
          step: 'receive.event.saga',
        })
        yield put(chatActions.requestJoinIoT());
      }

      if (isIotConnected && vcInfo && userInfo && !isHost) {
        logger.info('Hosted join so sending request to notify that user is on waiting room', {
          step: 'receive.event.saga',
        })
        yield put(chatActions.onWaitingRoomIoT());
      }
      break;
    case 'request_join':
      if (isHost) {
        yield put(a.receiveJoinRequest(payload.participantId))
      } else {
        logger.info('Received request to join but skipping as user is not a host', {
          step: 'receive.event.saga',
        })
      }
      break
    case 'on_waiting_room':
      if (isHost) {
        yield put(a.receiveOnWaitingRoomRequest(payload.participantId))
      } else {
        logger.info('Received notification about user is on waiting room but skipping as user is not a host', {
          step: 'receive.event.saga',
        })
      }
      break
    case 'leave_waiting_room':
      if (isHost) {
        yield put(a.removeOnWaitingRoomParticipants(payload.participantId));
        yield put(a.removeWaitingParticipant(payload.participantId));
      } else {
        logger.info('Received notification about user is leaving waiting room but skipping as user is not a host', {
          step: 'receive.event.saga',
        })
      }
      break
    case 'accept_join_request':
      if (userInfo.id === payload.participantId) {
        logger.info('User\'s request to join has been approved', {
          step: 'receive.event.saga',
        })

        if (isRequested) {
          yield put(
            a.createSessionSuccess({ twilioToken: payload.token, roomId: sessionId })
          )
          yield put(a.joinTwilioRoom())
        }
      } else {
        // make sure newly accepted participants get added to the vcInfo.participants list
        yield put(
          a.acceptJoinRequest(payload.participant)
        )
      }
      break;
    case 'remove_participant':
      if (userInfo.id === payload.participantId) {
        yield put(
          a.destroySession()
        )
        yield put(a.setIsRemoved(true))
        yield put(a.exitFromCall())
      }
      break;
    case 'decline_join_request':
      if (userInfo.id === payload.participantId) {
        logger.info('My request to join has been declined', {
          step: 'receive.event.saga',
        })
        yield put(
          a.setIsDeclined(true)
        )
      }
      break;
    case 'end_call':
      if (!isHost) {
        yield put(a.callClosed());
        yield put(a.destroySession());
        yield put(a.exitFromCall())
      }
      break;
    case 'update_host':
      yield put(
        a.updateHost(payload.hostId)
      )
      break;
    case 'mute_participant':
      if (userInfo.id === payload.participantId) {
        if (localFeed) {
          const audioTracks = trackPubsToTracks(localFeed.audioTracks);
          const audioTrack = audioTracks[0];

          if (audioTrack) {
            if (audioTrack.isEnabled) {
              audioTrack.disable();

              yield put(setMutedStreamsIoT(localFeed.sid, true));
            }
          }
        }
      }
      break;
    default:
      break;
  }
}

export default function* conferenceSaga() {
  yield takeLatest(t.SET_DEVICES, setDevicesSaga);
  yield fork(subscribeSessionStartSaga);
  yield fork(subscribeToRemoveRemoteFeed);
  yield fork(subscribeToReplaceFeedSaga);
  yield fork(subscribeToAttachLocalFeedSaga);
  yield fork(subscribeToDetachLocalFeedSaga);
  yield fork(subscribeToSwitchScreenShareSaga);
  yield takeLeading(t.ROOM_LOCAL_FEED_TOGGLE_MIC, switchLocalFeedMicSaga);
  yield takeLeading(t.ROOM_LOCAL_FEED_TOGGLE_VIDEO, switchLocalFeedVideoSaga);
  yield takeLatest(t.GET_ACCESSIBLE_PROVIDERS, getAccessibleProvidersSaga);
  yield takeEvery(t.ADD_PARTICIPANT, addParticipantSaga);

  yield takeEvery(t.ROOM_PUBLISHERS_JOINED, roomPublishersJoinedHandler);
  yield takeEvery(t.ROOM_SHARE_SCREEN_STARTED, roomShareScreenStartedHandler);
  yield takeEvery(t.ROOM_SHARE_SCREEN_FINISHED, roomShareScreenFinishedHandler);

  yield takeEvery(t.REFRESH_TWILIO_REMOTE_PARTICIPANTS, refreshTwilioRoomParticipantsHandler);

  yield takeEvery(t.REMOVE_DATA_FROM_URL, removeDataFromURLSaga);
  yield takeEvery(t.DESTROY_SESSION, destroySessionHandler);

  yield takeLatest(t.CREATE_SESSION, createSessionHandler);
  yield takeLatest(t.JOIN_TWILIO_ROOM, joinToTwilioRoomHandler);

  yield takeEvery(t.UPLOAD_RECORDING, uploadRecordingSaga);
  yield takeEvery(t.RECEIVE_JOIN_REQUEST, receiveJoinRequestSaga);

  yield takeEvery(t.BLUR_TRACK, blurTrackHandler);
  yield takeEvery(t.UNBLUR_TRACK, unBlurTrackHandler);

  yield takeEvery(t.RECEIVE_EVENT, receiveEvent);
}
