export const SET_VIDEO_DEVICES = 'SET_VIDEO_DEVICES';
export const SET_AUDIO_DEVICES = 'SET_AUDIO_DEVICES';

export const SELECT_MOUTH_WATCH_DEVICES = 'SELECT_MOUTH_WATCH_DEVICES';
export const SET_LOCAL_DEVICES = 'SET_LOCAL_DEVICES';

export const ATTACH_MCU_ERROR = 'ATTACH_MCU_ERROR';
export const ROOM_EXISTS_ERROR = 'ROOM_EXISTS_ERROR';
export const CREATE_ROOM_ERROR = 'CREATE_ROOM_ERROR';
export const AUDIO_DISABLED = 'AUDIO_DISABLED';
export const JOIN_TO_ROOM = 'JOIN_TO_ROOM';
export const CLEAN_FEEDS = 'CLEAN_FEEDS';

export const ROOM_STREAM_READY_TO_PUBLISH = 'ROOM_STREAM_READY_TO_PUBLISH';
export const ROOM_LOCAL_FEED_ATTACH = 'ROOM_LOCAL_FEED_ATTACH';
export const ROOM_LOCAL_FEED_DEATTACH = 'ROOM_LOCAL_FEED_DEATTACH';
export const ROOM_LOCAL_FEED_REPLACE = 'ROOM_LOCAL_FEED_REPLACE';
export const ROOM_LOCAL_FEED_TOGGLE_SCREEN_SHARE = 'ROOM_LOCAL_FEED_TOGGLE_SCREEN_SHARE';
export const ROOM_LOCAL_FEED_TOGGLE_MIC = 'ROOM_LOCAL_FEED_TOGGLE_MIC';
export const ROOM_LOCAL_FEED_TOGGLE_VIDEO = 'ROOM_LOCAL_FEED_TOGGLE_VIDEO';
export const ROOM_REMOTE_FEED_UPDATE = 'ROOM_REMOTE_FEED_UPDATE';
export const ROOM_LOCAL_FEED = 'ROOM_LOCAL_FEED';
export const ROOM_LOCAL_FEED_ERROR = 'ROOM_LOCAL_FEED_ERROR';
export const ROOM_LOCAL_STREAM = 'ROOM_LOCAL_STREAM';

export const ROOM_LOCAL_PUBLISHED = 'ROOM_LOCAL_PUBLISHED';

export const ROOM_REMOTE_FEED_ATTACH = 'ROOM_REMOTE_FEED_ATTACH';
export const ROOM_REMOTE_FEED = 'ROOM_REMOTE_FEED';
export const ROOM_REMOTE_FEED_ERROR = 'ROOM_REMOTE_FEED_ERROR';
export const ROOM_REMOTE_STREAM = 'ROOM_REMOTE_STREAM';
export const ROOM_REMOTE_TALKING = 'ROOM_REMOTE_TALKING';
export const ROOM_REMOTE_UPDATE_STATS = 'ROOM_REMOTE_UPDATE_STATS';
export const ROOM_REMOVE_FEED = 'ROOM_REMOVE_FEED';
export const ROOM_CLEAN_UP = 'ROOM_CLEAN_UP';

export const ROOM_PUBLISHERS_LEFT = 'ROOM_PUBLISHERS_LEFT';
export const ROOM_PUBLISHERS_JOINED = 'ROOM_PUBLISHERS_JOINED';
export const ROOM_SHARE_SCREEN_STARTED = 'ROOM_SHARE_SCREEN_STARTED';
export const ROOM_SHARE_SCREEN_FINISHED = 'ROOM_SHARE_SCREEN_FINISHED';

export const ROOM_DESTROYED = 'ROOM_DESTROYED';
export const ROOM_ICE_ERROR = 'ROOM_ICE_ERROR';

export const ROOM_LOCAL_DATA_ERROR = 'ROOM_LOCAL_DATA_ERROR';
export const ROOM_REMOTE_DATA = 'ROOM_REMOTE_DATA';

export const ROOM_DATA_CHAT = 'ROOM_DATA_CHAT';

export const ROOM_DATA_OPEN = 'ROOM_DATA_OPEN';

export const CREATE_SESSION = 'CREATE_SESSION';
export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS';
export const CREATE_SESSION_ERROR = 'CREATE_SESSION_ERROR';
export const DESTROY_SESSION = 'DESTROY_SESSION';

export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION';
export const TOGGLE_PIP = 'TOGGLE_PIP';
export const MEDIA_PING_DELAY = 10000;

export const GET_USER_INFO = 'GET_USER_INFO';
export const SET_USER_INFO = 'SET_USER_INFO';
export const NO_USER = 'NO_USER';

export const REMOVE_DATA_FROM_URL = 'REMOVE_DATA_FROM_URL';

export const SET_PINNED_FEED = 'SET_PINNED_FEED';
export const UNPIN_FEED = 'UNPIN_FEED';

export const SET_PREVIEW_STREAM = 'SET_PREVIEW_STREAM';

export const SET_LAYOUT_MODE = 'SET_LAYOUT_MODE';

export const SET_DEVICES = 'SET_DEVICES';

export const GET_IS_PARTICIPANT = 'GET_IS_PARTICIPANT';
export const SET_IS_PARTICIPANT = 'SET_IS_PARTICIPANT';

export const GET_VC_INFO = 'GET_VC_INFO';
export const SET_VC_INFO = 'SET_VC_INFO';

export const SET_VC_INFO_ERROR = 'SET_VC_INFO_ERROR';

export const SET_APPOINTMENT_INFO = 'SET_APPOINTMENT_INFO';

export const SET_VIDEO_RECORDING = 'SET_VIDEO_RECORDING';
export const UPLOAD_RECORDING = 'UPLOAD_RECORDING';

export const RECEIVE_FLIPPED_STREAMS = 'RECEIVE_FLIPPED_STREAMS';
export const RECEIVE_MUTED_STREAMS = 'RECEIVE_MUTED_STREAMS';
export const RECEIVE_VIDEO_MUTED_STREAMS = 'RECEIVE_VIDEO_MUTED_STREAMS';

export const ADD_PARTICIPANT = 'ADD_PARTICIPANT';
export const REMOVE_ACCESSIBLE_PROVIDER = 'REMOVE_ACCESSIBLE_PROVIDER';
export const GET_ACCESSIBLE_PROVIDERS = 'GET_ACCESSIBLE_PROVIDERS';
export const GET_ACCESSIBLE_PROVIDERS_ERROR = 'GET_ACCESSIBLE_PROVIDERS_ERROR';
export const UPDATE_ACCESSIBLE_PROVIDERS_LIST = 'UPDATE_ACCESSIBLE_PROVIDERS_LIST';

/// TWILIO
export const JOIN_TWILIO_ROOM = 'JOIN_TWILIO_ROOM';
export const SET_TWILIO_LOCAL_PARTICIPANT = 'SET_TWILIO_LOCAL_PARTICIPANT';
export const SET_TWILIO_REMOTE_PARTICIPANTS = 'SET_TWILIO_REMOTE_PARTICIPANTS';
export const UPDATE_TWILIO_REMOTE_PARTICIPANTS = 'UPDATE_TWILIO_REMOTE_PARTICIPANTS';
export const ADD_TWILIO_REMOTE_PARTICIPANT = 'ADD_TWILIO_REMOTE_PARTICIPANT';
export const REMOVE_TWILIO_REMOTE_PARTICIPANT = 'REMOVE_TWILIO_REMOTE_PARTICIPANT';
export const REFRESH_TWILIO_REMOTE_PARTICIPANTS = 'REFRESH_TWILIO_REMOTE_PARTICIPANTS';
export const TWILIO_UPDATE_SHARE_SCREEN = 'TWILIO_UPDATE_SHARE_SCREEN';
export const SET_TWILIO_DOMINANT_SPEAKER = 'SET_TWILIO_DOMINANT_SPEAKER';
export const EXIT_FROM_CALL = 'EXIT_FROM_CALL';
export const SET_AUDIO_TRACK_STATS = 'SET_AUDIO_TRACK_STATS';

export const BLUR_TRACK = 'BLUR_TRACK';
export const UNBLUR_TRACK = 'UNBLUR_TRACK';

export const SET_IOT_CONNECTED = 'SET_IOT_CONNECTED';
export const ACCEPT_JOIN_REQUEST = 'ACCEPT_JOIN_REQUEST';
export const RECEIVE_JOIN_REQUEST = 'RECEIVE_JOIN_REQUEST';
export const RECEIVE_ON_WAITING_ROOM_REQUEST = 'RECEIVE_ON_WAITING_ROOM_REQUEST';
export const REMOVE_ON_WAITING_ROOM_PARTICIPANT = 'REMOVE_ON_WAITING_ROOM_PARTICIPANT';
export const RECEIVE_EVENT = 'RECEIVE_EVENT';
export const REMOVE_WAITING_PARTICIPANT = 'REMOVE_WAITING_PARTICIPANT';
export const SET_IS_JOINING = 'SET_IS_JOINING';
export const SET_IS_DECLINED = 'SET_IS_DECLINED';
export const ADD_ACCEPTING_PARTICIPANT = 'ADD_ACCEPTING_PARTICIPANT';
export const REMOVE_ACCEPTING_PARTICIPANT = 'REMOVE_ACCEPTING_PARTICIPANT';
export const SET_IS_REMOVED = 'SET_IS_REMOVED';
export const UPDATE_HOST = 'UPDATE_HOST';
export const CALL_CLOSED = 'CALL_CLOSED';
