import * as s from '../../selectors/videoRoom';

import { addParticipant, getAccessibleProviders } from 'modules/conference/actions';
import {
  attachLocalFeed,
  createSession,
  destroySession,
  detachLocalFeed,
  exitFromCall,
  getIsParticipant,
  getVCInfo,
  pipToggle,
  replaceLocalFeed,
  setDevices,
  setLayoutMode,
  toggleScreenshare,
  uploadRecording
} from '../../actions';
import { connectIoT, endCallIoT, leaveWaitingRoomIoT, setIsRecordingIoT, updateHostIoT } from 'modules/chat/actions';
import { currentUserSelector, isPatient, viewPermsSelector } from 'modules/login/selectors';

import Conference from './Conference';
import { addNewImage } from '../../../imageCapture/actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { dominantSpeakerSelector } from 'modules/conference/selectors/videoRoom';
import { enqueueSnackbar } from '../../../notifier/actions';
import { fetchMedia } from 'modules/pastConference/actions';
import { loadConfig } from '../../../serverConfig/actions';
import { mediaListSelector } from 'modules/pastConference/selectors';
import { setAuthorizationHeader } from '../../../login/actions';
import { unreadMessagesSelector } from 'modules/chat/selectors';
import { useStyles } from './Conference-styles';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core';

const mapStateToProps = (state) => ({
  accessibleProviders: s.accessibleProvidersSelector(state),
  config: state.config.roomConfig,
  dominantSpeaker: dominantSpeakerSelector(state),
  flippedStreams: s.flippedStreamsSelector(state),
  isHost: s.isHostSelector(state),
  isPatient: isPatient(state),
  isPublished: false,
  isRecord: s.isRecordSelector(state),
  layout: s.layoutSelector(state),
  layoutMode: s.layoutModeSelector(state),
  localFeeds: s.localFeedsSelector(state),
  mediaList: mediaListSelector(state),
  notification: s.notificationSelector(state),
  notUsedDevices: s.notUsedDevicesSelector(state),
  participants: s.participantsSelector(state),
  pinnedFeed: s.pinnedFeedSelector(state),
  pinnedTrackId: s.pinnedTrackIdSelector(state),
  pip: s.pipStateSelector(state),
  remoteFeeds: s.remoteFeedsSelector(state),
  userInfo: currentUserSelector(state),
  videoDevices: s.videoDevicesSelector(state),
  viewPerms: viewPermsSelector(state),
  vcInfo: s.vcInfoSelector(state),
  unreadMessages: unreadMessagesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  addNewImage: (image) => dispatch(addNewImage(image)),
  addParticipant: (userId) => dispatch(addParticipant(userId)),
  attachLocalFeed: (feedName, offer) => dispatch(attachLocalFeed(feedName, offer)),
  connectIoT: (roomId) => dispatch(connectIoT(roomId)),
  createSession: (config) => dispatch(createSession(config)),
  destroySession: () => dispatch(destroySession()),
  detachLocalFeed: (feedName) => dispatch(detachLocalFeed(feedName)),
  enqueueSnackbar: (message) => dispatch(enqueueSnackbar(message)),
  fetchMedia: (roomId) => dispatch(fetchMedia(roomId, true)),
  getAccessibleProviders: () => dispatch(getAccessibleProviders()),
  getIsParticipant: (roomId) => dispatch(getIsParticipant(roomId)),
  getVCInfo: (roomId) => dispatch(getVCInfo(roomId)),
  loadConfig: () => dispatch(loadConfig()),
  pipToggle: (pip) => dispatch(pipToggle(pip)),
  replaceLocalFeed: (feedName, offer) => dispatch(replaceLocalFeed(feedName, offer)),
  setAuthorizationHeader: (token) => dispatch(setAuthorizationHeader(token)),
  setDevices: () => dispatch(setDevices()),
  setIsRecordingIoT: (recording) => dispatch(setIsRecordingIoT(recording)),
  setLayoutMode: (mode) => dispatch(setLayoutMode(mode)),
  toggleScreenshare: () => dispatch(toggleScreenshare()),
  uploadRecording: (recording) => dispatch(uploadRecording(recording)),
  exitFromCall: () => dispatch(exitFromCall()),
  endCall: () => dispatch(endCallIoT()),
  updateHost: (hostId) => dispatch(updateHostIoT(hostId)),
  leaveWaitingRoom: () => dispatch(leaveWaitingRoomIoT())
});

export default compose(
  withStyles(useStyles),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(Conference));
