import * as at from './actions/actionTypes';

const initialState = {
  roomConfig: {},
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case at.STORE_CONFIG: {
      const { config } = payload;

      return {
        ...state,
        roomConfig: config
      };
    }

    default:
      return state;
  }
};

export default reducer;
