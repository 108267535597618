import { createStyles, makeStyles } from '@material-ui/styles';
import colors from '../../../../colors';

export const useStyles = makeStyles((theme) => createStyles({
  button: {
    margin: theme.spacing(0, 3.75),
    height: theme.spacing(15),
    padding: theme.spacing(1, 1),
    width: theme.spacing(15),
    border: '1px solid',
    color: theme.palette.secondary.main,
    transition: theme.transitions.create(
      ['all'],
      { duration: theme.transitions.duration.complex }
    ),
    '&:hover': {
      color: theme.palette.primary.main,
      boxShadow: theme.shadows[2]
    }
  },
  buttonActive: {
    color: theme.palette.primary.main,
    boxShadow: theme.shadows[2],
  },
  buttonExit: {
    color: colors.primary.white,
    backgroundColor: colors.accents.accent_bright_red,
    boxShadow: theme.shadows[2],
    '&:hover': {
      color: colors.primary.white,
      backgroundColor: colors.accents.accent_bright_red,
      boxShadow: theme.shadows[2],
      opacity: 0.6
    }
  },
  content: {
    flexDirection: 'column',
    alignItems: 'center ',
    padding: 8
  },
  badge: {
  },
  icon: {
    height: theme.spacing(5.25),
    fontSize: theme.spacing(5),
    fill: theme.palette.secondary.main
  },
  text: {
    textAlign: 'center',
    font: '11px Source Sans Pro',
    letterSpacing: '0.9px',
    textTransform: 'uppercase',
  },
  [`@media (max-width: ${768}px)`]: {
    button: {
      height: 50,
      width: 50,
      marginRight: 6.5,
      marginLeft: 6.5,
      marginTop: 0,
      marginBottom: 0,
    },

    text: {
      font: '9px Source Sans Pro',
    },
  }
}));
