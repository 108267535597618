export const useStyles = () => ({
  actionContainer: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end'
  },
  actionItemContainer: {
    cursor: 'pointer',
    flex: '1 0 65px',
    fontSize: 12,
    textAlign: 'center'
  },
  acceptButton: {
    alignItems: 'center',
    backgroundColor: '#49a6ff !important',
    borderRadius: '6px',
    color: '#fff',
    fontSize: '14px',
    height: 30,
    padding: '4px 16px',
  },
  accept: {
    color: '#49A6FF'
  },
  decline: {
    color: '#FF0000A6'
  },
  header: {
    display: 'flex'
  },
  participantItem: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  participantName: {
    color: '#4C575D',
    flexBasis: 150,
    fontSize: 16,
    marginBottom: 0,
    maxWidth: 150
  },
  title: {
    color: '#1EBFA2',
    flex: '1',
    fontWeight: 600,
    fontSize: 12,
    letterSpacing: 2,
    marginBottom: 0,
  }
});
