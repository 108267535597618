import { connect } from 'react-redux';
import _ from 'lodash';
import SwitchFacingModeButton from './SwitchFacingModeButton';
import { localFeedsSelector, previewStreamSelector } from '../../selectors/videoRoom';

const mapStateToProps = (state) => {
  const previewStream = previewStreamSelector(state);
  const stream = _.get(localFeedsSelector(state), '0.stream', previewStream);

  let isDisabled = false;

  const isActive = false;

  if (stream) {
    if (!stream.getVideoTracks() || stream.getVideoTracks().length === 0) {
      isDisabled = true;
    } else {
      const videoTrack = stream.getVideoTracks()[0];

      isDisabled = !videoTrack.enabled;
    }
  }

  return ({
    isActive,
    isDisabled
  });
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SwitchFacingModeButton);
