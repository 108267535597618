import * as at from './actionTypes';

export const fetchMedia = (roomId, onlyImage) => ({
  type: at.FETCH_MEDIA,
  payload: {
    onlyImage,
    roomId
  }
});

export const fetchPatientsList = () => ({
  type: at.FETCH_PATIENTS_LIST
});

export const setPhotosList = (photosList) => ({
  type: at.SET_PHOTOS_LIST,
  payload: photosList
});

export const setVideoList = (videosList) => ({
  type: at.SET_VIDEOS_LIST,
  payload: videosList
});

export const setMediaList = (mediaList) => ({
  type: at.SET_MEDIA_LIST,
  payload: mediaList
});

export const setPatientsList = (patients) => ({
  type: at.SET_PATIENTS_LIST,
  payload: patients
});

export const updatePatientsList = (patients) => ({
  type: at.UPDATE_PATIENTS_LIST,
  payload: patients
});

export const loadMessages = (messages) => ({
  type: at.LOAD_MESSAGES,
  payload: messages
});

export const setLoading = (loading) => ({
  type: at.SET_LOADING,
  payload: loading,
});

export const setPatient = (patient) => ({
  type: at.SET_PATIENT,
  payload: patient,
});

export const attachMediaToPatient = () => ({ type: at.ATTACH_MEDIA_TO_PATIENT });

export const toggleMedia = (id, checked) => ({
  type: at.TOGGLE_MEDIA,
  payload: { id, checked }
});
