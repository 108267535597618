import React, { Component } from 'react';
import { faComments, faTimes } from '@fortawesome/free-solid-svg-icons';

import Box from '@material-ui/core/Box';
import ChatBody from '../ChatBody';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { isEqual } from 'lodash';
import theme from './Chat.module.css';

class Chat extends Component {
  static propTypes = {
    onMessageSend: PropTypes.func.isRequired,
    messages: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    onChatClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.chatBody = React.createRef();
    this.state = {
      input: ''
    };
  }

  componentDidMount() {
    const { resetUnreadMessages } = this.props;

    // if (isFirstLoading) loadChat();
    resetUnreadMessages();
    this.scrollBottom();
  }

  componentDidUpdate(prevProps) {
    const { messages } = this.props;

    if (!isEqual(prevProps.messages, messages)) {
      setTimeout(this.scrollBottom, 1000);
    }
  }

  sendMessage = () => {
    const { onMessageSend, user } = this.props;
    const { input } = this.state;

    if (!input) return;

    const messageData = {
      message: this.state.input,
      timeStamp: Date.now(),
      userId: user.id,
    };

    onMessageSend(messageData);
    this.setState({ input: '' });
  }

  sendMessageHandler = (event) => {
    if (event.keyCode === 13) {
      this.sendMessage();
    }
  };

  scrollBottom = () => {
    this.chatBody.current.scrollTop = this.chatBody.current.scrollHeight;
  };

  onChange = (ev) => {
    this.setState({ input: ev.target.value });
  }

  render() {
    const { messages, onChatClose, user, loading, vcInfo } = this.props;
    const { input } = this.state;

    return (
      <ClickAwayListener onClickAway={onChatClose}>
        <Paper>
          <Box className={theme.container}>
            <div className={theme.header}>
              <div className={theme.headerTitle}>
                <FontAwesomeIcon className={theme.icon} icon={faComments} />
                Chat
              </div>
              <div className={theme.closeContainer}>
                <FontAwesomeIcon className={theme.icon} icon={faTimes} onClick={onChatClose} />
              </div>
            </div>
            <ChatBody messages={messages} user={user} participants={vcInfo.participants} containerRef={this.chatBody} loading={loading} />

            <Box className={theme.textComposer}>
              <TextField
                placeholder="Start writing here…"
                fullWidth
                margin="normal"
                variant="filled"
                InputLabelProps={{ shrink: true }}
                onChange={this.onChange}
                onKeyDown={this.sendMessageHandler}
                className={theme.textField}
                InputProps={{
                  style: {
                    border: '1px solid #60A4FE',
                    borderRadius: 5
                  }
                }}
                value={input}
                disabled={loading}
              />
              <IconButton
                aria-label="send"
                classes={{ root: theme.sendButton }}
                color="primary"
                disabled={loading || !input}
                onClick={this.sendMessage}
              >
                <span>
                  <FontAwesomeIcon icon={faPaperPlane} />
                </span>
              </IconButton>

            </Box>
          </Box>
        </Paper>

      </ClickAwayListener>
    );
  }
}

export default Chat;
