import { apiHost } from 'api2';
import { datadogLogs } from '@datadog/browser-logs'
let logger = datadogLogs.logger;

const dataDogInit = (env) => {
    datadogLogs.init({
        clientToken: 'pub3f167b9458f426d04041cb00d8873a7e',
        forwardConsoleLogs: 'all',
        forwardErrorsToLogs: true,
        silentMultipleInit: true,
        env: apiHost
    })
    datadogLogs.setGlobalContext({
        clientApplication: 'video-conference'
    })

    logger = datadogLogs.logger;
}

export { dataDogInit, logger }
