import * as at from './actionTypes';

export const setVideoDevices = (devices) => ({
  type: at.SET_VIDEO_DEVICES,
  payload: devices,
});

export const setAudioDevices = (devices) => ({
  type: at.SET_AUDIO_DEVICES,
  payload: devices,
});

export const createSession = (config) => ({
  type: at.CREATE_SESSION,
  config,
});
export const destroySession = () => ({
  type: at.DESTROY_SESSION,
});
export const createSessionSuccess = (payload) => ({ type: at.CREATE_SESSION_SUCCESS, payload });
export const createSessionError = (e) => ({ type: at.CREATE_SESSION_ERROR, payload: e });
export const attachLocalFeed = (feedName, offer) => ({
  type: at.ROOM_LOCAL_FEED_ATTACH,
  feedName,
  payload: offer,
});
export const setPreviewStream = (stream) => ({
  type: at.SET_PREVIEW_STREAM,
  payload: { stream },
});
export const detachLocalFeed = (id) => ({
  type: at.ROOM_LOCAL_FEED_DEATTACH,
  payload: { id },
});
export const toggleScreenshare = () => ({
  type: at.ROOM_LOCAL_FEED_TOGGLE_SCREEN_SHARE,
});

export const toggleMic = () => ({
  type: at.ROOM_LOCAL_FEED_TOGGLE_MIC,
});
export const toggleVideo = () => ({
  type: at.ROOM_LOCAL_FEED_TOGGLE_VIDEO,
});
export const replaceLocalFeed = (feedName, offer) => ({
  type: at.ROOM_LOCAL_FEED_REPLACE,
  feedName,
  offer,
});

export const roomRemoteFeedAtach = (id, display) => ({
  type: at.ROOM_REMOTE_FEED_ATTACH,
  id,
  display,
});

export const blurTrack = (videoTrackId) => ({
  type: at.BLUR_TRACK,
  payload: { videoTrackId }
});

export const unBlurTrack = (videoTrackId) => ({
  type: at.UNBLUR_TRACK,
  payload: { videoTrackId }
});

export const attachMcuError = (error) => ({
  type: at.ATTACH_MCU_ERROR,
  message: error,
});
export const roomDataOpen = () => ({
  type: at.ROOM_DATA_OPEN,
});
export const roomLocalFeed = (feed) => ({
  type: at.ROOM_LOCAL_FEED,
  payload: { feed }
});
export const roomDestroyed = () => ({
  type: at.ROOM_DESTROYED,
});
export const roomRemoveFeed = (id) => ({
  type: at.ROOM_REMOVE_FEED,
  payload: { id }
});

export const roomPublisherLeft = (participant) => ({
  type: at.ROOM_PUBLISHERS_LEFT,
  payload: { participant }
});

export const roomPublisherJoined = (participant) => ({
  type: at.ROOM_PUBLISHERS_JOINED,
  payload: { participant }
});

export const roomShareScreenStarted = (track) => ({
  type: at.ROOM_SHARE_SCREEN_STARTED,
  payload: { track }
});

export const roomShareScreenFinished = (track) => ({
  type: at.ROOM_SHARE_SCREEN_FINISHED,
  payload: { track }
});

export const roomCleanUp = (feed, feeds) => ({
  type: at.ROOM_CLEAN_UP,
  feed,
  feeds,
});

export const roomLocalStream = (feed, stream, status) => ({
  type: at.ROOM_LOCAL_STREAM,
  payload: { feed, stream, status }
});
export const roomIceError = (pc) => ({
  type: at.ROOM_ICE_ERROR,
  message: pc.iceConnectionState,
});

export const roomRemoteFeed = (feed) => ({
  type: at.ROOM_REMOTE_FEED,
  payload: { feed }
});
export const roomRemoteFeedError = (error) => ({
  type: at.ROOM_REMOTE_FEED_ERROR,
  message: error,
});

export const roomRemoteData = (data) => ({
  type: at.ROOM_REMOTE_DATA,
  payload: JSON.parse(data),
});

export const roomRemoteChatData = (data) => ({
  type: at.ROOM_DATA_CHAT,
  payload: data,
});

export const roomRemoteStream = (feed, stream, status) => ({
  type: at.ROOM_REMOTE_STREAM,
  payload: { feed, stream, status }
});

export const roomTalkingChange = (feedId, isTalking, lastTalkingChange) => ({
  type: at.ROOM_REMOTE_TALKING,
  payload: { feedId, isTalking, lastTalkingChange }
});
export const roomRemoteUpdateStats = (feedId, stats) => ({
  type: at.ROOM_REMOTE_UPDATE_STATS,
  payload: { feedId, stats }
});

export const pipToggle = (pip) => ({
  type: at.TOGGLE_PIP,
  payload: { pip },
});

export const showNotification = (type, message) => ({
  type: at.SET_NOTIFICATION,
  payload: { type, message },
});

export const resetNotification = () => ({
  type: at.RESET_NOTIFICATION,
});

export const getUserInfo = () => ({ type: at.GET_USER_INFO });
export const setUserInfo = (data) => ({
  type: at.SET_USER_INFO,
  payload: data
});
export const noUser = () => ({
  type: at.NO_USER
});

export const removeDataFromURL = (videoSessionId) => ({ type: at.REMOVE_DATA_FROM_URL, payload: videoSessionId });

export const setPinnedFeed = (pinnedFeed, videoTrackId) => ({
  type: at.SET_PINNED_FEED,
  payload: { videoTrackId, pinnedFeed }
});

export const unpinFeed = (feed) => ({
  type: at.UNPIN_FEED,
});

export const updateRemoteFeed = (id, feedOptions) => ({
  type: at.ROOM_REMOTE_FEED_UPDATE,
  payload: { id, feedOptions }
});

export const setLayoutMode = (mode) => ({
  type: at.SET_LAYOUT_MODE,
  payload: { mode }
});

export const setDevices = () => ({
  type: at.SET_DEVICES
});

export const getIsParticipant = (roomId) => ({
  type: at.GET_IS_PARTICIPANT,
  payload: roomId
});

export const setIsParticipant = (success) => ({
  type: at.SET_IS_PARTICIPANT,
  payload: success
});

export const getVCInfo = (roomId) => ({
  type: at.GET_VC_INFO,
  payload: roomId
});

export const setVCInfo = (vcInfo) => ({
  type: at.SET_VC_INFO,
  payload: vcInfo
});

export const setVCInfoError = (error) => ({
  type: at.SET_VC_INFO_ERROR,
  payload: error
});

export const setAppointmentInfo = (appointment) => ({
  type: at.SET_APPOINTMENT_INFO,
  payload: appointment
});


export const joinTwilioRoom = () => ({
  type: at.JOIN_TWILIO_ROOM
});

export const setTwilioLocalParticipant = (local) => ({
  type: at.SET_TWILIO_LOCAL_PARTICIPANT,
  payload: { local }
});

export const setTwilioDominantSpeaker = (speaker) => ({
  type: at.SET_TWILIO_DOMINANT_SPEAKER,
  payload: { speaker }
});

export const setTwilioRemoteParticipants = (remote) => ({
  type: at.SET_TWILIO_REMOTE_PARTICIPANTS,
  payload: { remote }
});

export const refreshTwilioRemoteParticipants = (participants) => ({
  type: at.REFRESH_TWILIO_REMOTE_PARTICIPANTS,
  payload: { participants }
});

export const addTwilioRemoteParticipant = (remote) => ({
  type: at.ADD_TWILIO_REMOTE_PARTICIPANT,
  payload: { remote }
});

export const removeTwilioRemoteParticipant = (remote) => ({
  type: at.REMOVE_TWILIO_REMOTE_PARTICIPANT,
  payload: { remote }
});

export const twilioShareScreen = (participant, track) => ({
  type: at.TWILIO_UPDATE_SHARE_SCREEN,
  payload: track ? { participant, track } : null
});

export const updateTwilioRemoteParticipants = () => ({
  type: at.UPDATE_TWILIO_REMOTE_PARTICIPANTS,
});

export const setAudioTrackStats = (stats) => ({
  type: at.SET_AUDIO_TRACK_STATS,
  payload: stats
});

export const setRecording = (recording) => ({
  type: at.SET_VIDEO_RECORDING,
  payload: recording
});

export const uploadRecording = (recording) => ({
  type: at.UPLOAD_RECORDING,
  payload: { blob: recording }
});

export const receiveFlippedStreams = (flippedStreams) => ({
  type: at.RECEIVE_FLIPPED_STREAMS,
  payload: flippedStreams
});

export const receiveMutedStreams = (mutedStreams) => ({
  type: at.RECEIVE_MUTED_STREAMS,
  payload: mutedStreams
});

export const receiveVideoMutedStreams = (mutedStreams) => ({
  type: at.RECEIVE_VIDEO_MUTED_STREAMS,
  payload: mutedStreams
});

export const setIotConnected = (isIotConnected) => ({
  type: at.SET_IOT_CONNECTED,
  payload: { isIotConnected },
});

export const acceptJoinRequest = (participant) => ({
  type: at.ACCEPT_JOIN_REQUEST,
  payload: { participant }
});

export const receiveJoinRequest = (participantId) => ({
  type: at.RECEIVE_JOIN_REQUEST,
  payload: { participantId },
});

export const receiveOnWaitingRoomRequest = (participantId) => ({
  type: at.RECEIVE_ON_WAITING_ROOM_REQUEST,
  payload: { participantId },
});

export const removeOnWaitingRoomParticipants = (participantId) => ({
  type: at.REMOVE_ON_WAITING_ROOM_PARTICIPANT,
  payload: { participantId },
})

export const receiveEvent = (payload) => ({
  type: at.RECEIVE_EVENT,
  payload,
});

export const removeWaitingParticipant = (participantId) => ({
  type: at.REMOVE_WAITING_PARTICIPANT,
  payload: { participantId },
});

export const addAcceptingParticipant = (participantId) => ({
  type: at.ADD_ACCEPTING_PARTICIPANT,
  payload: { participantId },
});

export const removeAcceptingParticipant = (participantId) => ({
  type: at.REMOVE_ACCEPTING_PARTICIPANT,
  payload: { participantId },
});

export const setIsJoining = (isJoining) => ({
  type: at.SET_IS_JOINING,
  payload: { isJoining },
});

export const setIsRemoved = (isRemoved) => ({
  type: at.SET_IS_REMOVED,
  payload: { isRemoved },
});

export const setIsDeclined = (isDeclined) => ({
  type: at.SET_IS_DECLINED,
  payload: { isDeclined },
});

export const exitFromCall = () => ({
  type: at.EXIT_FROM_CALL,
});

export const updateHost = (hostId) => ({
  type: at.UPDATE_HOST,
  payload: { hostId }
});

export const callClosed = () => ({
  type: at.CALL_CLOSED,
});

export const addParticipant = (userId) => ({
  type: at.ADD_PARTICIPANT,
  payload: { userId }
});

export const getAccessibleProviders = () => ({
  type: at.GET_ACCESSIBLE_PROVIDERS
});

export const getAccessibleProvidersError = () => ({
  type: at.GET_ACCESSIBLE_PROVIDERS_ERROR
});

export const removeAccessibleProvider = (id) => ({
  type: at.REMOVE_ACCESSIBLE_PROVIDER,
  payload: { id }
});

export const updateAccessibleProvidersList = (count, data) => ({
  type: at.UPDATE_ACCESSIBLE_PROVIDERS_LIST,
  payload: { count, data }
});
