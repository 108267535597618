export const useStyles = ({ palette: { common } }) => ({
  participantItem: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  participantName: {
    flex: 1,
    color: '#4C575D',
    fontSize: 16,
    marginBottom: 0,
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actionItemContainer: {
    cursor: 'pointer',
    padding: 4,
    marginLeft: 10,
    minWidth: 0,
  },
  actionItem: {
    color: '#49A6FF',
  },
  actionDisabled: {
    opacity: 0.7
  },
  listItemText: {
    margin: 0,
    letterSpacing: 1.2,
    color: '#848A8D',
    textAlign: 'center'
  },
  listItemTextPrimary: {
    fontSize: '0.75rem',
    fontWeight: 600,
    color: '#49A6FF',
    margin: '4px 0',
  },
  listItemTextSecondary: {
    fontSize: '0.75rem',
    fontWeight: 600,
    color: '#FF0000A6',
    margin: '4px 0',
  },
  list: {
    padding: '20px',
    cursor: 'pointer',
  },
});
  