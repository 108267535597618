import { find, get, isObject, uniqBy, values } from 'lodash';

import { createSelector } from 'reselect';
import { localFeedsSelector } from '../conference/selectors/videoRoom';

export const messagesSelector = (state) => uniqBy(state.chat.messages, 'timeStamp');

export const feedsSelector = ({ conference: state }) => {
  return values(get(state, 'feeds', null)).filter((i) => isObject(i));
};

export const userSelector = createSelector(
  localFeedsSelector,
  (feeds) => { return get(feeds, '0.user', { id: null, name: 'Noname' }); }
);

export const dataFeedSelector = createSelector(
  feedsSelector,
  (feeds) => find(feeds,
    (feed) => {
      // const data = get(feed, 'offer.media.data');
      // return !feed.remote && data;

      return !feed.remote;
    })
);

export const unreadMessagesSelector = ({ chat: state }) => get(state, 'unreadMessages', 0);

export const loadingSelector = ({ chat: state }) => get(state, 'loading', false);

export const isFirstLoadingSelector = ({ chat: state }) => get(state, 'isFirstLoading', true);
export const isRequestedSelector = ({ chat: state }) => get(state, 'isRequested');
