import APImethods2, { instance as instance2 } from '../../api2';
import { GET_IS_PARTICIPANT, GET_USER_INFO, GET_VC_INFO } from '../conference/actions/actionTypes';
import { LOGIN_REQUESTING, SET_AUTHORIZATION_HEADER, } from './actions/actionTypes';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { getUserInfo, noUser, setAppointmentInfo, setIsParticipant, setUserInfo, setVCInfo, setVCInfoError } from '../conference/actions';
import { loginFailure, loginSuccess, setToken } from 'modules/login/actions';

import { datadogLogs } from '@datadog/browser-logs'
import { enqueueSnackbar } from '../notifier/actions';
import { instance } from '../../api';
import { logger } from 'utils/logger';

export function handleApiErrors(response) {
  if (!response.ok) throw Error(response.statusText);
  return response;
}

function* loginSaga({ username, password }) {
  try {
    const { data: resData } = yield call([APImethods2, 'post'], '/authenticate', {
      username,
      password
    });

    yield call(setAuthorizationHeader, { payload: resData.data.token });
    yield put(loginSuccess());
    // If there is redirect uri in search, then do redirect
    const params = new URLSearchParams(window.location.search);

    if (params.get('redirect_uri')) {
      window.location.href = params.get('redirect_uri');
    } else {
      window.location.href = '/';
    }

    logger.info(`User logs in successfully from login page. And redirecting to ${window.location.href}`)
  } catch (e) {
    yield put(loginFailure('You have entered an invalid username or password.'));
  }
}

function* getUserInfoSaga() {
  try {
    const { data: { data: userInfo } } = yield call([APImethods2, 'get'], '/me');

    let groupInfo;

    if (userInfo.is_patient && userInfo.status !== 'active') {
      groupInfo = (yield call([APImethods2, 'get'], `/groups/${userInfo.group_id}`)).data.data;
    }

    yield put(setUserInfo({ groupInfo, userInfo }));
    datadogLogs.setGlobalContext({
      ...datadogLogs.getGlobalContext(),
      user_id: userInfo.id
    })

    yield sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
  } catch (e) {
    yield call(setAuthorizationHeader, { payload: null });
    yield put(enqueueSnackbar('401 Unauthorized', { variant: 'error' }));
  }
}

function* getIsParticipantSaga({ payload: roomId }) {
  try {
    const { data: { data: success } } = yield call([APImethods2, 'post'], '/videoConferences/checkParticipants', {
      room_id: roomId
    });

    if (success) {
      logger.info('Confirmed that user is in participant lists.')
    } else {
      logger.info('This user is not in participants list')
    }
    yield put(setIsParticipant(success));
  } catch (e) {
    logger.error('Checking if user is in participants failed.', {
      error: e.message
    })
    yield put(setIsParticipant(false));
    yield put(enqueueSnackbar('404 NotFound', { variant: 'error' }));
  }
}

function* getVCInfoSaga({ payload: roomId }) {
  try {
    yield put(setVCInfoError(null))

    const { data: { data: vcInfo } } = yield call([APImethods2, 'get'], `/video_conference_rooms/${roomId}`);
    if (vcInfo.appointment_id) {
      const { data: { data: appointment } } = yield call([APImethods2, 'get'], `/appointments/${vcInfo.appointment_id}`);
      yield put(setAppointmentInfo(appointment));
    }

    datadogLogs.setGlobalContext({
      ...datadogLogs.getGlobalContext(),
      host_id: vcInfo.host_id
    })
    logger.info('Successfully loaded vc info', {
      vcInfo
    })

    yield put(setVCInfo(vcInfo));
  } catch (e) {
    logger.error('Loading vc info is failed', {
      error: e.message
    })

    yield put(setVCInfoError(e.message))
  }
}

function* setAuthorizationHeader({ payload: authToken }) {
  if (authToken) {
    yield instance.defaults.headers.common.Authorization = `Bearer ${authToken}`;
    yield instance2.defaults.headers.common.Authorization = authToken;
    yield sessionStorage.setItem('authToken', authToken);
    yield put(setToken(authToken));
    yield put(getUserInfo());
  } else {
    logger.info('Auth token was empty or invalid. Redirecting to login page.')
    // delete instance.defaults.headers.common.Authorization;
    window.location.href = `/login?redirect_uri=${window.location.pathname}`;
    yield put(noUser());
  }
}

export function* loginWatcher() {
  yield takeLatest(SET_AUTHORIZATION_HEADER, setAuthorizationHeader);
  yield takeEvery(GET_USER_INFO, getUserInfoSaga);
  yield takeLatest(GET_IS_PARTICIPANT, getIsParticipantSaga);
  yield takeLatest(GET_VC_INFO, getVCInfoSaga);
  yield takeLatest(LOGIN_REQUESTING, loginSaga);
}
