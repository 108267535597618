import { CircleButtonWithText } from '../CircleButtonWithText/CircleButtonWithText';
import DetectRTC from 'detectrtc';
import PropTypes from 'prop-types';
import React from 'react';
import { faChromecast } from '@fortawesome/free-brands-svg-icons';

const ScreenSharingButton = ({ onClick, isActive, isDisabled }) => {
  if (DetectRTC.isScreenCapturingSupported) {
    return (
      <CircleButtonWithText
        onClick={onClick}
        icon={faChromecast}
        text="Present"
        isDisabled={isDisabled}
        isActive={isActive}
      />
    );
  } else return null;
};

export default ScreenSharingButton;

ScreenSharingButton.propTypes = {
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func
};

ScreenSharingButton.defaultProps = {
  isActive: false,
  isDisabled: false,
  onClick: () => {}
};
