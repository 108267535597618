import cn from 'classnames';
import PreviewStream from 'modules/conference/components/PreviewStream';
import * as React from 'react';
import Select from 'react-select';

import * as Modal from './Modal';
import styles from './styles.module.scss';

const ChangeDevicesModal = ({ cameraOptions, classes, close, deviceId, handleChangeCamera, isMuted, isOpen }) =>{
  const selectedCameraOption = cameraOptions?.filter(({ value }) => value === deviceId)
  const renderDropdown = () => (
    <div className={cn(classes.formWrapper)}>
      <h6 className={classes.title}>
        Camera
      </h6>
      <Select
        value={selectedCameraOption}
        onChange={handleChangeCamera}
        options={cameraOptions}
        className={cn('basic-single', classes.select)}
        classNamePrefix="select"
      />
    </div>
  )

  return (
    <Modal.Wrapper backdrop close={close} isOpen={isOpen} size='md'>
      <Modal.Header>
        <div className={styles.title}>Change Your Camera<span onClick={() => close()}>X</span></div>
      </Modal.Header>
      <Modal.Body className={styles.changeDevicesModal}>
        <div className={styles.content}>
          <div className={styles.leftSide}>{<PreviewStream isMuted={isMuted} />}</div>
          <div className={styles.rightSide}>{renderDropdown()}</div>
        </div>
      </Modal.Body>
    </Modal.Wrapper>
  )
}

export default ChangeDevicesModal;
