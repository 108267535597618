import cn from 'classnames';
import { get, map as lMap } from 'lodash';
import React, { Component } from 'react';
import ReactGridLayout from 'react-grid-layout';
import { SizeMe } from 'react-sizeme';

import { margin, maxX, maxY } from '../../selectors/videoRoom';
import Stream from '../Stream';

const screenHeight = window.screen.height / 1.5;
const screenWidth = window.screen.width / 1.5;
const MAXIMUM_COUNT_IN_ROW = 4;

class ConferenceDesktop extends Component {
  audios = [];
  chunks = [];
  videos = [];
  audioContext = null;
  mediaRecorder = null;
  mediaStreamAudioDestinationNode = null;
  mediaStreamAudioSourceNodes = [];
  state = {
    layouts: [],
    galleryCountInRow: MAXIMUM_COUNT_IN_ROW,
  }

  componentDidMount() {
    this.createCanvas();
    window.addEventListener('beforeunload', this.beforeUnload);
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  componentDidUpdate(prevProps) {
    const layoutModeChangedToGallery = prevProps.layoutMode !== this.props.layoutMode && this.props.layoutMode === 'gallery';
    const capturedMediaListToggled = prevProps.capturedImagesIsOpen !== this.props.capturedImagesIsOpen;
    const participantListToggled = prevProps.isParticipantsOpen !== this.props.isParticipantsOpen;
    if (layoutModeChangedToGallery || capturedMediaListToggled || participantListToggled) {
      this.onResize();
    }

    if (!prevProps.isRecordingLocally && this.props.isRecordingLocally) {
      if (this.videoStream) {
        this.chunks = [];

        let options;

        if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
          options = { mimeType: 'video/webm; codecs=vp9' };
        } else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8')) {
          options = { mimeType: 'video/webm; codecs=vp8' };
        }

        this.audioContext = new AudioContext();
        this.mediaStreamAudioDestinationNode = new MediaStreamAudioDestinationNode(this.audioContext);

        this.props.layout.forEach((el) => {
          if (!this.audios[el.i]) return;
          const audioStream = this.audios[el.i].captureStream();
          const mediaStreamAudioSourceNode = new MediaStreamAudioSourceNode(
            this.audioContext,
            { mediaStream: audioStream }
          );

          mediaStreamAudioSourceNode.connect(this.mediaStreamAudioDestinationNode);
          this.mediaStreamAudioSourceNodes[el.i] = mediaStreamAudioSourceNode;
        });

        this.updateAudioOnRecording();

        this.mediaRecorder = new MediaRecorder(this.videoStream, options);
        this.mediaRecorder.onstop = () => {
          const blob = new Blob(this.chunks, { type: 'video/webm' });

          this.props.uploadRecording(blob);
        };
        this.mediaRecorder.ondataavailable = (e) => {
          this.chunks.push(e.data);
        };

        this.mediaRecorder.start();
      }
    } else if (prevProps.isRecordingLocally && !this.props.isRecordingLocally) {
      if (this.mediaRecorder && this.mediaRecorder.state === 'recording') {
        this.mediaRecorder.stop();
      }
    } else if (JSON.stringify(prevProps.layout) !== JSON.stringify(this.props.layout)) {
      this.setState({ layouts: this.props.layout })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeUnload);
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    const elm = document.getElementById('gallery');

    if (elm) {
      const width = elm.getBoundingClientRect().width;
      let countInRow = MAXIMUM_COUNT_IN_ROW;
      if (width < 600) {
        countInRow = 1;
      } else if (width < 900) {
        countInRow = 2;
      } else if (width < 1200) {
        countInRow = 3;
      }

      this.setState({ galleryCountInRow: countInRow })
    }
  }

  beforeUnload = (e) => {
    const { isRecord, isRecordingLocally, setIsRecordingIoT, setIsRecordingLocally } = this.props;

    if (isRecord && isRecordingLocally) {
      e.preventDefault();
      setIsRecordingIoT(false);
      setIsRecordingLocally(false);
      e.returnValue = 'Your recording will not be saved correctly if you exit.';
    }
  }

  updateAudioOnRecording = () => {
    const audioTrack = this.mediaStreamAudioDestinationNode.stream.getTracks()[0];

    if (!audioTrack) return;

    if (this.props.layout.some((el) => this.audios[el.i])) {
      this.videoStream.addTrack(audioTrack);
    } else {
      this.videoStream.removeTrack(audioTrack);
    }
  }

  onVideoReady = (i) => (video) => {
    this.videos[i] = video;
  }

  onAudioReady = (i) => (audio) => {
    this.audios[i] = audio;
    if (this.audioContext && this.mediaStreamAudioDestinationNode && this.mediaRecorder && this.mediaRecorder.state === 'recording') {
      if (this.mediaStreamAudioSourceNodes[i]) {
        this.mediaStreamAudioSourceNodes[i].disconnect();
        this.mediaStreamAudioSourceNodes[i] = null;
      }
      if (audio) {
        const audioStream = audio.captureStream();
        const mediaStreamAudioSourceNode = new MediaStreamAudioSourceNode(
          this.audioContext,
          { mediaStream: audioStream }
        );

        mediaStreamAudioSourceNode.connect(this.mediaStreamAudioDestinationNode);
        this.mediaStreamAudioSourceNodes[i] = mediaStreamAudioSourceNode;
      }

      this.updateAudioOnRecording();
    }
  }

  sizeW = () => {
    const { layout } = this.props;
    const colCount = layout.length > 3 ? 4 : layout.length || 1;
    const w = Math.ceil(24 / colCount);
    const baseW = screenWidth / 24;
    const sizeW = baseW * w;

    return sizeW;
  }

  createCanvas = () => {
    const canvas = document.createElement('canvas');

    canvas.width = screenWidth;
    canvas.height = screenHeight;

    this.ctx = canvas.getContext('2d');

    this.videoStream = canvas.captureStream(30);

    setInterval(this.drawVideosToCanvas, 1000 / 30);
  }

  drawVideosToCanvas = () => {
    const { layout, flippedStreams } = this.props;

    if (this.ctx) {
      const colCount = layout.length > 3 ? 4 : layout.length;
      const rowCount = Math.min(layout.length, 3);
      const w = Math.ceil(24 / colCount);
      const h = Math.ceil(12 / rowCount);
      const baseW = screenWidth / 24;
      const baseH = screenHeight / 12;
      const sizeW = baseW * w;
      const sizeH = baseH * h;

      this.ctx.fillStyle = 'black';
      this.ctx.fillRect(0, 0, screenWidth, screenHeight);
      layout.forEach((el, i) => {
        const video = this.videos[el.i];
        const isFlipped = flippedStreams.includes(el.feed.sid);
        const stream = isFlipped ? this.flipStream(video) : video;

        const row = Math.floor(i / colCount) * h;
        const col = (i % colCount) * w;

        if (video) {
          if (video.videoWidth / video.videoHeight > sizeW / sizeH) {
            this.ctx.drawImage(stream, (video.videoWidth - sizeW / sizeH * video.videoHeight) / 2, 0, sizeW / sizeH * video.videoHeight, video.videoHeight, baseW * col, baseH * row, sizeW, sizeH);
          } else {
            this.ctx.drawImage(stream, 0, (video.videoHeight - sizeH / sizeW * video.videoWidth) / 2, video.videoWidth, sizeH / sizeW * video.videoWidth, baseW * col, baseH * row, sizeW, sizeH);
          }
        }
      });
    }
  }

  flipStream = (stream) => {
    if (!stream.videoHeight || !stream.videoWidth) return stream;
    const canvas = document.createElement('canvas');

    canvas.width = stream.videoWidth;
    canvas.height = stream.videoHeight;
    const ctx = canvas.getContext('2d');

    ctx.scale(-1, 1);
    ctx.translate(-canvas.width, 0);
    ctx.drawImage(stream, 0, 0, stream.videoWidth, stream.videoHeight);
    return canvas;
  }

  gridLayoutProps = (size) => {
    const { classes, layoutMode } = this.props;
    const isSpotlight = layoutMode === 'spotlight'
    const gridLayoutClassName = cn(classes.layout);

    const commonProps = {
      className: gridLayoutClassName,
      margin: [margin, margin],
      maxRows: maxY,
      cols: maxX,
      allowOverlap: isSpotlight ? true : false,
      autoSize: false,
      preventCollision: false,
      useCSSTransforms: false,
      verticalCompact: true,
      rowHeight: Math.ceil(((size.height - 10) + (margin * 4)) / maxY) - margin * 1.5,
      isResizable: false
    };

    return commonProps
  }

  renderStream(stream, hidePin) {
    const {
      detachLocalFeed,
      isRecord,
      pip, pipToggle,
      match: { params: { roomId: videoSessionId } },
      openCapturedImages
    } = this.props;

    return (
      <Stream
        feed={stream.feed}
        grid={stream}
        isMasterFeed={stream.isMasterFeed}
        isRecord={isRecord}
        onAudioReady={this.onAudioReady(stream.i)}
        onVideoReady={this.onVideoReady(stream.i)}
        pip={pip}
        pipToggle={pipToggle}
        remote={stream.remote}
        share={stream.share}
        videoTrackId={stream.videoTrackId}
        roomId={videoSessionId}
        hidePin={hidePin}
        openCapturedImages={openCapturedImages}
        detachLocalFeed={detachLocalFeed}
      />
    )
  }

  render() {
    const {
      classes, dominantSpeaker,
      layout, layoutMode, localFeeds,
      pinnedFeed, pinnedTrackId
    } = this.props;
    const { layouts, galleryCountInRow } = this.state;

    const localFeed = localFeeds[0];
    const localStreams = layout.filter((stream) => stream.feed.sid === localFeed?.sid);
    const remoteStreams = layout.filter((stream) => stream.feed.sid !== localFeed?.sid);

    const getClassName = (el) => {
      return cn(
        'user-card',
        { 'user-card_active': get(el, 'feed.sid') === get(dominantSpeaker, 'sid') },
        { 'user-card_local': !el.remote }
      );
    };

    if (!layout.length) return null;

    const pinnedStream = layout.find((stream) =>
      (stream.feed.sid === pinnedFeed?.sid) &&
      (pinnedTrackId ? stream.videoTrackId === pinnedTrackId : !stream.videoTrackId)
    );

    // TODO: Need to update it with dominant speaker
    let mainStream = remoteStreams.length > 0 ? remoteStreams[0] : localStreams[0];
    if (pinnedStream) {
      mainStream = pinnedStream;
    }
    const nonMainStreams = layout.filter((stream) => stream.i !== mainStream?.i);

    let additionalInGallery = layout.length >= 12 ? 0 : (MAXIMUM_COUNT_IN_ROW - (layout.length % galleryCountInRow));
    if (additionalInGallery === MAXIMUM_COUNT_IN_ROW) {
      additionalInGallery = 0;
    }

    const numberOfRowsInGallery = Math.ceil(Math.min(layout.length, 12) / galleryCountInRow);
    const moreInGallery = layout.length - 12;
    return (
      <SizeMe monitorHeight>
        {({ size }) => layoutMode === 'sidebar' ? (
          <div className={classes.sidebarWrapper}>
            {mainStream && (
              <div className={classes.remoteFeedsContainer}>
                <div className={classes.remoteFeed}>
                  {this.renderStream(mainStream, !pinnedStream)}
                </div>
              </div>
            )}
            {nonMainStreams.length > 0 && (
              <div className={classes.localFeedsContainer}>
                {
                  nonMainStreams.map((el, index) => (
                    <div className={classes.localFeed} key={index}>
                      {this.renderStream(el, false)}
                    </div>
                  ))
                }
              </div>
            )}
          </div>
        ) : (
          layoutMode === 'gallery' ? (
            <div id='gallery' className={classes.galleryWrapper}>
              {Array.from({ length: numberOfRowsInGallery }).map((_, row) => {
                return Array.from({ length: galleryCountInRow }).map((_, col) => {
                  const index = row * galleryCountInRow + col;
                  const el = layout[index];

                  if (index === 11 && moreInGallery > 0) {
                    return (
                      <div className={cn(classes.galleryItem, classes.moreItem)} key={index} style={{ width: `${100 / galleryCountInRow}%` }}>
                        <div className={classes.moreContent}>
                          <p className={classes.moreItemsText}>+{moreInGallery + 1}</p>
                        </div>
                      </div>
                    );
                  }
                  return (
                    <div className={classes.galleryItem} key={index} style={{ width: `${100 / galleryCountInRow}%` }}>
                      {el && this.renderStream(el, false)}
                    </div>
                  );
                });
              })}
            </div>
          ) : (
            <ReactGridLayout
              width={size.width}
              {...this.gridLayoutProps(size)}
              layout={layouts}
              onLayoutChange={(layouts) => this.setState({ layouts })}
            >
              {lMap(layout, (el, i) => (
                <div
                  className={cn(classes.layoutWrapper, getClassName(el))}
                  key={i}
                  style={{ zIndex: el.index }}
                  data-grid={el}
                >
                  {this.renderStream(el, false)}
                </div>
              ))}
            </ReactGridLayout>
          )
        )}
      </SizeMe>
    );
  }
}

export default ConferenceDesktop;
