/* primary palette */
const white = '#FFF';
const black = '#4C575D';

const gray = '#FBFBFB';
const disabled_gray = '#CCCED0';
const dark_gray = '#F3F3F3';
const darker_gray = '#B1B1B1';
const darkest_gray = '#848A8D';

const blue = '#49A6FF';
const lightest_blue = '#EAF4FF';
const dark_blue = '#056fb5';
const darkest_blue = '#265C7F';
const dark_blue_black = '#003558';

/* accents */
const accent_black = '#000';
const accent_red = '#D16764';
const accent_bright_red = '#ff0000';
const accent_orange = '#EE8D41';
const accent_dark_yellow = '#C3B750';
const accent_light_yellow = '#FEFFC3';
const accent_green = '#1EBFA2';
const accent_turqoise = '#09CCE2';
const accent_blue = '#C7F5E6';

const online = '#4DEB98';
const offline = '#EB4F4F';

const theme = {
  primary: {
    white,
    black,

    gray,
    disabled_gray,
    dark_gray,
    darker_gray,
    darkest_gray,

    blue,
    lightest_blue,
    dark_blue,
    darkest_blue,
    dark_blue_black,
  },
  accents: {
    accent_black,
    accent_red,
    accent_bright_red,
    accent_orange,
    accent_dark_yellow,
    accent_light_yellow,
    accent_green,
    accent_turqoise,
    accent_blue,

    online,
    offline,
    idle: disabled_gray
  }
};

export default theme;
