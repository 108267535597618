const useStyles = ({ spacing, palette: { common, secondary, red } }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    marginTop: spacing(8),
    marginBottom: spacing(2),
  },
  footer: {
    padding: spacing(2),
    backgroundColor: secondary.darker_gray,
  },
  appBar: {},
  menuButton: {
    marginRight: spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    height: '100%',
    flexGrow: 1,
    flexDirection: 'column',
    overflow: 'auto'
  },
  openMenu: {
    margin: '0 150px 0 0'
  },
  count: {
    backgroundColor: red.main,
    borderRadius: '1rem',
    color: common.white,
    fontSize: '13px',
    display: 'flex',
    height: '20px',
    justifyContent: 'center',
    position: 'absolute',
    right: '14px',
    top: '17px',
    width: '20px'
  },
  icon: {
    height: '36px',
    width: '36px'
  }
});

export default useStyles;
