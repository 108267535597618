import { CLOSE_SNACKBAR, ENQUEUE_SNACKBAR, REMOVE_SNACKBAR } from './actionTypes';

export const enqueueSnackbar = (message, options = {
  key: new Date().getTime() + Math.random(),
  preventDuplicate: true
}) => {
  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      key: options.key,
      message,
      options,
    },
  };
};

export const closeSnackbar = (key) => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const removeSnackbar = (key) => ({
  type: REMOVE_SNACKBAR,
  key,
});
