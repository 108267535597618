import { connect } from 'react-redux';
import MediasList from './MediasList';
import { tokenSelector, mediaListSelector } from '../../selectors';
import { toggleMedia } from '../../actions';

const mapStateToProps = (state) => ({
  mediaList: mediaListSelector(state),
  token: tokenSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  onMediaToggle: (id, checked) => dispatch(toggleMedia(id, checked))
});

export default connect(mapStateToProps, mapDispatchToProps)(MediasList);
