import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import history from './history';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware({
  // sagaMonitor: createSagaMonitor(config)
});
const middlewares = [
  sagaMiddleware,
  thunkMiddleware,
  routerMiddleware(history),
];

export const store = createStore(
  rootReducer(history),
  compose(
    applyMiddleware(...middlewares),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
  ),
);

sagaMiddleware.run(rootSaga);
