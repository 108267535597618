import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from 'react-select';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import theme from './PatientAssignment.module.css';

const selectColourStyles = {
  control: (styles) => ({ ...styles, border: '1px solid #E4E4E4', borderRadius: '5px' }),
};

class PatientAssignment extends Component {
  static propTypes = {
    selectedPatient: PropTypes.object,
    patientsOptions: PropTypes.array.isRequired,
    onPatientChange: PropTypes.func.isRequired,
    onMediaSubmit: PropTypes.func.isRequired,
    selectedMedia: PropTypes.array
  };

  get patientsOptions() {
    const { patientsOptions } = this.props;

    return patientsOptions.map(({ key, label }) => ({ value: key, label }));
  }

  handleChangePatient = (value) => {
    const { onPatientChange } = this.props;

    onPatientChange(value);
  };

  renderButton = () => {
    const { selectedPatient, onMediaSubmit, selectedMedia } = this.props;

    return !isEmpty(selectedPatient) && (
      <Button
        className={theme.submitButton}
        variant="contained"
        color="primary"
        onClick={onMediaSubmit}
        disabled={isEmpty(selectedMedia)}
      >
        Submit
      </Button>
    );
  };

  renderPlaceHolder = () => <div>Start typing to select... </div>;

  render() {
    const {
      patientsOptions
    } = this.props;

    return (
      <div className={theme.container}>
        <div className={theme.containerForm}>
          <FormControl className={theme.formControl}>
            <InputLabel className={theme.formLabel}>Patient</InputLabel>
            <Select
              placeholder={this.renderPlaceHolder()}
              onChange={this.handleChangePatient}
              isDisabled={isEmpty(patientsOptions)}
              options={this.patientsOptions}
              isSearchable
              className="basic-single"
              classNamePrefix="select"
              styles={selectColourStyles}
            />
          </FormControl>
        </div>

        <div className={theme.buttonContainer}>{this.renderButton()}</div>
      </div>
    );
  }
}

export default PatientAssignment;
