import React, { Component } from 'react';

import Avatar from '../Avatar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import ReactPlayer from 'react-player';
import cn from 'classnames';
import theme from './MobileActiveFeed.module.css';
import { trackPubsToTracks } from 'utils/utils';

class MobileActiveFeed extends Component {
  state = {
    videoTracks: [],
    avatarSize: 0,
    fontSize: 0,
  };

  handleScreenShot = () => {
    const { ctx, props, video } = this;

    if (!video) {
      return null;
    }
    if (!ctx) {
      const canvas = document.createElement('canvas');
      const aspectRatio = video.videoWidth / video.videoHeight;

      let canvasWidth = props.minScreenshotWidth || video.clientWidth;

      let canvasHeight = canvasWidth / aspectRatio;

      if (
        props.minScreenshotHeight
        && canvasHeight < props.minScreenshotHeight
      ) {
        canvasHeight = props.minScreenshotHeight;
        canvasWidth = canvasHeight * aspectRatio;
      }
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      this.canvas = canvas;
      this.ctx = canvas.getContext('2d');
    }
    this.ctx.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);
    const dataUrl = this.canvas.toDataURL();

    this.ctx.canvas.toBlob((blob) => {
      this.props.addNewImage({
        roomId: this.props.roomId,
        title: `image-${new Date().getTime()}`,
        dataUrl,
        status: 'loading',
        blob,
      });
    });

    return this.canvas;
  };

  ref = (player) => {
    if (player) {
      this.player = player;
      this.video = player.getInternalPlayer();
      this.handleResize();
    }
  };

  onReady = (player) => {
    const video = player.getInternalPlayer();

    if (video) {
      this.video = video;
    }
  };

  componentDidMount() {
    const { activeFeed } = this.props;

    if (activeFeed) {
      this.setState({
        videoTracks: trackPubsToTracks(activeFeed.videoTracks),
      });

      activeFeed.on('trackSubscribed', this.trackSubscribed);
      activeFeed.on('trackUnsubscribed', this.trackUnsubscribed);
    }

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    const { activeFeed } = this.props;

    if (activeFeed) {
      activeFeed.off('trackSubscribed', this.trackSubscribed);
      activeFeed.off('trackUnsubscribed', this.trackUnsubscribed);
    }

    this.setState({ videoTracks: [] });
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps, prevState) {
    const { videoTracks } = this.state;

    if (this.props.activeFeed !== prevProps.activeFeed) {
      if (prevProps.activeFeed) {
        prevProps.activeFeed.off('trackSubscribed', this.trackSubscribed);
        prevProps.activeFeed.off('trackUnsubscribed', this.trackUnsubscribed);
        if (videoTracks[0]) {
          videoTracks[0].detach(this.video);
        }
      }
      if (this.props.activeFeed) {
        this.setState({
          videoTracks: trackPubsToTracks(this.props.activeFeed.videoTracks),
        });
        this.props.activeFeed.on('trackSubscribed', this.trackSubscribed);
        this.props.activeFeed.on('trackUnsubscribed', this.trackUnsubscribed);
      } else {
        this.setState({
          videoTracks: []
        });
      }
    } else if (videoTracks !== prevState.videoTracks || this.props.pinnedTrackId !== prevProps.pinnedTrackId) {
      let videoTrack = videoTracks[0];
      if (this.props.pinnedTrackId) {
        videoTrack = videoTracks.find((t) => t.id === this.props.pinnedTrackId || t.sid === this.props.pinnedTrackId);
      }

      if (videoTrack) {
        videoTrack.attach(this.video);
      }
    }
  }

  handleResize = () => {
    if (this.video) {
      const approxWidth = Math.ceil(this.video.clientWidth / 3);
      const approxHeight = Math.ceil(this.video.clientHeight / 2);
      const estimatedSize = approxWidth < approxHeight ? approxWidth : approxHeight;
      const fontSize = Math.max(Math.ceil(estimatedSize / 5), 15);

      this.setState({
        avatarSize: estimatedSize,
        fontSize: Math.min(fontSize, 50),
      });
    }
  }

  trackSubscribed = (track) => {
    const { videoTracks } = this.state;

    if (track.kind === 'video') {
      this.setState({ videoTracks: [...videoTracks, track] });
    }
  };

  trackUnsubscribed = (track) => {
    const { videoTracks } = this.state;

    if (track.kind === 'video') {
      this.setState({ videoTracks: videoTracks.filter((v) => v !== track) });
    }
  };

  render() {
    const { activeFeed, fullscreen, isPatient } = this.props;
    const { avatarSize, fontSize, videoTracks } = this.state;

    return activeFeed ? (
      <div className={theme.activeFeed} key={activeFeed.i}>
        <Card className={theme.card}>
          <CardContent style={{ padding: 0 }}>
            <ReactPlayer
              ref={this.ref}
              className={cn(theme.video, {
                [theme.videoFullScreen]: fullscreen,
              })}
              style={{ width: '100% !important', height: '100% !important' }}
              onReady={this.onReady}
              url={[]}
              controls={false}
              playsinline
              autoPlay
              playing
            />
            <CardActions style={{ padding: 0, zIndex: 1000 }} className={theme.cardActions}>
            {!isPatient && (
              <IconButton
                className={theme.button}
                // onClick={this.handleScreenShot}
                onTouchEnd={this.handleScreenShot}
                aria-label="Make photo from camera"
                size="medium"
              >
                <PhotoCameraIcon />
              </IconButton>
            )}
            </CardActions>
            {videoTracks.length === 0 && (
              <div className={theme.accountAvatar} style={{ width: avatarSize, height: avatarSize }}>
                <Avatar accountId={activeFeed.identity} fontSize={fontSize} />
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    ) : null;
  }
}

export default MobileActiveFeed;
