import { isHostSelector, mutedStreamsSelector, vcInfoSelector, videoMutedStreamsSelector } from '../../../../selectors/videoRoom';
import { muteParticipantIoT, removeParticipantIoT, updateHostIoT } from 'modules/chat/actions';
import { toggleMic, toggleVideo } from 'modules/conference/actions';

import ParticipantItem from './ParticipantItem';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { currentUserSelector } from 'modules/login/selectors';
import { useStyles } from './ParticipantItem-styles';
import { withStyles } from '@material-ui/core';

const mapStateToProps = (state) => ({
  vcInfo: vcInfoSelector(state),
  mutedStreams: mutedStreamsSelector(state),
  videoMutedStreams: videoMutedStreamsSelector(state),
  isHost: isHostSelector(state),
  userInfo: currentUserSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  removeParticipant: (participantId) => dispatch(removeParticipantIoT(participantId)),
  updateHost: (hostId) => dispatch(updateHostIoT(hostId)),
  muteParticipant: (participantId) => dispatch(muteParticipantIoT(participantId)),
  toggleMic: () => dispatch(toggleMic()),
  toggleVideo: () => dispatch(toggleVideo()),
});

export default compose(
  withStyles(useStyles),
  connect(mapStateToProps, mapDispatchToProps)
)(ParticipantItem);
