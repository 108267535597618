import { drawerWidth } from '../LeftSideBar/LeftSideBar-styles';

export const useStyles = ({ palette: { common } }) => ({
  root: {
    display: 'flex',
    height: '100%',
    minHeight: '100%',
  },
  noSupportWrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'white',
    zIndex: 99
  },
  noSupport: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    width: '90%',
    maxWidth: 330,
    height: 240,
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white',
    border: '1px solid #60A4FE',
    borderRadius: 5,
    zIndex: 99,
  },
  noSupportMessage: {
    color: '#4E575C',
    fontSize: 20,
    lineHeight: '25px',
    textAlign: 'center',
    width: 295,
    marginBottom: 32
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
  },
  withSider: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  contentWrapper: {
    width: '100%',
    padding: '0 20px',
    margin: '0 auto',
    height: 'calc(100% - 70px)',
    overflow: 'auto',
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
  },
  contentWrapperLandscape: {
    height: '100%'
  },
  layout: {
    flex: 1,
    width: '100%',
    position: 'relative',
    height: '100%',
  },
  /* Wrapper class of all stream items */
  layoutWrapper: {
    // minWidth: "350px",
    // minHeight: "350px"
  },
  sidebarWrapper: {
    display: 'flex',
    height: '100%',
    flex: 1,
  },
  galleryWrapper: {
    display: 'flex',
    height: '100%',
    width: 'calc(100% + 40px)',
    margin: '0 -20px',
    alignContent: 'flex-start',
    flexWrap: 'wrap',
    overflowY: 'auto',
  },
  galleryItem: {
    height: '33.33%',
    minWidth: 300,
    minHeight: 150,
    padding: 12,
  },
  greyBackdrop: {
    backgroundColor: '#5F6368'
  },
  moreContent: {
    background: '#4C575D',
    borderRadius: 8,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  moreItemsText: {
    marginBottom: 0,
    fontSize: 32,
    lineHeight: '16px',
    padding: '24px 32px',
    background: 'black',
    borderRadius: 13,
    color: 'white',
  },
  remoteFeedsContainer: {
    flex: 1,
    height: '100%',
    padding: '10px 0',
    boxSizing: 'border-box',
  },
  localFeedsContainer: {
    marginLeft: '28px',
    padding: '20px 0',
  },
  noRemoteFeeds: {
    marginLeft: 0,
    flex: 1,
    height: '100%',
    padding: '10px 0',
    boxSizing: 'border-box',
  },
  localFeed: {
    width: '211px',
    height: '176px',
    margin: '4px',
  },
  noRemoteFeed: {
    width: '100%',
    height: '100%',
    padding: '10px 0',
    boxSizing: 'border-box',
  },
  remoteFeed: {
    width: '100%',
    height: '100%',
    padding: '10px 0',
    boxSizing: 'border-box',

    '& video': {
      objectFit: 'contain'
    }
  },
  footer: {
    flex: 0,
    position: 'relative',
  },
  chatButton: {
    marginLeft: '10px',
    minHeight: '36px',
  },
  chatIcon: {
    marginLeft: '8px',
    fill: common.white,
  },
  toolbar: {
    justifyContent: 'space-between'
  },
  toolbarWrapper: {
    flex: 1,
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  usersList: {
    height: '40%',
    padding: '10px 20px',
    overflowY: 'auto',
    display: 'none'
  },
  usersListWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  usersListBox: {
    display: 'flex',
    alignItems: 'center',
  },
  usersListItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    marginBottom: 10.5,
    paddingBottom: 10.45,
    borderBottom: '1px solid #CCCED0'
  },
  usersListImage: {
    marginRight: 20,
    width: 75,
    minWidth: 75,
    height: 56.05,
    backgroundColor: '#9B9B9B'
  },
  usersListText: {
    fontSize: 16,
    lineHeight: '20px',
    color: '#4E575C',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  mobileBar: {
    display: 'none',
    position: 'fixed',
    top: '0',
    left: '0',
    height: '100vh',
    width: '100vw',
    backgroundColor: 'rgba(0, 53, 88, 0.8)',
    zIndex: '9999'
  },
  mobileBarWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  mobileBarButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(243, 243, 243, 1)',
    height: 30,
    padding: '0 20px',
    color: 'rgba(96, 164, 254, 1)',
    fontSize: 12,
    textTransform: 'uppercase',
    letterSpacing: '1.2px'
  },
  mobileBarBox: {
    backgroundColor: '#ffffff',
    minHeight: 194,
    width: '100%',
    padding: '22px 20px 29px',
  },
  mobileBarSwitcher: {
    color: '#4E575C',
    fontSize: 20,
    lineHeight: '25px',
    margin: '0 0 20px 0',

    '& svg': {
      marginRight: 20,
    }
  },
  layoutMobile: {
    flex: '1 1 auto',
    background: '#fff !important',
    overflow: 'auto'
  },
  drawerHeader: {
    padding: '8px 20px',
    background: '#F3F3F3',
    display: 'flex',
    justifyContent: 'space-between',
    color: '#60A4FE',
    '& p': {
      fontSize: 12,
      marginBottom: 0
    },
    '& svg': {
      cursor: 'pointer'
    },
    marginBottom: 10
  },
  drawerItemsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  drawerItem: {
    padding: '10px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 20,
    color: '#4E575C',
    position: 'relative',
    '& button': {
      flex: 1,
      padding: '0',
      color: '#B1B1B1',

      '& > .MuiButton-label': {
        display: 'flex',
        flexDirection: 'column',

        '& > svg': {
          width: '1.25em',
          height: '1.25em',
        }
      }
    },
  },
  moreContainer: {
    position: 'relative',
  },
  badge: {
    position: 'absolute',
    top: 0,
    right: 20,
    background: '#49A6FF',
    fontSize: 10,
    borderRadius: '50%',
    minWidth: 15,
    textAlign: 'center',
    paddingLeft: 5,
    paddingRight: 5,
    height: 15,
    color: 'white',
  },
  moreBadge: {
    right: 4,
  },
  videoContent: {
    display: 'flex',
    flex: 1,
    position: 'relative',
    height: '100%',
  },
  [`@media (max-width: 930px) and (orientation: landscape)`]: {
    floatPreview: {
      position: 'fixed !important',
      right: '20px !important',
      bottom: '90px !important',
      width: '150px !important',
      height: '112px !important'
    },
    withSider: {
      marginLeft: 0,
      width: `100%`
    },
    videoContent: {
      flex: 'inherit',
    },
  },
  [`@media (max-width: ${768}px)`]: {
    content: {
      width: '100%',
      marginLeft: 0
    },
    usersList: {
      display: 'block'
    },
    contentWrapper: {
      maxWidth: '100%',
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      overflow: 'auto'
    },
    layoutWrapper: {
      zIndex: '0 !important',
      position: 'static !important',
      width: '100% !important',
      height: 'auto !important',
    },
    floatPreview: {
      position: 'fixed !important',
      right: '20px !important',
      bottom: '90px !important',
      width: '150px !important',
      height: '112px !important'
    },
    activeFeed: {
      display: 'flex'
    },
    noneForMobile: {
      display: 'none'
    },
    video: {
      maxWidth: '100%',
      display: 'flex',
      width: '100%!important',
      height: '100%!important',

      '& video': {
        width: '100%!important',
        height: 'auto!important',
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'cover',
      },
    },
  },
});
