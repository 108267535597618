export const useStyles = ({ palette: { common } }) => ({
  hostInfo: {
    fontSize: 24,
    lineHeight: '30px',
    color: '#000000',
    marginTop: 36,
  },
  hostName: {
    fontWeight: 700
  },
  appointmentInfo: {
    fontSize: 16,
    fontWeight: 400,
    marginTop: '-15px',
  },
  [`@media (max-width: ${768}px)`]: {
    hostInfo: {
      fontSize: 18,
      lineHeight: '22px'
    },
    appointmentInfo: {
      marginTop: 0,
    },
    participantTitle: {
      textAlign: 'left'
    }
  }
});
