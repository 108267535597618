import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import clsx from 'clsx';
import EssentialTooltip from 'components/shared/EssentialTooltip';
import React, { Component } from 'react';

class LeftSideBarItem extends Component {
  static propTypes = {};

  state = {
    anchorEl: null
  }

  onClick = (event) => {
    event.stopPropagation();

    const { children, id, text, evData } = this.props;
    const { anchorEl } = this.state;

    if (children && children.length) {
      this.setState({ anchorEl: anchorEl ? null : event.currentTarget });
    } else {
      this.props.onClick({ id, text, evData });
    }
  }

  get showChatCounter() {
    const { id, unreadMessages } = this.props;

    return id === 'chat' && unreadMessages > 0;
  }

  onClickSubItem = (item) => {
    const { onClick } = this.props;

    onClick(item);

    this.setState({ anchorEl: null });
  }

  handleClickAway = () => {
    this.setState({
      anchorEl: null
    });
  };

  render() {
    const {
      text, icon, className, classes, isActive, isDisabled, isRecord, id, unreadMessages, children, participants, layoutMode
    } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const poperId = open ? `simple-popper-${id}` : undefined;
    const itemClass = clsx(classes.listItem, className, {
      [classes.isActive]: isActive,
      [classes.isDisabled]: isDisabled,
      [classes.isRecord]: isRecord && id === 'recording'
    });

    return (
      <ListItem disabled={isDisabled} onClick={this.onClick} button className={itemClass}>
        {icon && (
          <ListItemIcon className={classes.listItemIcon}>
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon icon={icon} size="1x" />
              {id === 'chat' && this.showChatCounter && (
                <span className={`fa-layers-counter ${classes.counter}`}>{unreadMessages}</span>
              )}
              {id === 'participants' && participants.length > 0 && (
                <span className={`fa-layers-counter ${classes.counter}`}>{participants.length}</span>
              )}
            </span>
          </ListItemIcon>
        )}
        <ListItemText classes={{
          root: clsx(classes.listItemText, {[classes.listItemTextActive]: id === layoutMode}),
          primary: classes.listItemTextPrimary,
        }}
          primary={text}
          aria-describedby={poperId}
        />
        {children && (
          <Popper
            id={poperId}
            open={open}
            anchorEl={anchorEl}
            placement="top-end"
            transition
            modifiers={{
              arrow: {
                enabled: true,
              },
            }}
          >
            {({ TransitionProps }) => (
              <ClickAwayListener onClickAway={this.handleClickAway}>
                <Fade {...TransitionProps}>
                  <Paper>
                    <List classes={{
                      root: classes.list,
                      padding: classes.listPadding,
                      dense: classes.listDense,
                      subheader: classes.listSubHeader
                    }}
                    >
                      {children.map((item) => (
                        <EssentialTooltip
                          key={item.id}
                          enabled={item.noPermission === true}
                          content={item.messageForEssential}
                        >
                          <LeftSideBarItem
                            id={item.id}
                            classes={classes}
                            className={classes.secondLevel}
                            onClick={(data) => this.onClickSubItem(data)}
                            layoutMode={layoutMode}
                            {...item}
                          />
                        </EssentialTooltip>
                      ))}
                    </List>
                  </Paper>
                </Fade>
              </ClickAwayListener>
            )}
          </Popper>
        )}
      </ListItem>
    );
  }
}

export default LeftSideBarItem;
