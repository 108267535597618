import { find, get } from 'lodash';

export const imageSelector = ({ imageCapture: state }) => get(state, 'images', []);
export const tokenSelector = ({ token: state }) => get(state, 'token', '');
export const defaultVideoDeviceSelector = (state) => get(state, 'conference.videoDevices.0');
export const dataFeedSelector = (state) => {
  const feeds = get(state, 'conference.feeds');

  return find(feeds,
    (feed) => {
      const data = get(feed, 'offer.media.data');

      return !feed.remote && data;
    });
};

export const accountIdSelector = ({ login: state }) => get(state, 'userInfo.id', null);
