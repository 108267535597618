import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import ReactPlayer from 'react-player';

import { useStyles } from './ImageList-styles';

export default function ImageList(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h4">Media</Typography>
      <GridList cols={1} className={classes.gridList}>
        {props.images.map((tile, i) => {
          const url = tile.url ? `${tile.url}?authToken=${props.token}` : tile.dataUrl;
          const isVideo = tile.mime_type === 'video/webm';
          const isImage = tile.mime_type === 'image/png' || !!tile.dataUrl;

          return (
            <GridListTile
              key={i}
              cols={tile.cols || 1}
            >
              {isImage && <img src={url} alt={tile.file_name} />}
              {isVideo && (
              <ReactPlayer
                className="react-player"
                url={[{ src: url, type: 'video/webm' }]}
                controls
                width="100%"
                height="100%"
              />
              )}
            </GridListTile>
          );
        })}
      </GridList>
    </div>
  );
}
