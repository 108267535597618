import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import theme from './Loading.module.css';

class Loading extends Component {
  render() {
    const { text } = this.props;

    return (
      <div className={theme.container}>
        <CircularProgress disableShrink />
        {text && (<span className={theme.text}>{text}</span>)}
      </div>
    );
  }
}

export default Loading;
