import React, { useMemo } from 'react'

import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import cn from 'classnames'

const WaitingToJoin = ({
  classes,
  waitingParticipants,
  declineParticipant,
  acceptParticipant,
  acceptingParticipants,
  vcInfo
}) => {
  const participants = useMemo(() => {
    return vcInfo.participants.filter(participant => waitingParticipants.includes(participant.id))
  }, [vcInfo, waitingParticipants]);

  const onAccept = (participant) => () => {
    acceptParticipant(participant);
  }

  const onDecline = (participantId) => () => {
    declineParticipant(participantId);
  }

  const onAcceptAll = () => {
    participants.forEach(participant => {
      acceptParticipant(participant);
    });
  }

  const onDeclineAll = () => {
    participants.forEach(participant => {
      declineParticipant(participant.id);
    });
  }

  const renderHeader = () => (
    <div className={classes.header}>
      <div className={classes.title}>WAITING TO JOIN</div>
      <div className={classes.actionContainer}>
        <div className={classes.actionItemContainer} onClick={onAcceptAll}>
          <a className={cn(classes.action, classes.accept)} href>Accept All</a>
        </div>
        <div className={classes.actionItemContainer} onClick={onDeclineAll}>
          <a className={cn(classes.action, classes.decline)} href>Decline All</a>
        </div>
      </div>
    </div>
  )

  return (
    <div>
      {renderHeader()}
      {participants.map((participant, index) => {
        const name = `${participant.first_name} ${participant.last_name}`;
        const isAccepting = acceptingParticipants.includes(participant.id);

        return (
          <div className={classes.participantItem} key={index}>
            <p className={classes.participantName}>{name}</p>
            <div className={classes.actionContainer}>
              {isAccepting ? (
                <CircularProgress size={18} disableShrink />
              ) : (
                <>
                  <Button className={classes.acceptButton} onClick={onAccept(participant)}>
                    Accept
                  </Button>
                  <div className={classes.actionItemContainer}>
                    <a className={cn(classes.action, classes.decline)} href onClick={onDecline(participant.id)}>Decline</a>
                  </div>
                </>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
};

export default WaitingToJoin
