import CircularProgress from '@material-ui/core/CircularProgress';
import Button from 'components/shared/Button';
import * as React from 'react';
import Select from 'react-select';

import * as Modal from './Modal';
import styles from './styles.module.scss';

const AddParticipantModal = ({
  accessibleProviders: {
    count,
    fetching,
    providerOptions
  },
  addParticipant,
  close,
  getAccessibleProviders,
  isOpen
}) =>{
  const [selectedParticipant, setSelectedParticipant] = React.useState(null)
  const clickAdd = () => {
    if (selectedParticipant) {
      addParticipant(selectedParticipant.value)
      close()
    }
  }

  const renderDropdown = () => (
    <Select
      onChange={setSelectedParticipant}
      options={providerOptions}
    />
  )
  
  React.useEffect(() => {
    // if count is 0 we haven't tried fetching already
    if (!fetching && isOpen && count === 0) {
      getAccessibleProviders()
    }
  }, [ count, fetching, isOpen, getAccessibleProviders ])

  const showLoading = fetching && count === 0

  return (
    <Modal.Wrapper backdrop close={close} isOpen={isOpen} size='sm'>
      <Modal.Header>
        <div className={styles.title}>Add Participant<span onClick={() => close()}>X</span></div>
      </Modal.Header>
      <Modal.Body className={showLoading ? styles.addParticipantModal : ''}>
        {showLoading ? <CircularProgress /> : renderDropdown()}
      </Modal.Body>
      <Modal.Footer>
        <Button className={styles.addParticipantBtn} disabled={!selectedParticipant} onClick={clickAdd} skinnyBtn>Add</Button>
      </Modal.Footer>
    </Modal.Wrapper>
  )
}

export default AddParticipantModal;
