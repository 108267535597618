import { put, takeLatest } from '@redux-saga/core/effects';
import { get } from 'lodash';
import { enqueueSnackbar } from '../notifier/actions';
// import { storeConfigs } from "./actions";
import { LOAD_CONFIG } from './actions/actionTypes';
// import APImethods2 from "../../api2";

function* loadConfig() {
  try {
    // Load Twilio Room Settings
    // const { data: { data: { roomSettings } } } = yield call([APImethods2, 'get'], '/videoConferences/roomSettings');
    // yield put(storeConfigs({ config: roomSettings }));
  } catch (e) {
    const { status, statusText } = get(e, 'response', {});
    const errorMessage = status && statusText ? `${status} ${statusText}` : e.toString();

    yield put(
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        preventDuplicate: true,
      })
    );
  }
}

export default function* serverConfigSaga() {
  yield takeLatest(LOAD_CONFIG, loadConfig);
}
