import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import React from 'react';
import { useStyles } from './CircleButtonWithText-styles';

export const CircleButtonWithText = ({ onClick, badge, icon, text, isActive, isDisabled, isExit }) => {
  const classes = useStyles();

  return (
    <IconButton
      color="secondary"
      variant="outlined"
      classes={{ root: cn(
        classes.button, 
        { [classes.buttonActive]: (isActive && !isDisabled) },
        { [classes.buttonExit]: isExit }
        )
      }}
      aria-haspopup="true"
      disabled={isDisabled}
      onClick={onClick}
    >
      <Badge classes={{
        root: classes.content,
        badge: classes.badge
      }}
        color="primary"
        badgeContent={badge}
      >
        <FontAwesomeIcon className={classes.icon} icon={icon} />
        <span className={classes.text}>{text}</span>
      </Badge>
    </IconButton>
  );
};

CircleButtonWithText.propTypes = {
  icon: PropTypes.object,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string
};

CircleButtonWithText.defaultProps = {
  text: ''
};
