import { createStyles, makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => createStyles({
  avatarContainer: {
    width: '100%',
    height: '100%',
    borderRadius: '100%',
    border: '2px solid white',
    boxSizing: 'border-box',
  },
  avatarInnerContainer: {
    width: '100%',
    height: '100%',
    borderRadius: '100%',
    border: '2px solid black',
    boxSizing: 'border-box',
    position: 'relative',
  },
  thumbnail: {
    background: 'transparent center center / 100% 100% no-repeat',
    width: '100%',
    height: '100%',
    borderRadius: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 100,
  },
  default: {
    background: '#555151',
    color: '#fff',
    fontWeight: 600,
    fontSize: 24,
    width: '100%',
    height: '100%',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
  }
}));
