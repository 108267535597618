export const useStyles = () => ({
  actionContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actionItem: {
    color: '#49A6FF',
  },
  actionItemContainer: {
    cursor: 'pointer',
    padding: 4,
    marginLeft: 10,
  },
  addParticipant: {
    color: '#49A6FF',
    cursor: 'pointer',
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 15
  },
  addParticipantIcon: {
    marginRight: 10
  },
  closeIcon: {
    fontSize: 22,
    fontWeight: 400,
    cursor: 'pointer',
  },
  container: {
    width: "340px",
    height: "100%",
    background: "white",
    boxShadow: "4px 0px 12px rgba(0, 0, 0, 0.12)",
    position: "relative",
    left: "-20px",
    padding: "40px 20px 20px 20px",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#4C575D",
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 18,
  },
  mobileContainer: {
    position: "fixed",
    top: "0px",
    left: "0px",
    width: "100%",
    zIndex: "999"
  },
  participantItem: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  participantName: {
    flex: 1,
    color: '#4C575D',
    fontSize: 16,
    marginBottom: 0,
  },
  sectionTitle: {
    color: '#056FB5',
    fontWeight: 600,
    fontSize: 12,
    letterSpacing: '0.08em',
    marginBottom: 0,
  },
  usersIcon: {
    fontSize: 16,
    fontWeight: 400,
  }
});
