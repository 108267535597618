export const drawerWidth = 125;

export const useStyles = () => ({
  drawerPaper: {
    width: drawerWidth,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: 'none',
    boxShadow: '0px 0px 15px #00000029',
    overflow: 'unset'
  },
  listLogo: {
    width: 156,
    height: 31
  },
  listPadding: {
    padding: '0 0 20px 0'
  },
  listDense: {},
  listSubHeader: {},
  [`@media (max-width: ${768}px)`]: {
    drawer: {
      display: 'none'
    }
  },
  logoContainer: {
    display: 'flex',
    marginTop: 50,
    justifyContent: 'center'
  }
});
