import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import SwitchMicOnOffButton from './SwitchMicOnOffButton';

const SwitchMicOnOffButtonContainer = ({ onClick, previewStream, audioTracks, isActive, setActive, enqueueSnackbar }) => {
  const [isDisabled, setDisabled] = useState(false);

  const setActiveCb = useCallback((active) => {
    setActive(active);
  }, [setActive]);

  const setActiveCallback = useCallback(() => {
    setActiveCb(true);
  }, [setActiveCb]);

  const setInactiveCallback = useCallback(() => {
    setActiveCb(false);
  }, [setActiveCb]);

  useEffect(() => {
    const audioTrack = audioTracks[0];

    if (audioTrack) {
      setActiveCb(!audioTrack.isEnabled);
      audioTrack.on('enabled', setInactiveCallback);
      audioTrack.on('disabled', setActiveCallback);

      return () => {
        audioTrack.off('enabled', setInactiveCallback);
        audioTrack.off('disabled', setActiveCallback);
      };
    } else if (previewStream) {
      if (!previewStream.getAudioTracks() || previewStream.getAudioTracks().length === 0) {
        setDisabled(true);
        setActiveCb(true);
      } else {
        setDisabled(false);
        const isMuted = !previewStream.getAudioTracks()[0].enabled;

        setActiveCb(isMuted);
      }
    } else {
      setDisabled(true);
      setActiveCb(true);
    }
  }, [
    audioTracks,
    previewStream,
    setActiveCallback,
    setInactiveCallback,
    setActiveCb
  ]);

  const handleClick = useCallback(() => {
    if (isDisabled) {
      enqueueSnackbar('No active devices found')
    } else {
      onClick();
    }
  }, [enqueueSnackbar, onClick, isDisabled]);

  return (
    <SwitchMicOnOffButton
      onClick={handleClick}
      isActive={isActive}
    />
  );
};

export default SwitchMicOnOffButtonContainer;

SwitchMicOnOffButtonContainer.propTypes = {
  previewStream: PropTypes.any,
  audioTracks: PropTypes.array,
  onClick: PropTypes.func,
  enqueueSnackbar: PropTypes.func
};

SwitchMicOnOffButtonContainer.defaultProps = {
  onClick: () => {},
};
