import { faUserPlus, faUsers } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ParticipantItem from './ParticipantItem'
import React from 'react'
import WaitingItem from './WaitingItem'
import WaitingToJoin from '../WaitingToJoin/'
import closeIcon from '../../../../../assets/close.svg'
import cn from 'classnames';
import { trackPubsToTracks } from 'utils/utils'

const Participants = ({
  audioTrackStats,
  classes,
  isHost,
  isMobile,
  onClose,
  openAddParticipantModal,
  participants,
  waitingParticipants,
  onWaitingRoomParticipants,
  vcInfo
}) => {
  const nonHostParticipants = React.useMemo(() => {
    return participants.filter(participant => vcInfo?.host_id !== participant.identity);
  }, [participants, vcInfo])
  const hostParticipants = React.useMemo(() => {
    return participants.filter(participant => vcInfo?.host_id === participant.identity);
  }, [participants, vcInfo])
  const onWaitingRoomParticipantsDetails = React.useMemo(() => {
    return (vcInfo?.participants || []).filter(participant => onWaitingRoomParticipants.includes(participant.id));
  }, [onWaitingRoomParticipants, vcInfo])

  const isTalking = (participantFeed) => {
    const stat = audioTrackStats.filter((stat) => trackPubsToTracks(participantFeed.audioTracks).some((t) => t.sid === stat.trackSid || t.id === stat.trackId));
    // Set audiolevel threshold (500)
    return stat.length > 0 && stat[0].audioLevel > 500;
  };

  return (
    <div className={cn(classes.container, { [classes.mobileContainer]: isMobile })}>
      <div className={classes.headerContainer}>
        <span>
          <FontAwesomeIcon className={classes.usersIcon} icon={faUsers} size="1x" />
          &nbsp;Participants
        </span>
        <img className={classes.closeIcon} src={closeIcon} alt='close' onClick={onClose} />
      </div>
      {
        isHost &&
        <div className={classes.addParticipant}>
          <span onClick={openAddParticipantModal}>
            <FontAwesomeIcon className={classes.addParticipantIcon} icon={faUserPlus} size="1x" />
            Add Participant
          </span>
        </div>
      }
      {hostParticipants.length > 0 && (
        <>
          <p className={classes.sectionTitle}>HOST</p>
          {hostParticipants.map((participantFeed, index) => (
            <ParticipantItem
              isTalking={isTalking(participantFeed)}
              key={index}
              participantFeed={participantFeed}
            ></ParticipantItem>
          ))}
          <hr />
        </>
      )}
      {nonHostParticipants.length > 0 && (
        <>
          <p className={classes.sectionTitle}>IN THE CALL</p>
          {nonHostParticipants.map((participantFeed, index) => (
            <ParticipantItem
              isTalking={isTalking(participantFeed)}
              key={index}
              participantFeed={participantFeed}
            ></ParticipantItem>
          ))}
          <hr />
        </>
      )}
      {waitingParticipants.length > 0 && isHost && (
        <>
          <WaitingToJoin />
          <hr />
        </>
      )}
      <p className={classes.sectionTitle}>SETTING UP AUDIO/VIDEO</p>
      {onWaitingRoomParticipantsDetails.map((participant, index) => (
        <WaitingItem
          key={index}
          participant={participant}
        ></WaitingItem>
      ))}
    </div>
  )
}

export default Participants
