import { deleteMedia } from 'modules/imageCapture/actions';
import { currentUserSelector } from 'modules/login/selectors';
import { mediaListSelector, tokenSelector } from 'modules/pastConference/selectors';
import { connect } from 'react-redux';

import { isHostSelector } from '../../selectors/videoRoom';
import CapturedImages from './CapturedImages';

const mapStateToProps = (state) => ({
  isHost: isHostSelector(state),
  mediaList: mediaListSelector(state),
  token: tokenSelector(state),
  userInfo: currentUserSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  deleteMedia: (id) => dispatch(deleteMedia(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CapturedImages);
