import * as React from 'react';

import Button from '../Button';
import * as Modal from './Modal';
import styles from './styles.module.scss';

const RecordingNotificationModal = ({ close, isOpen }) => {
  return (
        <Modal.Wrapper
            backdrop={false}
            close={close}
            keyboard
            isOpen={isOpen}
            size="sm"
        >
            <Modal.Body>
                <div className={styles.recordingNotificationModal}>
                    This video conference is being recorded.
                    <div className={styles.recordingCloseBtn}>
                        <Button skinnyBtn onClick={close}>Okay</Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal.Wrapper>
  );
};

export default RecordingNotificationModal;
