import * as s from '../../selectors/videoRoom';

import { consentPolicySelector, currentUserSelector, isPatientInactiveSelector } from '../../../login/selectors';
import { joinTwilioRoom, setDevices, setPreviewStream } from '../../actions';
import { leaveWaitingRoomIoT, onWaitingRoomIoT, requestJoinIoT } from 'modules/chat/actions';

import WaitingRoom from './WaitingRoom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { enqueueSnackbar } from '../../../notifier/actions';
import { useStyles } from './WaitingRoom-styles';
import { withStyles } from '@material-ui/core';

const mapStateToProps = (state) => ({
  consentPolicy: consentPolicySelector(state),
  isAuthorized: s.isAuthorized(state),
  isPatientInactive: isPatientInactiveSelector(state),
  isParticipant: s.isParticipant(state),
  isTwilioAuthorized: s.isTwilioAuthorized(state),
  preview: s.previewSelector(state),
  userInfo: currentUserSelector(state),
  vcInfo: s.vcInfoSelector(state),
  vcInfoError: s.vcInfoErrorSelector(state),
  videoDevices: s.videoDevicesSelector(state),
  twilioToken: s.twilioTokenSelector(state),
  isIotConnected: s.isIotConnectedSelector(state),
  isJoining: s.isJoiningSelector(state),
  isDeclined: s.isDeclinedSelector(state),
  isRemoved: s.isRemovedSelector(state),
  previewStream: s.previewStreamSelector(state),
  appointment: s.appointmentInfoSelector(state),
});
const mapDispatchToProps = (dispatch) => ({
  enqueueSnackbar: (message, options) => dispatch(enqueueSnackbar(message, options)),
  joinTwilioRoom: () => dispatch(joinTwilioRoom()),
  onWaitingRoom: () => dispatch(onWaitingRoomIoT()),
  setPreviewStream: (stream) => dispatch(setPreviewStream(stream)),
  setDevices: () => dispatch(setDevices()),
  requestJoinIoT: () => dispatch(requestJoinIoT()),
  leaveWaitingRoom: () => dispatch(leaveWaitingRoomIoT())
});

export default compose(
  withStyles(useStyles),
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(WaitingRoom);
