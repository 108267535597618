import cn from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

const GroupLogo = ({ apiHost, baseURLs, forSideBar, groupId }) => {
  return (
    <div
      className={cn(styles.groupLogo, {
        [styles.forSideBar]: forSideBar
      })}
      style={{ backgroundImage: `url("${baseURLs[apiHost]}/groups/${groupId}/logo")` }}
    />
  )
}

export default GroupLogo;
