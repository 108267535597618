import * as React from 'react';

import Button from '../Button';
import * as Modal from './Modal';
import styles from './styles.module.scss';

class GroupConsentPolicyModal extends React.PureComponent {
  state = {
    activePolicyIndex: 0
  }

  handlePolicySelect = (e, i) => {
    e.preventDefault();
    this.setState({ activePolicyIndex: i });
  }

  render() {
    const { close, agree, consentPolicies } = this.props;
    const policy = consentPolicies[this.state.activePolicyIndex];

    if (!policy) return null;

    return (
      <>
        <Modal.Wrapper
          backdrop
          keyboard
          onRequestClose={close}
          size="lg"
        >
          <Modal.Header>
            <div><b>Consent Policy</b></div>
          </Modal.Header>
          <Modal.Body className={styles.groupConsentPolicyModalBody}>
            <div>
              {
                consentPolicies.map((p, i) => (
                  /* eslint-disable */
                  <a
                    key={p.language}
                    href="#"
                    onClick={(e) => this.handlePolicySelect(e, i)}
                    className={`${styles.filterByLang} ${this.state.activePolicyIndex === i ? styles.selected : ''}`}
                  >
                    {p.language}
                  </a>
                ))
              }
            </div>
            <div className={styles.modalContentContainer} dangerouslySetInnerHTML={{ __html: policy.consent_policy }} />
          </Modal.Body>
          <Modal.Footer>
            <Button skinnyBtn secondary onClick={close}>{policy.decline_button_text}</Button>
            <Button skinnyBtn onClick={agree}>{policy.agree_button_text}</Button>
          </Modal.Footer>
        </Modal.Wrapper>
      </>
    );
  }
}

export default GroupConsentPolicyModal;
