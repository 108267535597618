import { all, spawn } from '@redux-saga/core/effects';
import { call } from 'redux-saga/effects';

import chatSaga from './modules/chat/saga';
import conferenceSaga from './modules/conference/saga';
import imageCaptureSaga from './modules/imageCapture/saga';
import { loginWatcher } from './modules/login/saga';
import pastConferenceSaga from './modules/pastConference/saga';
import serverConfigSaga from './modules/serverConfig/saga';

export default function* rootSaga() {
  const sagas = [
    conferenceSaga,
    chatSaga,
    loginWatcher,
    imageCaptureSaga,
    pastConferenceSaga,
    serverConfigSaga,
  ];

  // eslint-disable-next-line func-names
  yield all(sagas.map((saga) => spawn(function* () {
    while (true) {
      try {
        yield call(saga);
        break;
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
      }
    }
  })));
}
