import { AddParticipantModal, HostLeavingModal, RecordingNotificationModal } from '../../../../components/shared/Modal';
import React, { PureComponent } from 'react';
import { faComments, faExchangeAlt, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CapturedImages from '../CapturedImages';
import Chat from '../../../chat';
import ConferenceDesktop from './ConferenceDesktop';
import ConferenceFooter from './ConferenceFooter';
import ConferenceMobile from './ConferenceMobile';
import Drawer from '@material-ui/core/Drawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeftSideBar from '../LeftSideBar/index';
import Notification from '../../../../components/Notification';
import Participants from './Participants';
import WaitingRoom from '../WaitingRoom';
import WaitingToJoinOverlay from './WaitingToJoinOverlay';
import cn from 'classnames';
import copy from 'copy-to-clipboard';
import { datadogLogs } from '@datadog/browser-logs';
import { hasPermission } from 'utils/userRole';
import isMobile from 'is-mobile';
import { logger } from 'utils/logger';
import logo from '../../../../assets/teledent_horizontal.png';
import moment from 'moment';

// import { trackEvent, getEnv } from 'utils/ChurnZero';

class Conference extends PureComponent {
  constructor(props) {
    super(props);

    const { match: { params: { roomId: videoSessionId } } } = props;
    const { searchParams } = new URL(window.location.href);
    const authToken = searchParams.get('authToken') || sessionStorage.getItem('authToken');

    datadogLogs.setGlobalContext({
      ...datadogLogs.getGlobalContext(),
      room_id: videoSessionId
    })
    props.setAuthorizationHeader(authToken);
    props.getIsParticipant(videoSessionId);
    props.getVCInfo(videoSessionId);
    props.loadConfig();
    props.setDevices();
    if (navigator.mediaDevices) {
      navigator.mediaDevices.ondevicechange = function () {
        logger.info('mediaDevices.ondevicechange emitted')
        props.setDevices();
      };
    }

    this.state = {
      addParticipantModalIsOpen: false,
      authToken,
      capturedImagesIsOpen: false,
      drawerOpen: false,
      facingMode: 'user',
      forMobile: isMobile() || window.innerWidth < 768,
      hostLeavingModalIsOpen: false,
      isConnected: false,
      isMicMuted: false,
      isRecordingLocally: false, // make sure only local recording is uploaded
      recordingModalIsOpen: props.isRecord || false,
      recordingStarted: null, // 'user' - front, 'environment' - back,
      showChat: false,
      showParticipants: props.isHost,
      videoSessionId
    };

    this.previewRef = React.createRef();
  }

  get forLandscape() {
    const mql = window.matchMedia("(orientation: landscape)")
    return this.state.forMobile && mql.matches
  }

  get isHost() {
    const { userInfo, vcInfo } = this.props
    return userInfo?.id === vcInfo?.host_id
  }

  closeAddParticipantModal = () => {
    this.setState({ addParticipantModalIsOpen: false });
  }

  closeHostLeavingModal = () => {
    this.setState({ hostLeavingModalIsOpen: false });
  }

  closeRecordingModal = () => {
    this.setState({ recordingModalIsOpen: false });
  }

  getAccessibleProviders = () => {
    this.props.getAccessibleProviders()
  }

  handleChatClose = () => {
    this.setState({ showChat: false });
  };

  handleResize = () => {
    this.setState({
      forMobile: isMobile() || window.innerWidth < 768
    });
  }

  openAddParticipantModal = () => {
    this.setState({ addParticipantModalIsOpen: true })
  }

  openCapturedImages = () => {
    this.setState({ capturedImagesIsOpen: true });
  }

  openMoreDrawer = () => {
    this.setState({ drawerOpen: true })
  }

  setIsMicMuted = (isMicMuted) => {
    this.setState({ isMicMuted });
  }

  setIsRecordingLocally = (isRecordingLocally, callback) => {
    this.setState({ isRecordingLocally }, callback);
  }

  toggleChatHandler = () => {
    this.setState((prevState) => ({ showChat: !prevState.showChat }));
  };

  toggleCapturedImages = () => {
    this.setState({ capturedImagesIsOpen: !this.state.capturedImagesIsOpen });
  };

  toggleParticipants = () => {
    this.setState((prevState) => ({ showParticipants: !prevState.showParticipants }));
  }

  handleRecordToggle = () => {
    const { setIsRecordingIoT, isRecord } = this.props;

    if (isRecord) {
      // NOTE: For now, disable this event
      // window.ChurnZero.push(['setContact', group_id, `Mouthwatch-${getEnv()}`])
      // trackEvent(
      //   'Video conference recorded',
      //   'When a provider hits the "Record" button two times during a video conference: once to start a recording, a second time to finish the recording.',
      //   {
      //     username,
      //     length_of_recording: moment.duration(moment().diff(recordingStarted)).seconds(),
      //   }
      // )
    } else {
      this.setState({
        recordingStarted: moment()
      });
    }
    setIsRecordingIoT(!isRecord);
    this.setIsRecordingLocally(!isRecord);
  }

  handleCameraAction = (item) => {
    const { id: deviceId, text, evData } = item;

    if (evData === 'ADD_CAMERA') {
      this.props.attachLocalFeed(text.substring(0, 10), { deviceId });
    } else {
      this.props.replaceLocalFeed(text, deviceId);
    }
  }

  handleLayoutMode = ({ id }) => {
    this.props.setLayoutMode(id);
  }

  switchFacingMode = () => {
    this.setState((prevState) => ({
      facingMode: prevState.facingMode === 'user' ? 'environment' : 'user'
    }), () => {
      const { facingMode } = this.state;
      const { layout } = this.props;

      navigator.mediaDevices.getUserMedia({
        video: { facingMode }
      })
        .then((stream) => {
          // eslint-disable-next-line no-empty
          if (!stream.getVideoTracks() || stream.getVideoTracks().length === 0) {
          } else {
            const videoTrack = stream.getVideoTracks()[0];
            const capabilities = videoTrack.getSettings();

            if (layout.length > 0) {
              // after join
              this.props.replaceLocalFeed(videoTrack.label, capabilities.deviceId);
            } else if (this.previewRef.current) {
              // on Preview screen
              this.previewRef.current.handleChangeCamera({
                label: videoTrack.label,
                value: capabilities.deviceId
              });
            }
          }
        })
        .catch((err) => {
          this.props.enqueueSnackbar('Problem with video device',
            { variant: 'error', preventDuplicate: true });

          // eslint-disable-next-line no-console
          console.log('Switching Facing Mode:', err);
        });
    });
  }

  help = () => {
    const helpTab = window.open('https://support.mouthwatch.com/hc/en-us', '_blank');

    helpTab.focus();
  }

  copyUrl = () => {
    const { authToken, videoSessionId } = this.state;
    const url = `${window.location.origin}/conference/${videoSessionId}?authToken=${authToken}`;

    copy(url);
    this.props.enqueueSnackbar('Copied!');
  }

  onExit = () => {
    const { isHost } = this.props;

    if (isHost) {
      this.setState({ hostLeavingModalIsOpen: true });
      return;
    }

    this.exitFromCallManually();
  }

  exitFromCallManually = () => {
    const { match: { params: { roomId } }, history, destroySession, isPatient, isRecord, setIsRecordingIoT, viewPerms } = this.props;
    const { isRecordingLocally } = this.state;

    destroySession();
    if (isRecord && isRecordingLocally && !isPatient) {
      this.setIsRecordingLocally(false, () => {
        setIsRecordingIoT(false);
        if (hasPermission(viewPerms, 'vc_snapshots')) {
          history.push(`/media/${roomId}`);
        } else {
          setTimeout(window.close, 300);
          this.goToWaitingRoom();
        }
      });
    } else if (!isPatient && hasPermission(viewPerms, 'vc_snapshots')) {
      history.push(`/media/${roomId}`);
    } else {
      setTimeout(window.close, 300);
      this.goToWaitingRoom();
    }
  }

  goToWaitingRoom = () => {
    const { exitFromCall } = this.props;
    const isPopup = window.opener && window.opener !== window;
    if (!isPopup) {
      exitFromCall();
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('beforeunload', this.beforeUnload)
    this.handleResize();
  }

  beforeUnload = () => {
    this.props.leaveWaitingRoom();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('beforeunload', this.beforeUnload)
    this.props.destroySession();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isRecord && this.props.isRecord) {
      this.setState({ recordingModalIsOpen: true });
    }

    /*
      wait for async operations getUserInfo and getVCInfo
      then connect to iot
    */
    if (this.props.vcInfo === prevProps.vcInfo && this.props.userInfo === prevProps.userInfo && this.state.isConnected === prevState.isConnected) {
      return
    }
    this.setState({ showParticipants: this.props.isHost })
    const { connectIoT, fetchMedia, match: { params: { roomId: videoSessionId } }, userInfo, vcInfo, createSession } = this.props;

    if (vcInfo && userInfo && !this.state.isConnected) {
      this.setState({ isConnected: true });
    }

    if (this.state.isConnected === true && prevState.isConnected === false) {
      createSession({ videoSessionId, shouldGetToken: vcInfo.host_id === userInfo.id });
      connectIoT(videoSessionId);
      if (!userInfo.is_patient) {
        fetchMedia(videoSessionId);
      }
    }
  }

  renderWaitingRoom = () => {
    const { forMobile, isMicMuted, isConnected } = this.state
    return (
      <WaitingRoom
        forMobile={forMobile}
        isConnected={isConnected}
        isMuted={isMicMuted}
        ref={this.previewRef}
        setIsMicMuted={this.setIsMicMuted}
        switchFacingMode={this.switchFacingMode}
        forLandscape={this.forLandscape}
      />
    )
  }

  addParticipant = (id) => {
    this.props.addParticipant(id);
  }

  assignNewHost = (hostId) => {
    const { updateHost } = this.props;

    updateHost(hostId);
    this.exitFromCallManually();
  }

  endCall = () => {
    const { endCall } = this.props;
    endCall();
    this.exitFromCallManually();
  }

  render() {
    const {
      addParticipantModalIsOpen,
      hostLeavingModalIsOpen,
      capturedImagesIsOpen,
      forMobile,
      isMicMuted,
      isRecordingLocally,
      recordingModalIsOpen,
      showChat,
      showParticipants,
    } = this.state

    const {
      accessibleProviders,
      classes,
      layout,
      isHost,
      notification: { type, message },
      participants,
      userInfo,
      vcInfo,
      videoDevices,
      unreadMessages,
    } = this.props

    const participantIds = participants.map(participant => participant?.identity)
    const providerParticipants = (vcInfo?.participants || []).filter(
      participant => !participant?.is_patient && participantIds.includes(participant?.id) && participant?.id !== vcInfo?.host_id
    )

    const contentWrapperClassNames = cn(classes.contentWrapper, {
      [classes.contentWrapperLandscape]: this.forLandscape,
      [classes.greyBackdrop]: forMobile && layout.length > 0
    })

    return (
      <div className={classes.root}>
        <Box
          className={cn(classes.content, { [classes.withSider]: layout.length > 0 })}
          display="flex"
          p={0}
          flexDirection="column"
        >
          <img className={cn(classes.appLogo, 'app-logo', 'desktop-only')} src={logo} alt="Teledent Logo" />
          <div className={contentWrapperClassNames}>
            {
              showParticipants && layout.length > 0 &&
              <Participants
                isMobile={this.state.forMobile}
                onClose={this.toggleParticipants}
                openAddParticipantModal={this.openAddParticipantModal}
              />
            }
            <div className={classes.videoContent}>
              {this.state.forMobile
                ? <ConferenceMobile
                  forLandscape={this.forLandscape}
                  participantsClick={this.toggleParticipants}
                  {...this.props}
                />
                : (
                  <ConferenceDesktop
                    capturedImagesIsOpen={capturedImagesIsOpen}
                    isParticipantsOpen={showParticipants}
                    isRecordingLocally={isRecordingLocally}
                    openCapturedImages={this.openCapturedImages}
                    setIsRecordingLocally={this.setIsRecordingLocally}
                    {...this.props}
                  />
                )
              }

              {isHost && layout.length > 0 && <WaitingToJoinOverlay />}
            </div>
            {
              layout.length > 0 && !userInfo.is_patient &&
              <CapturedImages isOpen={capturedImagesIsOpen} onToggle={this.toggleCapturedImages} />
            }
            {layout.length === 0 && this.renderWaitingRoom(isMicMuted)}
          </div>
          {
            layout.length !== 0 &&
            <ConferenceFooter
              classes={classes}
              forMobile={forMobile}
              isMicMuted={isMicMuted}
              onExit={this.onExit}
              openMore={this.openMoreDrawer}
              setIsMicMuted={this.setIsMicMuted}
              unreadMessages={unreadMessages}
            />
          }
          <Drawer BackdropProps={{ style: { background: '#003558CC' } }} anchor="bottom" open={this.state.drawerOpen} onClose={() => this.setState({ drawerOpen: !this.state.drawerOpen })}>
            <div className={classes.drawerHeader}>
              <p>More</p>
              <ArrowDownIcon fontSize="small" onClick={() => this.setState({ drawerOpen: !this.state.drawerOpen })} />
            </div>
            <div className={classes.drawerItemsContainer}>
              {(this.state.forMobile && videoDevices && videoDevices.length > 1) && (
                <div className={classes.drawerItem}>
                  <Button onClick={this.switchFacingMode}>
                    <FontAwesomeIcon icon={faExchangeAlt} />
                    Switch Camera
                  </Button>
                </div>
              )}
              <div className={classes.drawerItem}>
                {unreadMessages > 0 && (
                  <div className={classes.badge}>{unreadMessages}</div>
                )}
                <Button
                  onClick={() => {
                    this.toggleChatHandler();
                    this.setState({ drawerOpen: false });
                  }}
                >
                  <FontAwesomeIcon icon={faComments} />
                  Chat
                </Button>
              </div>
              <div className={classes.drawerItem}>
                <Button onClick={this.help}>
                  <FontAwesomeIcon icon={faQuestionCircle} />
                  Help
                </Button>
              </div>
            </div>
          </Drawer>
          {message && <Notification type={type} message={message} onClose={this.handleClose} />}
          {showChat && <Chat onChatClose={this.handleChatClose} />}
        </Box>
        {!this.state.forMobile && layout.length > 0 && (
          <LeftSideBar className={classes.sider}
            chatClick={this.toggleChatHandler}
            cameraClick={this.handleCameraAction}
            layoutClick={this.handleLayoutMode}
            participantsClick={this.toggleParticipants}
            helpClick={() => {
              const helpTab = window.open('https://support.mouthwatch.com/hc/en-us', '_blank');

              helpTab.focus();
            }}
            recordingClick={this.handleRecordToggle} />
        )}
        <AddParticipantModal
          addParticipant={this.addParticipant}
          accessibleProviders={accessibleProviders}
          close={this.closeAddParticipantModal}
          isOpen={addParticipantModalIsOpen}
          getAccessibleProviders={this.getAccessibleProviders}
        />
        <RecordingNotificationModal
          close={this.closeRecordingModal}
          isOpen={recordingModalIsOpen}
        />
        <HostLeavingModal
          assignNewHost={this.assignNewHost}
          classes={classes}
          close={this.closeHostLeavingModal}
          endCall={this.endCall}
          isOpen={hostLeavingModalIsOpen}
          providerParticipants={providerParticipants}
        />
      </div>
    );
  }
}

export default Conference;
