import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Checkbox from '@material-ui/core/Checkbox';
import ReactPlayer from 'react-player';
import theme from './MediasList.module.css';
import { baseURL } from '../../../../api2';

class MediasList extends Component {
  static propTypes = {
    captured: PropTypes.bool.isRequired,
    mediaList: PropTypes.array.isRequired,
    onMediaToggle: PropTypes.func.isRequired
  };

  src = (id) => {
    const { token } = this.props;

    return `${baseURL}/media/${id}?authToken=${token}`;
  };

  handleMediaCheck = (event) => {
    const { onMediaToggle } = this.props;
    const { value, checked } = event.target;

    onMediaToggle(value, checked);
  };

  render() {
    const { mediaList, captured } = this.props;

    let filteredMediaList = mediaList.filter(({ patient_id }) => captured ? patient_id : !patient_id)

    return (
      <div className={theme.list}>
        <GridList cellHeight={140} cols={3} className={theme.listGrid} spacing={8}>
          {filteredMediaList.map(({ id, mime_type, file_name }) => (
            <GridListTile key={id} className={theme.listGridItem}>
              <div className={theme.listGridMediaWrapper}>
                {(mime_type === 'image/png' || mime_type === 'image/jpeg')
                  ? (<img className={theme.listGridImage} src={this.src(id)} alt={file_name} />)
                  : (
                    <ReactPlayer
                      className="react-player"
                      url={[{ src: this.src(id), type: mime_type }]}
                      controls
                      width="100%"
                      height="100%"
                    />
                  )}
                {!captured && <GridListTileBar
                  titlePosition="top"
                  actionIcon={(
                    <Checkbox
                      classes={{ root: theme.listGridCheckbox }}
                      onChange={this.handleMediaCheck}
                      value={id}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  )}
                  actionPosition="left"
                  className={theme.listGridTitleBar}
                />}
              </div>
              <p className={theme.listGridText}>{file_name}</p>
            </GridListTile>
          ))}
        </GridList>
      </div>
    );
  }
}

export default MediasList;
