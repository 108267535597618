export const useStyles = () => ({
  waitingItem: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  participantName: {
    flex: 1,
    color: '#4C575D',
    fontSize: 16,
    marginBottom: 0,
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actionItemContainer: {
    alignItems: 'center',
    backgroundColor: '#49a6ff !important',
    borderRadius: '6px',
    color: '#fff',
    fontSize: '14px',
    height: 30,
    padding: '4px 16px',
  },
});
  