import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React from 'react';

import { CircleButtonWithText } from '../CircleButtonWithText/CircleButtonWithText';

const SwitchFacingModeButton = ({ onClick, isActive, isDisabled, isSupported }) => {
  if (!isSupported) return null;
  return (
    <CircleButtonWithText
      onClick={onClick}
      icon={faExchangeAlt}
      text="Camera"
      isDisabled={isDisabled}
      isActive={isActive}
    />
  );
};

export default SwitchFacingModeButton;

SwitchFacingModeButton.propTypes = {
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSupported: PropTypes.bool,
  onClick: PropTypes.func
};

SwitchFacingModeButton.defaultProps = {
  isActive: false,
  isDisabled: false,
  isSupported: true,
  onClick: () => {}
};
