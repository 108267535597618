export const useStyles = ({ palette: { common } }) => ({
  joinBtn: {
    marginLeft: 20
  },
  btn: {
    height: 40,
    padding: '6px 16px!important',
    width: 180
  },
  card: {
    boxShadow: '0px 0px 15px #00000033',
    display: 'flex',
    flexDirection: 'row',
    maxHeight: 400,
    maxWidth: 600,
    position: 'relative',
    height: '100%',
  },
  cogIcon: {
    marginRight: 5
  },
  checkbox: {
    color: '#4E575C',
  },
  checkboxLabel: {
    marginLeft: 12,
    lineHeight: 1.2
  },
  content: {
    padding: 0,
    width: '100%',
    background: common.black,
    flex: 1,
    position: 'relative',
    height: '100%'
  },
  error: {
    padding: '20px 0',
    fontSize: '10px',
    flex: '100%',
  },
  groupLogoContainer: {
    height: 190,
    width: 190,
  },
  groupLogoContainerLandscape: {
    height: 120,
    width: 120
  },
  link: {
    color: '#49A6FF',
    fontWeight: 'bold',
    textDecoration: 'none'
  },
  micAndVidBtns: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px auto',
  },
  micAndVidBtnsLandscape: {
    flexBasis: 60,
    flexWrap: 'wrap',
    marginLeft: 10,
    '& button': {
      marginBottom: 10
    }
  },
  micOffIcon: {
    position: 'absolute',
    bottom: '20px',
    right: '20px',
    zIndex: 100,
    color: '#fff',
    opacity: '0.7',
    '& svg': {
      width: '3rem',
      height: 'rem'
    }
  },
  previewStreamHolder: {
    height: 400,
    width: 600
  },
  previewStreamHolderLandscape: {
    height: 220,
    width: 325
  },
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
    padding: '0 5%',
    maxWidth: 1500
  },
  rootError: {
    maxWidth: 'none',
  },
  rootLandscape: {
    flexWrap: 'nowrap'
  },
  terms: {
    margin: '24px 0 12px',
  },
  welcomeMessage: {
    flexBasis: '100%',
    marginBottom: 45,
    fontSize: '2rem',
    maxWidth: '90%',
  },
  welcomeMessageError: {
    marginBottom: 0,
  },
  welcomeMessageHolder: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    position: 'relative',
    left: -20,
  },
  welcomeMessageLandscape: {
    flexBasis: 250,
    fontSize: '1.2rem',
    marginBottom: 10,
    maxWidth: '100%'
  },
  [`@media (max-width: ${768}px)`]: {
    card: {
      width: '100%',
      maxWidth: 'unset',
    },
    error: {
      textAlign: 'center'
    },
    groupLogoContainer: {
      height: 120,
      width: 120
    },
    previewStreamHolder: {
      width: '95%'
    },
    root: {
      justifyContent: 'space-around',
    },
    terms: {
      margin: '24px 5px 12px',
    },
    welcomeMessageHolder: {
      justifyContent: 'center',
      width: '80%',
      flex: 'none',
      left: 0,
    },
    welcomeMessage: {
      textAlign: 'center',
      marginBottom: 5,
      maxWidth: 'none',
    },
    joinBtn: {
      marginLeft: 0
    },
  }
});
