export const getQueryStringValue = (name) => {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(window.location.search);

  return results === null ? false : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const getHash = (input) => {
  let hash = 0;

  const
    len = input.length;
  // eslint-disable-next-line

  for (let i = 0; i < len; i += 1) {
    hash = ((hash << 5) - hash) + input.charCodeAt(i);
    hash |= 0; // to 32bit integer
  }
  return hash;
};

export const isIOSChrome = () => /CriOS/i.test(navigator.userAgent) && /iphone|ipod|ipad/i.test(navigator.userAgent);

export const trackPubsToTracks = (trackMap) => {
  return Array.from(trackMap.values())
    .map((publication) => publication.track)
    .filter((track) => track !== null);
};
