import { createStyles, makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => createStyles({
  root: {
    maxWidth: '200px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
}));
