import cn from 'classnames';
import * as React from 'react';
import Select from 'react-select';

import Button from '../Button';
import * as Modal from './Modal';
import styles from './styles.module.scss';

const HostLeavingModal = ({ assignNewHost, classes, close, endCall, isOpen, providerParticipants }) =>{
  const providerOptions = React.useMemo(() => {
    return providerParticipants.map(p => ({
      value: p.id,
      label: `${p.first_name} ${p.last_name}`
    }))
  }, [providerParticipants]);
  const [newHost, setNewHost] = React.useState(null)
  const renderDropdown = () => (
    <div>
      <Select
        options={providerOptions}
        onChange={setNewHost}
        className={cn('basic-single', classes.select)}
        classNamePrefix="select"
      />
    </div>
  )

  const assignAsHost = () => {
    if (newHost) {
      assignNewHost(newHost.value)
    }
  }

  return (
    <Modal.Wrapper backdrop close={close} isOpen={isOpen} size='md'>
      <Modal.Body className={styles.hostLeavingModal}>
        <div className={styles.row}>
          <p>You are the host of this meeting. Before you leave, assign another provider as the new host or end the call.</p>
        </div>
        <div className={cn(styles.row, styles.actionRow)}>
          <div className={styles.actionItem}>{renderDropdown()}</div>
          <div className={styles.actionItem}>
            <Button className={styles.btn} disabled={!newHost} onClick={assignAsHost}>Assign as Host and Leave</Button>
          </div>
          <div className={styles.separator}>OR</div>
          <div className={styles.actionItem}>
            <Button className={cn(styles.btn, styles.endCallBtn)} onClick={endCall}>End Call for All</Button>
          </div>
        </div>
        <div className={cn(styles.row, styles.footer)}><hr/><a className={styles.cancel} href onClick={() => close()}>Cancel</a></div>
      </Modal.Body>
    </Modal.Wrapper>
  )
}

export default HostLeavingModal;
