export const FETCH_MEDIA = 'FETCH_MEDIA';
export const FETCH_PATIENTS_LIST = 'FETCH_PATIENTS_LIST';
export const SET_PHOTOS_LIST = 'SET_PHOTOS_LIST';
export const SET_VIDEOS_LIST = 'SET_VIDEOS_LIST';
export const SET_MEDIA_LIST = 'SET_MEDIA_LIST';
export const SET_PATIENTS_LIST = 'SET_PATIENTS_LIST';
export const UPDATE_PATIENTS_LIST = 'UPDATE_PATIENTS_LIST';
export const LOAD_MESSAGES = 'LOAD_MESSAGES';
export const SET_LOADING = 'SET_LOADING';
export const SET_PATIENT = 'SET_PATIENT';
export const ATTACH_MEDIA_TO_PATIENT = 'ATTACH_MEDIA_TO_PATIENT';
export const TOGGLE_MEDIA = 'TOGGLE_MEDIA';
