import { logger } from "./logger";

const userMediaUtils = {
  async getMediaDevices() {
    logger.info('getMediaDevices started', {
      step: 'get.media.devices',
    })
    try {
      return await navigator.mediaDevices.enumerateDevices();
    } catch (e) {
      logger.error('getMediaDevices failed', {
        step: 'get.media.devices',
        error: JSON.stringify(e)
      })
      return null;
    }
  },
  async getVideoDevices() {
    logger.info('getVideoDevices started', {
      step: 'get.video.devices',
    })
    try {
      const devices = await this.getMediaDevices();
      logger.info('Successfully get the list of available media devices', {
        step: 'get.video.devices',
        devices
      })

      return devices
        .filter((item) => item.kind === "videoinput")
        .map((item) => {
          const { label, deviceId } = item;

          return { label, deviceId };
        });
    } catch (e) {
      logger.error('Getting availble video devices is failed', {
        step: 'get.video.devices',
        error: JSON.stringify(e)
      })
      return null;
    }
  },
  async getAudioDevices() {
    logger.info('getAudioDevices started', {
      step: 'get.audio.devices',
    })
    try {
      const devices = await this.getMediaDevices();

      return devices
        .filter((item) => item.kind === "audioinput")
        .map((item) => {
          const { label, deviceId } = item;

          return { label, deviceId };
        });
    } catch (e) {
      logger.error('Getting availble audio devices is failed', {
        step: 'get.audio.devices',
        error: JSON.stringify(e)
      })
      return null;
    }
  },
  async getMediaStream() {
    logger.info('getMediaStream started', {
      step: 'get.media.stream',
    })
    const audioDevices = await this.getAudioDevices()
    const videoDevices = await this.getVideoDevices()

    try {
      return await navigator.mediaDevices.getUserMedia({
        audio: videoDevices.length > 0,
        video: audioDevices.length > 0
      })
    } catch (e) {
      logger.info('Permission check microphone & camera failed', {
        step: 'get.media.stream.video_audio',
        error: JSON.stringify(e)
      })
      console.log('Permission Check Microphone & Camera: ', e.message)
    }

    if (videoDevices.length > 0) {
      try {
        return await navigator.mediaDevices.getUserMedia({
          video: true,
        })
      }catch (e) {
        logger.info('Permission check camera failed', {
          step: 'get.media.stream.video',
          error: JSON.stringify(e)
        })
        console.log('Permission Check Camera: ', e.message)
      }
    }

    return await navigator.mediaDevices.getUserMedia({
      audio: true,
    })
  }
};

export default userMediaUtils;
