import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './colors.css';
import './index.css';

import * as serviceWorker from './serviceWorker';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';

import App from './app/index';
import Conference from './modules/conference';
import Login from 'modules/login';
import Notifier from './modules/notifier/components/Notifier';
import PastConference from './modules/pastConference';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter as Router } from 'connected-react-router';
import { SnackbarProvider } from 'notistack';
import colors from './colors';
import history from './history';
import { store } from './store';

const muiTheme = createMuiTheme({
  spacing: 4,
  shape: {
    borderRadius: 5,
  },
  typography: {
    fontFamily: [
      'Source Sans Pro',
      'sans-serif'
    ].join(','),
  },
  palette: {
    primary: {
      light: colors.primary.lightest_blue,
      main: colors.primary.blue,
      darkBlue: colors.primary.dark_blue,
      dark: colors.primary.darkest_blue,
      blueBlack: colors.primary.dark_blue_black,
      contrastText: colors.primary.white,
    },
    secondary: {
      light: colors.primary.dark_gray,
      main: colors.primary.disabled_gray,
      dark: colors.primary.darkest_gray,
      darkerGray: colors.primary.darker_gray,
      darkestGray: colors.primary.darkest_gray
    },
    action: {
      main: colors.primary.white,
      contrastText: colors.primary.white,
      light: colors.primary.white,
      dark: colors.primary.white,
    },
    black: {
      main: colors.primary.black,
    },
    red: {
      main: colors.accents.accent_red,
    },
    orange: {
      main: colors.accents.accent_orange,
    },
    darkYellow: {
      main: colors.accents.accent_dark_yellow,
    },
    lightYellow: {
      main: colors.accents.accent_light_yellow,
    },
    green: {
      main: colors.accents.accent_green,
    },
    turqoise: {
      main: colors.accents.accent_turqoise,
    },
    blue: {
      main: colors.accents.accent_blue,
    },
    acceptCall: {
      main: colors.accents.accent_green,
    },
    decline: {
      main: colors.accents.accent_bright_red,
    }
  },
});

ReactDOM.render(
  <MuiThemeProvider theme={muiTheme}>
    <Provider store={store}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className="snackBarProvider"
      >
        <div className="app">
          <Router history={history}>
            <Switch>
              <App>
                <Route path="/login" component={Login} />
                <Route path="/conference/:roomId" component={Conference} />
                <Route path="/media/:roomId" component={PastConference} />
              </App>
            </Switch>
          </Router>
        </div>

        <Notifier />
      </SnackbarProvider>
    </Provider>

  </MuiThemeProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
