import {
  audioTrackStatsSelector,
  isHostSelector,
  mutedStreamsSelector,
  onWaitingRoomParticipantsSelector,
  participantsSelector,
  vcInfoSelector,
  videoMutedStreamsSelector,
  waitingParticipantsSelector
} from '../../../selectors/videoRoom';

import Participants from './Participants';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useStyles } from './Participants-styles';
import { withStyles } from '@material-ui/core';

const mapStateToProps = (state) => ({
  audioTrackStats: audioTrackStatsSelector(state),
  isHost: isHostSelector(state),
  mutedStreams: mutedStreamsSelector(state),
  participants: participantsSelector(state),
  vcInfo: vcInfoSelector(state),
  videoMutedStreams: videoMutedStreamsSelector(state),
  waitingParticipants: waitingParticipantsSelector(state),
  onWaitingRoomParticipants: onWaitingRoomParticipantsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  withStyles(useStyles),
  connect(mapStateToProps, mapDispatchToProps)
)(Participants);
