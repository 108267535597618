import { withStyles } from '@material-ui/core';
import { acceptParticipantIoT, declineParticipantIoT } from 'modules/chat/actions';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { acceptingParticipantsSelector, vcInfoSelector, waitingParticipantsSelector } from '../../../selectors/videoRoom';
import WaitingToJoin from './WaitingToJoin';
import { useStyles } from './WaitingToJoin-styles';

const mapStateToProps = (state) => ({
  waitingParticipants: waitingParticipantsSelector(state),
  vcInfo: vcInfoSelector(state),
  acceptingParticipants: acceptingParticipantsSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  declineParticipant: (participantId) => dispatch(declineParticipantIoT(participantId)),
  acceptParticipant: (participant) => dispatch(acceptParticipantIoT(participant)),
});

export default compose(
  withStyles(useStyles),
  connect(mapStateToProps, mapDispatchToProps)
)(WaitingToJoin);
