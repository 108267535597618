import React, { useMemo } from 'react'

import { Button } from '@material-ui/core';
import cn from 'classnames'

const WaitingToJoinOverlay = ({
  classes,
  waitingParticipants,
  declineParticipant,
  acceptParticipant,
  acceptingParticipants,
  vcInfo
}) => {
  const participants = useMemo(() => {
    return (vcInfo?.participants || []).filter(participant => waitingParticipants.includes(participant.id) && !acceptingParticipants.includes(participant.id))
  }, [vcInfo, waitingParticipants, acceptingParticipants])

  const onAccept = (participant) => () => {
    acceptParticipant(participant);
  }

  const onDecline = (participantId) => () => {
    declineParticipant(participantId);
  }

  const onAcceptAll = () => {
    participants.forEach(participant => {
      acceptParticipant(participant);
    });
  }

  const onDeclineAll = () => {
    participants.forEach(participant => {
      declineParticipant(participant.id);
    });
  }

  if (participants.length === 0) {
    return null;
  }

  return (
    <div className={cn(classes.waitingJoinOverlay, { [classes.center]: participants.length > 2 })}>
      {participants.length > 2 ? (
        <div className={classes.participantItem}>
          <p className={classes.participantName}>{participants.length} people are waiting to join the call.</p>
          <div className={classes.actionContainer}>
            <div className={classes.actionItemContainer}>
              <a className={cn(classes.action, classes.accept)} href={true} onClick={onAcceptAll}>Accept All</a>
            </div>
            <div className={classes.actionItemContainer}>
              <a className={cn(classes.action, classes.decline)} href={true} onClick={onDeclineAll}>Decline All</a>
            </div>
          </div>
        </div>
      ) : participants.map((participant, index) => {
        const name = `${participant.first_name} ${participant.last_name}`;

        return (
          <div className={classes.participantItem} key={index}>
            <p className={classes.participantName}>{name} is waiting to join.</p>
            <div className={classes.actionContainer}>
              <Button className={classes.acceptButton} onClick={onAccept(participant)}>
                Accept
              </Button>
              <div className={classes.actionItemContainer}>
                <a className={cn(classes.action, classes.decline)} href={true} onClick={onDecline(participant.id)}>Decline</a>
              </div>
            </div>
            {index !== participants.length - 1 && <div className={classes.border} />}
          </div>
        )
      })}

    </div>
  )
};

export default WaitingToJoinOverlay
