import React, { Component } from 'react';
import { apiHost, baseURLs } from '../../../../api2';

import DisabledTooltip from 'components/shared/DisabledTooltip';
import Drawer from '@material-ui/core/Drawer';
import EssentialTooltip from 'components/shared/EssentialTooltip';
import GroupLogo from '../../../../components/shared/GroupLogo/GroupLogo';
import LeftSideBarItem from '../LeftSideBarItem/index';
import List from '@material-ui/core/List';
import PropTypes from 'prop-types';
import logo from '../../../../assets/teledent_horizontal.png';

class LeftSideBar extends Component {
  static defaultProps = {
    chatClick: () => {},
    cameraClick: () => {},
    layoutClick: () => {},
    recordingClick: () => {},
    helpClick: () => {},
    participantsClick: () => {},
  };

  static propTypes = {
    classes: PropTypes.any,
    chatClick: PropTypes.func.isRequired,
    cameraClick: PropTypes.func.isRequired,
    layoutClick: PropTypes.func.isRequired,
    recordingClick: PropTypes.func.isRequired,
    helpClick: PropTypes.func.isRequired,
    participantsClick: PropTypes.func.isRequired,
  };

  render() {
    const { classes, items, user, layoutMode } = this.props;

    return (
        <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
        > 
          <div className={classes.logoContainer}>
            <GroupLogo apiHost={apiHost} baseURLs={baseURLs} forSideBar groupId={user.group_id} />
          </div>
          <List classes={{ root: classes.list,
            padding: classes.listPadding,
            dense: classes.listDense,
            subheader: classes.listSubHeader }}
          >
            {items.map((item) => {
              if (user.is_patient && item.forOnlyProvider) {
                return null;
              }

              const Tooltip = item.isDisabled ? DisabledTooltip : EssentialTooltip;

              return (
                <Tooltip
                  key={item.text}
                  enabled={(item.isDisabled && item.disabledMessage) || item.noPermission === true}
                  content={(item.isDisabled && item.disabledMessage) || item.messageForEssential}
                >
                  <LeftSideBarItem
                    onClick={this.props[`${item.id}Click`]}
                    id={item.id}
                    layoutMode={layoutMode}
                    {...item}
                  />
                </Tooltip>
              );
            })}
          </List>

          <img className={`${classes.listLogo} mobile-only`} src={logo} alt="Teledent Logo" />
        </Drawer>
    );
  }
}

export default LeftSideBar;
