import moment from 'moment-timezone'

const logEvent = (eventName, eventDescription, isPatient, groupId, customFields, contactFields, groupFields, roles, channelPerOrg, quantity) => {
  const patientUserName = `patient${contactFields.id}`
  window.ChurnZero.push([ 'setContact', groupId, contactFields.id ])
  if (isPatient) {
    window.ChurnZero.push([ 'setAttribute', 'contact', {
      email: `${patientUserName}@mouthwatch.com`,
      firstName: 'Mouthwatch',
      lastName: 'Patient',
      roles
    }])
  } else {
    window.ChurnZero.push([ 'setAttribute', 'contact', {
      email: contactFields.email,
      channel: channelPerOrg || contactFields.channel,
      firstName: contactFields.first_name,
      lastName: contactFields.last_name,
      roles
    }])
  }

  window.ChurnZero.push([
    'trackEvent',
    eventName,
    eventDescription,
    quantity || 1,
    {
      ...customFields,
      group: groupFields.group,
      practices: groupFields.practices.join(', '),
      timestamp: moment().tz('EST').format('YYYY-MM-DD HH:mm:ss'),
      channel: channelPerOrg || customFields.channel,
      ...(isPatient ? { username: patientUserName, email: `${patientUserName}@mouthwatch.com` } : {})
    }
  ])
}
export const trackEvent = (eventName, eventDescription, account, customFields, quantity) => {
  if (window.ChurnZero) {
    const {
      is_patient: isPatient,
      group_id: groupId,
      account_roles: roles,
      channel_per_org: channelPerOrg,
      id,
      first_name,
      last_name,
      email,
      group_channel
    } = account

    const contactFields = {
      id,
      first_name,
      last_name,
      email,
      channel: group_channel
    }
    const groupFields = {
      group: null,
      practices: []
    }

    let roleNames = 'patient'

    if (!isPatient) {
      roleNames = roles.map((role) => role.name).join(', ')
    }

    logEvent(eventName, eventDescription, isPatient, groupId, customFields, contactFields, groupFields, roleNames, channelPerOrg, quantity)
  }
}


export const getEnv = () => {
  if (process.env.NODE_ENV === 'staging') {
    return 'stage';
  } else if (process.env.NODE_ENV === 'production') {
    return 'prod';
  } else {
    return 'dev';
  }
};
