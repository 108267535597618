import { LOGIN_ERROR, LOGIN_REQUESTING, LOGIN_SUCCESS, SET_USER_INFO } from './actions/actionTypes';

const initialState = {
  requesting: false,
  successful: false,
  messages: [],
  errors: [],
};

const reducer = function loginReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUESTING:
      return {
        ...state,
        requesting: true,
        successful: false,
        messages: [{ body: 'Logging in...', time: new Date() }],
        errors: [],
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        errors: [],
        requesting: false,
        successful: true,
      };

    case LOGIN_ERROR:
      return {
        ...state,
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
        requesting: false,
        successful: false,
      };
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload.userInfo,
        groupInfo: action.payload.groupInfo
      };

    default:
      return state;
  }
};

export default reducer;
