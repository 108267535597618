import { connect } from 'react-redux';
import { currentUserSelector } from 'modules/login/selectors';
import { vcInfoSelector } from 'modules/conference/selectors/videoRoom';
import Chat from './Chat';
import { loadChat, sendMessage, resetUnreadMessages } from '../../actions';
import {
  messagesSelector,
  loadingSelector,
  isFirstLoadingSelector
} from '../../selectors';

const mapStateToProps = (state) => ({
  messages: messagesSelector(state),
  user: currentUserSelector(state),
  loading: loadingSelector(state),
  isFirstLoading: isFirstLoadingSelector(state),
  vcInfo: vcInfoSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  onMessageSend: (message) => dispatch(sendMessage(message)),
  loadChat: () => dispatch(loadChat()),
  resetUnreadMessages: () => dispatch(resetUnreadMessages())
});

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
