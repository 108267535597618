import cn from 'classnames';
import { get, map as lMap } from 'lodash';
import React from 'react';

import MobileActiveFeed from '../MobileActiveFeed';
import Stream from '../Stream';

const ConferenceMobile = ({
	classes,
	detachLocalFeed,
	isRecord,
	layout,
	participantsClick,
	pip,
	pipToggle,
	replaceLocalFeed,
	match: { params: { roomId: videoSessionId } },
	toggleCapturedImages
}) => {
	const getClassName = (el) =>
		cn('user-card', { 'user-card_active': get(el, 'feed.isTalking') }, { 'user-card_local': !el.remote });

	return (
		<div>
			<MobileActiveFeed fullscreen roomId={videoSessionId} />

			<div className={classes.layoutMobile}>
				{layout.length >= 1 && lMap(layout, (el) => {
					return (
						<div
							className={cn(
								classes.layoutWrapper,
								getClassName(el),
								{ [classes.floatPreview]: !el.remote }
							)}
							data-grid={el}
							key={el.i}
							onClick={participantsClick}
							style={{ zIndex: el.index }}
						>
							<Stream
								detachLocalFeed={detachLocalFeed}
								feed={el.feed}
								forMobileLocal={true}
								grid={el}
								isMasterFeed={el.isMasterFeed}
								isRecord={isRecord}
								name={el.feed.feedName}
								onVideoReady={() => {}}
								onAudioReady={() => {}}
								pip={pip}
								pipToggle={pipToggle}
								remote={el.remote}
								replaceLocalFeed={replaceLocalFeed}
								roomId={videoSessionId}
								share={el.share}
								toggleCapturedImages={toggleCapturedImages}
								videoTrackId={el.videoTrackId}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default ConferenceMobile;
