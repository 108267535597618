import * as at from './actions/actionTypes';

const initialState = {
  // photosList: [],
  // videosList: [],
  // messages: [],
  mediaList: [],
  loading: false,
  patients: [],
  selectedPatient: {},
  selectedMedia: new Set([])
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case at.SET_PHOTOS_LIST:
      return {
        ...state,
        photosList: payload
      };

    case at.SET_VIDEOS_LIST:
      return {
        ...state,
        videosList: payload
      };

    case at.SET_MEDIA_LIST:
      return {
        ...state,
        mediaList: payload
      };

    case at.SET_PATIENTS_LIST:
      return {
        ...state,
        patients: payload
      };

    case at.UPDATE_PATIENTS_LIST:
      return {
        ...state,
        patients: [
          ...state.patients,
          ...payload
        ]
      };

    case at.SET_PATIENT:
      return {
        ...state,
        selectedPatient: payload
      };

    case at.TOGGLE_MEDIA:
      const { id, checked } = payload;
      const { selectedMedia } = state;
      const media = selectedMedia;

      if (checked) media.add(id);
      else media.delete(id);

      return {
        ...state,
        selectedMedia: media
      };

    case at.FETCH_MEDIA:
      return {
        ...state,
        mediaList: [],
      };

    case at.LOAD_MESSAGES:
      return {
        ...state,
        messages: payload
      };

    case at.SET_LOADING:
      return {
        ...state,
        loading: payload,
      };

    default:
      return state;
  }
};

export default reducer;
