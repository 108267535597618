export const useStyles = ({ palette: { common } }) => ({
  video: {
    maxWidth: '100%',
    display: 'flex',
    width: '100%!important',
    height: '100%!important',
    '& video': {
      width: '100%!important',
      height: 'auto!important',
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'cover'
    }
  },
  placeholderVideo: {
    maxWidth: '100%',
    display: 'flex',
    flex: 1,
    width: '100%!important',
    height: '100%!important',
    backgroundColor: 'black'
  }
});
