import React from 'react'

const ParticipantItem = ({
  classes,
  participant,
}) => {
  return (
    <div className={classes.waitingItem}>
      <p className={classes.participantName}>{`${participant.first_name} ${participant.last_name}`}</p>
    </div>
  )
}

export default ParticipantItem
