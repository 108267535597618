import { faEllipsisV, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import AddScreenSharingButton from '../ScreenSharingButton';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Box from '@material-ui/core/Box';
import { CircleButtonWithText } from '../CircleButtonWithText/CircleButtonWithText';
import React from 'react';
import SwitchMicOnOffButton from '../SwitchMicOnOffButton/index';
import SwitchVideoOnOffButton from '../SwitchVideoOnOffButton/index';
import Toolbar from '@material-ui/core/Toolbar';
import cn from 'classnames';

const ConferenceFooter = ({ classes, forMobile, isMicMuted, onExit, openMore, setIsMicMuted, unreadMessages }) => {
  return(
    <Box className={cn(classes.footer, 'conference-footer')} p={0}>
      <AppBar position="static" style={{ background: 'transparent', boxShadow: 'none' }}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.toolbarWrapper}>
            {forMobile && (
              <div className={classes.moreContainer}>
                <CircleButtonWithText icon={faEllipsisV} onClick={openMore} text="MORE" />
                {unreadMessages > 0 && (
                  <div className={cn(classes.badge, classes.moreBadge)}>{unreadMessages}</div>
                )}
              </div>
            )}
            {!forMobile && <AddScreenSharingButton />}
            <SwitchMicOnOffButton isActive={isMicMuted} setActive={setIsMicMuted} />
            <SwitchVideoOnOffButton />
            <CircleButtonWithText
              onClick={onExit}
              icon={faSignOutAlt}
              text="exit"
              isExit
            />
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default ConferenceFooter;
