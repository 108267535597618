import * as at from './actions/actionTypes';

import APImethods, { uploadFileToS3 } from '../../api2';
import { call, put, select, takeEvery, takeLatest } from '@redux-saga/core/effects';

import { accountIdSelector } from './selectors';
import { enqueueSnackbar } from '../notifier/actions';
import { mediaListSelector } from 'modules/pastConference/selectors';
import { setMediaList } from 'modules/pastConference/actions';
import { toggleCapturedImages } from './actions';

function* sendConferenceMedia({ payload: { blob, roomId } }) {
  const accountId = yield select(accountIdSelector);
  const filename = `${accountId}-screenshot-${new Date().getTime()}.png`;

  try {
    const { data: createdMedia } = (yield call([APImethods, 'post'], '/media', {
      properties: {
        file_name: filename,
        mime_type: blob.type,
        encoding: '7bit',
        type: blob.type.startsWith('image') ? 'exam-img' : 'exam-vid'
      },
      association: 'video_conference_room',
      association_id: roomId
    })).data;

    yield call(uploadFileToS3, createdMedia.url, blob);
    yield put(enqueueSnackbar('Screenshot uploaded', {
      key: new Date().getTime() + Math.random(),
      preventDuplicate: false
    }));
    const mediaList = yield select(mediaListSelector);
    yield put(setMediaList([...mediaList, createdMedia]))
    yield put(toggleCapturedImages(true));
  } catch ({ response: { data } }) {
    yield put(enqueueSnackbar(data.errorMessage || data.error));
  }
}

function* deleteMedia({ payload }) {
  const mediaList = yield select(mediaListSelector);
  try {
    yield call([APImethods, 'delete'], `/media/${payload}`);
    yield put(enqueueSnackbar('Image deleted', {
      key: new Date().getTime() + Math.random(),
      preventDuplicate: false
    }));
    const newList = JSON.parse(JSON.stringify(mediaList))
    const deletedIndex = mediaList.findIndex(media => media.id === payload)
    newList.splice(deletedIndex, 1)
    yield put(setMediaList(newList))
  } catch ({ response: { data } }) {
    yield put(enqueueSnackbar(data.errorMessage || data.error));
  }
}

export default function* imageCaptureSaga() {
  yield takeEvery(at.IMAGE_CAPTURE_ADD, sendConferenceMedia);
  yield takeLatest(at.DELETE_MEDIA, deleteMedia);
}
