export const useStyles = () => ({
  listItem: {
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 0,
    marginBottom: 30,

    '&:hover': {
      color: '#49A6FF',
      backgroundColor: 'transparent',

      '& $listItemIcon': {
        color: '#49A6FF'
      },
      '& $listItemText': {
        color: '#49A6FF'
      }
    },
  },
  list: {
    padding: '20px',
  },
  isDisabled: {
    opacity: 0.7
  },
  isActive: {
    '& $listItemIcon': {
      color: '#49A6FF'
    },
    '& $listItemText': {
      color: '#49A6FF'
    }
  },
  listItemIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#848A8D',
    marginBottom: 10,
    fontSize: '1.25rem'
  },
  listItemText: {
    margin: 0,
    letterSpacing: 1.2,
    color: '#848A8D',
    textAlign: 'center'
  },
  listItemTextActive: {
    color: '#49A6FF'
  },
  listItemTextPrimary: {
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    fontWeight: 600
  },
  secondLevel: {
    fontSize: 16,
    lineHeight: '30px',
    marginBottom: '0',
    display: 'inline-block',
    minWidth: '80px',
    textAlign: 'left',
    '& > div': {
      textAlign: 'left',
    },
  },
  isRecord: {
    '& $listItemIcon': {
      color: '#ff0000'
    },
    '& $listItemText': {
      color: '#ff0000'
    },
    animation: 'blinker 1s linear infinite;'
  },
  '@keyframes blinker': {
    '50%': { opacity: 0 }
  },
  counter: {
    zoom: '200%',
    position: 'relative',
    left: '-8px',
    top: '-0.1rem',
    background: '#49A6FF'
  }
});
