import cn from 'classnames';
import * as React from 'react';

import styles from './styles.module.scss';

const Button = (props) => {
  const { btnClassName, disabled, secondary, noOutline, forToolbar, className, transparent, inline, skinnyBtn } = props;
  const classNames = cn(styles.button, {
    [styles.disabled]: disabled,
    [styles.secondary]: secondary,
    [styles.primary]: !secondary,
    [styles.noOutline]: noOutline,
    [styles.forToolbar]: forToolbar,
    [styles.inline]: inline,
    [styles.transparent]: transparent,
    [styles.skinnyBtn]: skinnyBtn,
    [className]: className
  });
  const buttonTypeProps = (props.submit)
    ? { type: 'submit' }
    : {};
  const buttonFormProps = (props.form)
    ? { form: props.form }
    : {};

  return (
    <span className={classNames} style={{ ...props.style }}>
      <button
        className={btnClassName}
        disabled={disabled}
        onClick={props.onClick}
        type="button"
        {...buttonTypeProps}
        {...buttonFormProps}
      >
        {props.children}
      </button>
    </span>
  );
};

export default Button;
