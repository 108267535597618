export const apiKey = {
  churnzero: {
    dev: '1!0s7B-v8Lu2NhpFmxoRh1PyP1QUtmxZYxc8ydIeLrij4t97E',
    prod: '1!ESkF7KzyClfabTMbF97hQaKfPE8JM2I6wvxI7d9w2mwt980'
  }
};

export const isMobile = (() => {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

export const twilioSettings = {
  trackSwitchOffMode: 'detected',
  dominantSpeakerPriority: 'standard',
  bandwidthProfileMode: 'collaboration',
  maxAudioBitrate: '16000',
  video: {
    contentPreferencesMode: 'auto',
    clientTrackSwitchOffControl: 'auto'
  },
};
