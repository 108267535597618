import cn from 'classnames';
import React, { Component } from 'react';
import ReactPlayer from 'react-player';

class PreviewStream extends Component {
  constructor(props) {
    super(props);
    this.menuRef = React.createRef();
  }

  render() {
    const { classes, preview: { stream } } = this.props;

    return (
      <>
        {
          stream ?
          <ReactPlayer
            ref={this.ref}
            className={cn('streamWrapper', classes.video)}
            onReady={this.onReady}
            url={stream}
            controls={false}
            playsinline
            muted
            autoPlay
            playing
          />
          : <div className={classes.placeholderVideo} />
        }
        
      </>
    )
  }
}

export default PreviewStream;
