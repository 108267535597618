import React from 'react'
import moment from 'moment';
import { useStyles } from './WaitingRoomMessage-styles';
import { withStyles } from '@material-ui/core';

const WaitingRoomMessage = ({ classes, className, error, type, host, isHost, show, appointment }) => {
  const appointmentDateString = React.useMemo(() => {
    if (appointment) {
      return moment(appointment.appointment_start).format('MMMM Do, YYYY');
    }

    return '';
  }, [appointment])

  const appointmentTimeString = React.useMemo(() => {
    if (appointment) {
      return moment(appointment.appointment_start).format('h:mmA');
    }

    return '';
  }, [appointment])
  const msg = {
    closed: 'The host has ended this call.',
    default: 'Click join to let the host know you are ready.',
    denied: 'The host did not accept you into this call.',
    removed: 'The host has removed you from this call.',
    waiting: 'Smile! The host will let you in soon.'
  }

  const msgAddon = ' If this seems like a mistake, please contact your healthcare provider.'

  const getMsg = () => {
    if (error) {
      return `Error: ${error}`
    }

    if (!show) {
      return `               `
    }

    if (isHost) {
      return 'Ready to join?';
    }
    return `${msg[type]}${showWaiting ? msgAddon : ''}`;
  }

  const showWaiting = type !== 'waiting' && type !== 'default'

  return (
    <div className={className}>
      <p className={className}>{getMsg()}</p>
      {!showWaiting && !!appointment && (
        <div className={classes.appointmentInfo}>
          <p>Appointment on {appointmentDateString} at {appointmentTimeString}</p>
          <p className={classes.participantTitle}>Participants:</p>
          <ul className={classes.participantTitle}>
            {appointment.providers.map(provider => (
              <li>{provider.first_name} {provider.last_name}</li>
            ))}
            {appointment.patient && (
              <li>{appointment.patient.first_name} {appointment.patient.last_name}</li>
            )}
          </ul>
        </div>
      )}
      {showWaiting && host && (
        <div className={classes.hostInfo}>
          <div className={classes.hostName}>{host.first_name} {host.last_name}</div>
        </div>
      )}
    </div>
  )
}

export default withStyles(useStyles)(WaitingRoomMessage)
