import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { previewSelector } from '../../selectors/videoRoom';
import PreviewStream from './PreviewStream';
import { useStyles } from './PreviewStream-styles';

const mapStateToProps = (state) => ({
  preview: previewSelector(state)
});

export default compose(
  withStyles(useStyles),
  connect(mapStateToProps, {}, null, { forwardRef: true })
)(PreviewStream);
