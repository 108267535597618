import * as at from './actionTypes';

export const addNewImage = (data) => ({
  type: at.IMAGE_CAPTURE_ADD,
  payload: data
});
export const openSidebar = (payload) => ({
  type: at.OPEN_SIDEBAR,
  payload
});

export const loadMedia = (payload) => ({
  type: at.LOAD_MEDIA,
  payload
});

export const toggleCapturedImages = (payload) => ({
  type: at.TOGGLE_CAPTURED_IMAGES,
  payload
});

export const deleteMedia = (payload) => ({
  type: at.DELETE_MEDIA,
  payload
});
