import _, { differenceBy, get, isEqual, isObject, values } from 'lodash';
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import { faCogs, faComments, faDotCircle, faQuestionCircle, faRedo, faTh, faUsers } from '@fortawesome/free-solid-svg-icons';

import DetectRTC from 'detectrtc';
import React from 'react';
import { SHARE_SCREEN } from '../saga';
import { hasPermission } from 'utils/userRole';
import { trackPubsToTracks } from 'utils/utils';
import { viewPermsSelector } from 'modules/login/selectors';

export const pipStateSelector = ({ conference: state }) => get(state, 'pip');
export const twilioConfigSelector = ({ conference: state }) => get(state, 'twilioConfig');
export const isAuthorized = ({ conference: state }) => get(state, 'isAuthorized');
export const isParticipant = ({ conference: state }) => get(state, 'isParticipant');
export const isIotConnectedSelector = ({ conference: state }) => get(state, 'isIotConnected');
export const waitingParticipantsSelector = ({ conference: state }) => get(state, 'waitingParticipants');
export const onWaitingRoomParticipantsSelector = ({ conference: state }) => get(state, 'onWaitingRoomParticipants');
export const acceptingParticipantsSelector = ({ conference: state }) => get(state, 'acceptingParticipants');
export const isJoiningSelector = ({ conference: state }) => get(state, 'isJoining');
export const isAcceptedSelector = ({ conference: state }) => get(state, 'isAccepted');
export const isDeclinedSelector = ({ conference: state }) => get(state, 'isDeclined');
export const isRemovedSelector = ({ conference: state }) => get(state, 'isRemoved');
export const isTwilioAuthorized = ({ conference: state }) => !!get(state, 'twilioToken');
export const twilioTokenSelector = ({ conference: state }) => get(state, 'twilioToken');
export const notificationSelector = ({ conference: state }) => get(state, 'notification', {});
export const isRecordSelector = ({ conference: state }) => get(state, 'isRecord');
export const feedsSelector = ({ conference: state }) => {
  return values(get(state, 'feeds', null)).filter((i) => isObject(i));
};
export const blurredTrackSelector = ({ conference: state }) => get(state, 'blurredTrackIds');
export const previewSelector = ({ conference: state }) => get(state, 'preview');
export const previewStreamSelector = ({ conference: state }) => get(state, 'preview.stream', null);

export const currentUserSelector = ({ conference: state }) => get(state, 'currentUser', {
  id: null,
  name: 'NoName'
});

const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  isEqual
);

export const videoDevicesSelector = ({ conference: state }) => get(state, 'videoDevices');

export const localFeedsSelector = ({ conference: state }) => (get(state, 'twilioLocalParticipant') ? [get(state, 'twilioLocalParticipant')] : []);
export const remoteFeedsSelector = ({ conference: state }) => get(state, 'twilioRemoteParticipants');
export const shareScreenSelector = ({ conference: state }) => get(state, 'twilioShareScreen');
export const dominantSpeakerSelector = ({ conference: state }) => get(state, 'twilioDominantSpeaker');

export const audioTrackStatsSelector = ({ conference: state }) => get(state, 'audioTrackStats');

export const layoutModeSelector = ({ conference: state }) => get(state, 'layoutMode');

export const accessibleProvidersSelector = ({ conference: state }) => get(state, 'accessibleProviders')
export const vcInfoSelector = ({ conference: state }) => get(state, 'vcInfo');
export const vcInfoErrorSelector = ({ conference: state }) => get(state, 'vcInfoError');
export const appointmentInfoSelector = ({ conference: state }) => get(state, 'appointment');

export const flippedStreamsSelector = ({ conference: state }) => get(state, 'flippedStreams');
export const mutedStreamsSelector = ({ conference: state }) => get(state, 'mutedStreams');
export const videoMutedStreamsSelector = ({ conference: state }) => get(state, 'videoMutedStreams');

export const maxY = 12;
export const maxX = 24;
export const margin = 10;

export const isHostSelector = createSelector(
  currentUserSelector,
  vcInfoSelector,
  (userInfo, vcInfo) => {
    return vcInfo?.host_id === userInfo?.GUID;
  }
);

export const layoutSelector = createSelector(
  localFeedsSelector,
  remoteFeedsSelector,
  layoutModeSelector,
  shareScreenSelector,
  dominantSpeakerSelector,
  (localFeeds, remoteFeeds, layoutMode, shareScreen, dominantSpeaker) => {
    const layout = [];
    const leftOffset = 7;

    const addItem = (feed, share, remote, videoTrackId, isMasterFeed, x, y, w, h, index = 0, isDraggable = true) => {
      const suffix = `${remoteFeeds.length}${localFeeds.length}`;

      layout.push({
        i: `${feed.sid}-${layoutMode}-${suffix}-${videoTrackId}`,
        feed,
        share,
        remote,
        videoTrackId,
        isMasterFeed,
        x,
        y,
        w,
        h,
        index,
        isDraggable,
      });
    };

    let feedStreams = [];

    let screenShareFeed = null;

    [...localFeeds, ...remoteFeeds].forEach((feed) => {
      const videoTracks = trackPubsToTracks(feed.videoTracks).filter((t) => !t.name?.startsWith(SHARE_SCREEN));

      if (videoTracks.length > 0) {
        feedStreams = feedStreams.concat(videoTracks.map((track) => ({
          feed,
          videoTrackId: track.id || track.sid,
          isMasterFeed: !track.name.startsWith('camera-extra')
        })));
      } else {
        feedStreams.push({
          feed,
          isMasterFeed: true
        });
      }
      if (shareScreen && trackPubsToTracks(feed.videoTracks)
        .filter((t) => (t.id && t.id === shareScreen.track.id) || (t.sid && t.sid === shareScreen.track.sid)).length > 0) {
        screenShareFeed = feed;
      }
    });

    const shareScreenTrackId = shareScreen && (shareScreen.track.id || shareScreen.track.sid);
    if (screenShareFeed) {
      feedStreams.push({
        feed: screenShareFeed,
        videoTrackId: shareScreenTrackId,
        isMasterFeed: false
      });
    }

    const localFeed = localFeeds[0];

    if (layoutMode === 'gallery') {
      const colCount = feedStreams.length > 3 ? 4 : feedStreams.length;
      const rowCount = Math.min(feedStreams.length, 3);
      const w = Math.ceil(maxX / colCount);
      const h = Math.ceil(maxY / rowCount);

      _.each(feedStreams, ({ feed, videoTrackId, isMasterFeed }, i) => {
        const row = Math.floor(i / colCount);
        const col = i % colCount;

        addItem(
          feed,
          shareScreenTrackId && videoTrackId === shareScreenTrackId,
          feed.sid !== localFeed?.sid,
          videoTrackId,
          isMasterFeed,
          col * w, row * h, w, h, i
        );
      });
    } else if (layoutMode === 'spotlight') {
      _.each(feedStreams, ({ feed, videoTrackId, isMasterFeed }, i) => {
        let index = feedStreams.length - i
        if (dominantSpeaker && feed.sid === dominantSpeaker.sid) {
          index = feedStreams.length + 1
        } else if (shareScreen && i === feedStreams.length - 1) {
          index = feedStreams.length + 1
        }

        addItem(
          feed,
          shareScreenTrackId && videoTrackId === shareScreenTrackId,
          feed.sid !== localFeed?.sid,
          videoTrackId,
          isMasterFeed,
          0, 0, maxX, maxY, index,
          false
        );
      });
    } else if (remoteFeeds.length && localFeeds.length) {
      const localStreams = feedStreams.filter((stream) => stream.feed.sid === localFeed?.sid);
      const remoteStreams = feedStreams.filter((stream) => stream.feed.sid !== localFeed?.sid);

      _.each(localStreams, (stream, i) => {
        const colCount = localStreams.length > 2 ? localStreams.length : 3;
        const h = Math.ceil((maxY / colCount));

        addItem(
          stream.feed,
          shareScreenTrackId && stream.videoTrackId === shareScreenTrackId,
          stream.feed.sid !== localFeed?.sid,
          stream.videoTrackId,
          stream.isMasterFeed,
          0, h * i, leftOffset, h
        );
      });
      _.each(remoteStreams, (stream, i) => {
        const colCount = Math.ceil(Math.sqrt(remoteFeeds.length));

        const h = Math.ceil((maxY / colCount));
        const w = Math.floor((maxX - leftOffset) / colCount);

        const rowPosition = Math.floor(i / colCount);
        const colPosition = i % colCount;

        addItem(
          stream.feed,
          shareScreenTrackId && stream.videoTrackId === shareScreenTrackId,
          stream.feed.sid !== localFeed?.sid,
          stream.videoTrackId,
          stream.isMasterFeed,
          colPosition * w + leftOffset, rowPosition * h, w, h, i
        );
      });
    } else if (localFeeds.length) {
      if (feedStreams.length === 1) {
        const stream = feedStreams[0];

        addItem(
          stream.feed,
          shareScreenTrackId && stream.videoTrackId === shareScreenTrackId,
          stream.feed.sid !== localFeed?.sid,
          stream.videoTrackId,
          stream.isMasterFeed,
          0, 0, maxX, maxY
        );
      } else {
        _.each(feedStreams, (stream, i) => {
          const colCount = feedStreams.length > 2 ? feedStreams.length : 3;
          const h = Math.ceil((maxY / colCount));

          addItem(
            stream.feed,
            shareScreenTrackId && stream.videoTrackId === shareScreenTrackId,
            stream.feed.sid !== localFeed?.sid,
            stream.videoTrackId,
            stream.isMasterFeed,
            0, h * i, leftOffset, h
          );
        });
      }
    }

    return layout;
  },
);

export const usedDevicesSelector = createSelector(
  localFeedsSelector,
  videoDevicesSelector,
  (feeds, devices) => {
    let usedDevices = [];
    feeds.forEach((feed) => {
      const videoTracks = trackPubsToTracks(feed.videoTracks).filter((t) => !t.name?.startsWith(SHARE_SCREEN));
      usedDevices = usedDevices.concat(videoTracks.length ? videoTracks.map((track) => track.mediaStreamTrack.label) : [])
    })
    return devices.filter(device => usedDevices.includes(device.label))
  }
);

export const notUsedDevicesSelector = createDeepEqualSelector(
  videoDevicesSelector,
  usedDevicesSelector,
  (videoDevices, UsedDevices) => differenceBy(videoDevices, UsedDevices, 'label'),
);

export const participantsSelector = createSelector(
  localFeedsSelector,
  remoteFeedsSelector,
  (localFeeds, remoteFeeds) => {
    return [...localFeeds, ...remoteFeeds];
  }
);

export const pinnedFeedSelector = ({ conference: state }) => get(state, 'pinnedFeed', null);
export const pinnedTrackIdSelector = ({ conference: state }) => get(state, 'pinnedTrackId', null);
export const cameraSelector = createSelector(
  notUsedDevicesSelector,
  viewPermsSelector,
  (notUsedDevices, viewPerms) => {
    const cameras = notUsedDevices.map(({ deviceId, label }) => ({
      id: deviceId,
      text: label,
      isActive: false,
      isDisabled: false,
    }));

    return {
      id: 'camera',
      text: 'camera',
      icon: faCogs,
      isActive: false,
      children: [
        {
          id: 'add_camera',
          text: 'Add Camera',
          isActive: false,
          children: cameras.map((e) => ({ ...e, evData: 'ADD_CAMERA' })),
          isDisabled: false,
          noPermission: !hasPermission(viewPerms, 'multi_camera_vc'),
          messageForEssential: <span>With <strong>TeleDent Professional</strong>, you can stream multiple video feeds. Connect an intraoral camera or secondary webcam to review live.</span>
        },
        {
          id: 'change_camera',
          text: 'Change Camera',
          isActive: false,
          children: cameras,
          isDisabled: false,
        }
      ],
      isDisabled: false,
    };
  }
);

export const leftSideBarSelector = createSelector(
  cameraSelector,
  isRecordSelector,
  isHostSelector,
  viewPermsSelector,
  (camera, isRecording, isHost, viewPerms) => {
    return [
      camera,
      {
        id: 'participants',
        text: 'Participants',
        icon: faUsers,
        isActive: false,
        isDisabled: false,
        className: ['desktop-only']
      },
      {
        id: 'layout',
        text: 'Layout',
        icon: faTh,
        children: [
          {
            id: 'sidebar',
            text: 'Sidebar',
          },
          {
            id: 'gallery',
            text: 'Gallery',
          },
          {
            id: 'spotlight',
            text: 'Spotlight',
          }
        ],
        isActive: false,
        isDisabled: false,
        className: ['desktop-only']
      },
      {
        id: 'switchCamera',
        text: 'Switch camera',
        icon: faRedo,
        isActive: false,
        isDisabled: true,
        className: ['mobile-only'],
      },

      {
        id: 'chat',
        text: 'chat',
        icon: faComments,
        isActive: false,
        isDisabled: false,
        className: ['desktop-only'],
      },
      {
        id: 'recording',
        text: isRecording ? 'recording' : 'record',
        icon: faDotCircle,
        isActive: false,
        isDisabled: DetectRTC.browser.name === 'Safari' || !isHost,
        forOnlyProvider: true,
        noPermission: !hasPermission(viewPerms, 'vc_record'),
        messageForEssential: <span>With <strong>TeleDent Professional</strong>, you can <strong>record</strong> this call. Review the video and add on patient records for more streamlined treatment documentation.</span>,
        disabledMessage: (
          <span>
            {isHost ? 'Recording is not supported on this browser.' : 'Only host can start/stop recording.'}
          </span>
        ),
      },
      {
        id: 'help',
        text: 'help',
        icon: faQuestionCircle,
        isActive: false,
        isDisabled: false,
      }
    ];
  }
);

export const activeFeedSelector = createSelector(
  pinnedFeedSelector,
  dominantSpeakerSelector,
  localFeedsSelector,
  remoteFeedsSelector,
  (pinnedFeed, dominantSpeaker, localFeeds, remoteFeeds) => {
    if (pinnedFeed) return pinnedFeed;
    if (dominantSpeaker) return dominantSpeaker;
    if (remoteFeeds.length > 0) return remoteFeeds[0];
    return localFeeds[0];
  }
);
