import React, { useMemo } from 'react';

import { tokenSelector } from 'modules/imageCapture/selectors';
import { useSelector } from 'react-redux';
import { vcInfoSelector } from 'modules/conference/selectors/videoRoom';
import { useStyles } from './Avatar-styles';
import { apiHost, baseURLs } from '../../../../api2';

const firstLetterFormatter = (value) => {
  const trimmed = value.trim();

  return trimmed.length === 0 ? '' : trimmed[0].toUpperCase();
};

const Avatar = ({ accountId, fontSize }) => {
  const classes = useStyles();

  const token = useSelector(tokenSelector);
  const vcInfo = useSelector(vcInfoSelector);

  const avatarUrl = useMemo(() => {
    const participant = vcInfo.participants.find((v) => v.id === accountId);

    if (participant && participant.is_patient) {
      return `${baseURLs[apiHost]}/patients/${participant.patient_id}/avatar?authToken=${token}`;
    }
    return `${baseURLs[apiHost]}/accounts/${accountId}/avatar?authToken=${token}`;
  }, [token, accountId, vcInfo]);

  const initialAvatarName = useMemo(() => {
    const participant = vcInfo.participants.find((v) => v.id === accountId);

    if (participant) {
      return `${firstLetterFormatter(participant.first_name)}${firstLetterFormatter(participant.last_name)}`;
    }

    return '';
  }, [accountId, vcInfo]);

  return (
    <div className={classes.avatarContainer}>
      <div className={classes.avatarInnerContainer}>
        <div className={classes.thumbnail} style={{ backgroundImage: `url("${avatarUrl}")` }} />
        <div className={classes.default} style={{ fontSize }}>
          {initialAvatarName}
        </div>
      </div>
    </div>
  );
};

export default Avatar;
