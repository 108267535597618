import * as at from './actions/actionTypes';
import * as chatActions from '../chat/actions/actionTypes';

import { get, omit } from 'lodash';

import { getQueryStringValue } from '../../utils/utils';
import { twilioSettings as settings } from '../../config';
import { update } from 'lodash/fp';

const initialState = {
  accessibleProviders: {
    count: 0,
    fetching: false,
    providerOptions: []
  },
  isProvider: Boolean(getQueryStringValue('isProvider')),
  isAccepted: false,
  videoDevices: [],
  audioDevices: [],
  isAuthorized: undefined,
  isParticipant: false,
  localDevices: {},
  mouthWatchDevices: {},
  feeds: {},
  flippedStreams: [],
  mutedStreams: [],
  videoMutedStreams: [],
  layoutMode: 'sidebar', // 'gallery', 'sidebar', 'spotlight',
  previousLayoutMode: 'sidebar',
  preview: {
    stream: null,
    isPreview: true,
    media: {
      video: {
      },
      audio: true,
      data: true,
    }
  },
  currentUser: {
    id: new Date().getTime(),
    name: null,
  },
  notification: {},
  pip: false,
  pinnedFeed: null,
  pinnedTrackId: null,
  blurredTrackIds: [],
  isJoining: false,
  isDeclined: false,
  isRemoved: false,

  isRecord: false,
  vcInfo: null,
  vcInfoError: null,
  appointment: null,
  roomId: null,
  twilioToken: null,
  twilioRemoteParticipants: [],
  twilioLocalParticipant: null,
  twilioShareScreen: null,
  isIotConnected: false,
  waitingParticipants: [],
  acceptingParticipants: [],
  onWaitingRoomParticipants: [],
  audioTrackStats: [],
  twilioConfig: {
    // Bandwidth Profile, Dominant Speaker, and Network Quality
    // features are only available in Small Group or Group Rooms.
    // Please set "Room Type" to "Group" or "Small Group" in your
    // Twilio Console: https://www.twilio.com/console/video/configure
    bandwidthProfile: {
      video: {
        mode: settings.bandwidthProfileMode,
        dominantSpeakerPriority: settings.dominantSpeakerPriority,
        video: settings.video,
        trackSwitchOffMode: settings.trackSwitchOffMode,
      },
    },
    dominantSpeaker: true,
    networkQuality: { local: 1, remote: 1 },

    // Comment this line if you are playing music.
    maxAudioBitrate: Number(settings.maxAudioBitrate),

    // VP8 simulcast enables the media server in a Small Group or Group Room
    // to adapt your encoded video quality for each RemoteParticipant based on
    // their individual bandwidth constraints. Simulcast should be disabled if
    // you are using Peer-to-Peer or 'Go' Rooms.
    preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],

    // @ts-ignore - Internal use only. This property is not exposed in type definitions.
    environment: process.env.REACT_APP_TWILIO_ENVIRONMENT,
  }
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case at.SET_VIDEO_DEVICES:
      return {
        ...state,
        videoDevices: payload,
      };

    case at.SET_AUDIO_DEVICES:
      return {
        ...state,
        audioDevices: payload,
      };

    case at.SET_LOCAL_DEVICES:
      return {
        ...state,
        localDevices: payload,
      };

    case at.SELECT_MOUTH_WATCH_DEVICES:
      return {
        ...state,
        mouthWatchDevices: payload,
      };

    case at.ROOM_LOCAL_FEED:
    case at.ROOM_REMOTE_FEED:
      return {
        ...state,
        feeds: {
          ...state.feeds,
          [payload.feed.id]: payload.feed
        },

      };
    case at.ROOM_REMOTE_FEED_UPDATE: {
      return (update(
        `feeds.${payload.id}`,
        (feed) => {
          const id = get(feed, 'id', false);

          if (!id) return null;
          return { ...feed, ...payload.feedOptions };
        },
        state,
      ));
    }
    case at.ROOM_LOCAL_STREAM:
    case at.ROOM_REMOTE_STREAM:

      return (update(
        `feeds.${payload.feed.id}`,
        (feed) => ({
          ...feed,
          status: payload.status,
          stream: payload.stream
        }),
        state,
      ));
    case at.ROOM_REMOTE_UPDATE_STATS:
      return (update(
        `feeds.${payload.id}`,
        (feed) => ({ ...feed, stats: payload.stats }),
        state,
      ));

    case at.ROOM_REMOTE_TALKING:
      return (update(
        `feeds.${payload.feedId}`,
        (feed) => ({
          ...feed,
          isTalking: payload.isTalking,
          lastTalkingChange: payload.lastTalkingChange
        }),
        state,
      ));

    case at.ROOM_REMOVE_FEED:

      return {
        ...state,
        feeds: omit(state.feeds, payload.id)
      };

    case at.AUDIO_DISABLED:
      return { ...state, audioDisabled: true };
    case at.ATTACH_MCU_ERROR:
    case at.ROOM_EXISTS_ERROR:
    case at.CREATE_ROOM_ERROR:
    case at.ROOM_LOCAL_FEED_ERROR:
    case at.ROOM_REMOTE_FEED_ERROR:
    case at.ROOM_ICE_ERROR:
    case at.ROOM_LOCAL_DATA_ERROR:
      return { ...state, error: action };

    case at.DESTROY_SESSION:
      return {
        ...state,
      };
    case at.CREATE_SESSION:
      return {
        ...state,
        twilioToken: null,
      };
    case at.CREATE_SESSION_SUCCESS:
      return {
        ...state,
        isAccepted: true,
        roomId: payload.roomId,
        twilioToken: payload.twilioToken
      };
    case at.CLEAN_FEEDS:
      return {
        ...state,
        feeds: []
      };
    case at.CREATE_SESSION_ERROR:
      return {
        ...state,
        twilioToken: null,
        mcu: {
          error: action,
          connected: false,
        },
      };
    case at.SET_NOTIFICATION:
      return {
        ...state,
        notification: {
          type: payload.type,
          message: payload.message,
        },
      };
    case at.TOGGLE_PIP:
      return {
        ...state,
        pip: payload.pip,
      };
    case at.RESET_NOTIFICATION:
      return {
        ...state,
        notification: {},
      };
    case at.SET_USER_INFO:
      return {
        ...state,
        isAuthorized: true,
        currentUser: {
          ...state.currentUser,
          GUID: payload.userInfo.id,
          name: payload.userInfo.display_name || `${payload.userInfo.first_name} ${payload.userInfo.last_name}`
        }
      };
    case at.NO_USER:
      return {
        ...state,
        isAuthorized: false,
        currentUser: initialState.currentUser
      };

    case at.SET_PREVIEW_STREAM:
      return {
        ...state,
        preview: {
          ...state.preview,
          stream: payload.stream
        }
      };
    case at.SET_IOT_CONNECTED:
      return {
        ...state,
        isIotConnected: payload.isIotConnected
      };

    case at.BLUR_TRACK:
      return {
        ...state,
        blurredTrackIds: [...state.blurredTrackIds, payload.videoTrackId],
      };

    case at.UNBLUR_TRACK:
      return {
        ...state,
        blurredTrackIds: state.blurredTrackIds.filter(id => id !== payload.videoTrackId),
      };

    case at.SET_PINNED_FEED:
      return {
        ...state,
        layoutMode: 'sidebar',
        previousLayoutMode: state.layoutMode,
        pinnedTrackId: payload.videoTrackId,
        pinnedFeed: payload.pinnedFeed,
      };

    case at.UNPIN_FEED:
      return {
        ...state,
        pinnedTrackId: null,
        pinnedFeed: null,
        layoutMode: state.previousLayoutMode,
      };

    case at.SET_LAYOUT_MODE:
      if (state.layoutMode === payload.mode) {
        return state;
      }
      return {
        ...state,
        previousLayoutMode: state.layoutMode,
        layoutMode: payload.mode,
        pinnedFeed: null,
        pinnedTrackId: null,
      };

    case at.SET_IS_PARTICIPANT:
      return {
        ...state,
        isParticipant: payload
      };

    case at.SET_VC_INFO:
      return { ...state, vcInfo: payload };
    case at.SET_APPOINTMENT_INFO:
      return { ...state, appointment: payload };
    case at.SET_TWILIO_LOCAL_PARTICIPANT:
      return { ...state, twilioLocalParticipant: payload.local };

    case at.SET_TWILIO_REMOTE_PARTICIPANTS:
      return { ...state, twilioRemoteParticipants: payload.remote };

    case at.ADD_TWILIO_REMOTE_PARTICIPANT:
      return { ...state, twilioRemoteParticipants: [...state.twilioRemoteParticipants, payload.remote] };

    case at.REMOVE_TWILIO_REMOTE_PARTICIPANT:
      return { ...state, twilioRemoteParticipants: state.twilioRemoteParticipants.filter((p) => p !== payload.remote) };

    case at.UPDATE_TWILIO_REMOTE_PARTICIPANTS:
      return { ...state, twilioRemoteParticipants: [...state.twilioRemoteParticipants] };

    case at.TWILIO_UPDATE_SHARE_SCREEN:
      return { ...state, twilioShareScreen: payload };

    case at.SET_TWILIO_DOMINANT_SPEAKER:
      return { ...state, twilioDominantSpeaker: payload.speaker };

    case at.SET_AUDIO_TRACK_STATS:
      return { ...state, audioTrackStats: payload };

    case at.SET_VIDEO_RECORDING:
      return { ...state, isRecord: payload };

    case at.EXIT_FROM_CALL:
      return { ...state, twilioLocalParticipant: null, twilioRemoteParticipants: [], twilioShareScreen: null };

    case at.RECEIVE_FLIPPED_STREAMS:
      return { ...state, flippedStreams: payload };

    case at.RECEIVE_MUTED_STREAMS:
      if (payload.isMuted) {
        return { ...state, mutedStreams: state.mutedStreams.filter(stream => stream !== payload.stream).concat(payload.stream) };
      }
      return { ...state, mutedStreams: state.mutedStreams.filter(stream => stream !== payload.stream) };
    case at.RECEIVE_VIDEO_MUTED_STREAMS:
      if (payload.isMuted) {
        return { ...state, videoMutedStreams: state.videoMutedStreams.filter(stream => stream !== payload.stream).concat(payload.stream) };
      }
      return { ...state, videoMutedStreams: state.videoMutedStreams.filter(stream => stream !== payload.stream) };
    case at.ACCEPT_JOIN_REQUEST:
      return {
        ...state,
        vcInfo: {
          ...state.vcInfo,
          participants: [
            ...state.vcInfo.participants.filter(p => p.id !== payload.participant.id),
            payload.participant
          ],
        },
      }
    case at.RECEIVE_JOIN_REQUEST:
      return {
        ...state,
        waitingParticipants: state.waitingParticipants.filter(id => id !== payload.participantId).concat(payload.participantId),
      }
    case at.RECEIVE_ON_WAITING_ROOM_REQUEST:
      return {
        ...state,
        onWaitingRoomParticipants: state.onWaitingRoomParticipants.filter(id => id !== payload.participantId).concat(payload.participantId),
      }
    case at.REMOVE_ON_WAITING_ROOM_PARTICIPANT:
      return {
        ...state,
        onWaitingRoomParticipants: state.onWaitingRoomParticipants.filter(id => id !== payload.participantId),
      }
    case chatActions.IOT_DECLINE_PARTICIPANT:
    case at.REMOVE_WAITING_PARTICIPANT:
      return {
        ...state,
        waitingParticipants: state.waitingParticipants.filter(id => id !== payload.participantId),
      }
    case at.SET_IS_JOINING:
      return {
        ...state,
        isJoining: payload.isJoining,
      }
    case at.SET_IS_DECLINED:
      return {
        ...state,
        isDeclined: payload.isDeclined,
      }
    case at.SET_IS_REMOVED:
      return {
        ...state,
        isRemoved: payload.isRemoved,
      }
    case at.ADD_ACCEPTING_PARTICIPANT:
      return {
        ...state,
        acceptingParticipants: state.acceptingParticipants.filter(id => id !== payload.participantId).concat(payload.participantId),
      }
    case at.REMOVE_ACCEPTING_PARTICIPANT:
      return {
        ...state,
        acceptingParticipants: state.acceptingParticipants.filter(id => id !== payload.participantId),
      }

    case at.UPDATE_HOST:
      if (state.vcInfo) {
        return {
          ...state,
          vcInfo: {
            ...state.vcInfo,
            host_id: payload.hostId,
          },
        }
      }
      return state;
    case at.CALL_CLOSED:
      if (state.vcInfo) {
        return {
          ...state,
          vcInfo: {
            ...state.vcInfo,
            status: 'Closed'
          },
        }
      }
      return state;
    case at.GET_ACCESSIBLE_PROVIDERS:
      return {
        ...state,
        accessibleProviders: {
          count: 0,
          fetching: true,
          providerOptions: []
        }
      };
    case at.GET_ACCESSIBLE_PROVIDERS_ERROR:
      return {
        ...state,
        accessibleProviders: {
          count: 0,
          fetching: false,
          providerOptions: []
        }
      };
    case at.REMOVE_ACCESSIBLE_PROVIDER:
      return {
        ...state,
        accessibleProviders: {
          ...state.accessibleProviders,
          providerOptions: state.accessibleProviders.providerOptions.filter((p) => p.value !== payload.id)
        }
      };
    case at.UPDATE_ACCESSIBLE_PROVIDERS_LIST:
      return {
        ...state,
        accessibleProviders: {
          count: payload.count,
          fetching: false,
          providerOptions: [
            ...state.accessibleProviders.providerOptions,
            ...payload.data
          ]
        }
      };
    case at.SET_VC_INFO_ERROR:
      return {
        ...state,
        vcInfoError: payload
      }
    default:
      return state;
  }
};

export default reducer;
