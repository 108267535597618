import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { baseURL } from "api2";
import cn from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactPlayer from "react-player";

import theme from "./CapturedImages.module.scss";

class CapturedImages extends Component {
  static propTypes = {
    mediaList: PropTypes.array.isRequired,
    onChatClose: PropTypes.func,
    deleteMedia: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
  };

  renderToggleButton = () => {
    return (
      <div
        className={cn(theme.toggle, {
          [theme.toggleOnly]: !this.props.isOpen,
        })}
        onClick={this.props.onToggle}
        title={!this.props.isOpen ? "Open Menu" : "Close Menu"}
      >
        <FontAwesomeIcon
          icon={this.props.isOpen ? faAngleDoubleRight : faAngleDoubleLeft}
        />
      </div>
    );
  };

  src = (id) => {
    const { token } = this.props;
    return `${baseURL}/media/${id}?authToken=${token}`;
  };

  deleteImage = (id) => {
    this.props.deleteMedia(id)
  }

  renderMedia = (id, mime_type, file_name, created_by_id) => (
    <GridListTile key={id} className={theme.listGridItem}>
      <div className={theme.listGridMediaWrapper}>
        {mime_type === "image/png" || mime_type === "image/jpeg" ? (
          <img alt={file_name} className={theme.listGridImage} src={this.src(id)} />
        ) : (
          <ReactPlayer
            className="react-player"
            url={[{ src: this.src(id), type: mime_type }]}
            controls
            width="100%"
            height="100%"
          />
        )}
        {
          <GridListTileBar
            titlePosition="top"
            actionIcon={
              <IconButton
                aria-label="Close"
                onClick={() => this.deleteImage(id)}
                size="small"
                color="secondary"
              >
                <CloseIcon />
              </IconButton>
            }
            actionPosition="right"
            className={theme.listGridTitleBar}
          />
        }
      </div>
    </GridListTile>
  )

  render() {
    const { mediaList, userInfo } = this.props;

    return mediaList.length > 0 ? (
      <Box
        className={cn(theme.container, {
          [theme.hide]: !this.props.isOpen,
        })}
      >
        {this.renderToggleButton()}
        <Typography align="center" classes={{ root: theme.title }} variant="h3">
          Captured Images
        </Typography>
        <GridList
          cellHeight={200}
          cols={1}
          className={theme.listGrid}
          spacing={8}
        >
          {mediaList.map(({ id, mime_type, file_name, created_by_id }) => (
            (created_by_id === userInfo?.id) ?
              this.renderMedia(id, mime_type, file_name, created_by_id) :
              null
          ))}
        </GridList>
      </Box>
    ) : null;
  }
}

export default CapturedImages;
