import chatReducer from './modules/chat/reducer';
import { combineReducers } from 'redux';
import conferenceReducer from './modules/conference/reducer';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import imageCapturesReducer from './modules/imageCapture/reducer';
import loginReducer from './modules/login/LoginReducer';
import notifierReducer from './modules/notifier/reducer';
import pastConferenceReducer from './modules/pastConference/reducer';
import serverConfigReducer from './modules/serverConfig/reducer';
import tokenReducer from './modules/login/TokenReducer';

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  token: tokenReducer,
  login: loginReducer,
  conference: conferenceReducer,
  chat: chatReducer,
  form: formReducer,
  notifications: notifierReducer,
  imageCapture: imageCapturesReducer,
  pastConference: pastConferenceReducer,
  config: serverConfigReducer,
});

export default rootReducer;
