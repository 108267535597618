import * as a from './actions';
import * as at from './actions/actionTypes';

import { call, put, select, takeEvery } from 'redux-saga/effects';
import { selectedMediaSelector, selectedPatientSelector, tokenSelector } from './selectors';

import APImethods2 from '../../api2';
import { enqueueSnackbar } from '../notifier/actions';
import { setToken } from '../login/actions';

function* fetchMediaHandler({ payload: { onlyImage, roomId } }) {
  yield put(a.setLoading(true));

  try {
    let res = { page: 0 };

    let data = [];

    do {
      const url = `video_conference_rooms/${roomId}/media?${onlyImage ? 'media_type=exam-img&' : ''}page=${res.page + 1}&per_page=30&order=DESC&sort=created_at`;

      res = (yield call([APImethods2, 'get'], url)).data;

      data = data.concat(res.data);
    } while (data.length < res.count);

    yield put(a.setMediaList(data));
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
  } finally {
    yield put(a.setLoading(false));
  }
}

function* fetchPatientsListHandler() {
  yield put(a.setLoading(true));
  try {
    let res = { page: 0 };

    do {
      const url = `patients?page=${res.page + 1}&per_page=30&sort=last_name&order=ASC&archived=false`;

      res = (yield call([APImethods2, 'get'], url)).data;

      yield put(a.updatePatientsList(res.data));
    } while (res.data.length === res.per_page);
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
  } finally {
    yield put(a.setLoading(false));
  }
}

function* attachMediaToPatientHandler() {
  const { value: PatientId } = yield select(selectedPatientSelector);
  const selectedMedia = yield select(selectedMediaSelector);
  const data = {
    mediaIds: selectedMedia
  };

  yield put(a.setLoading(true));

  try {
    yield call([APImethods2, 'post'], `patients/${PatientId}/attachMedias`, data);
    yield put(enqueueSnackbar('Upload successful!'));
    setTimeout(window.close, 300);
  } catch (e) {
    yield put(enqueueSnackbar('Upload failed. Please check your connection and try again.'));
    // eslint-disable-next-line
    console.log(e);
  } finally {
    yield put(a.setLoading(false));
  }
}

export default function* pastConferenceSaga() {
  const token = yield select(tokenSelector);

  if (!token) {
    const savedToken = sessionStorage.getItem('authToken');

    yield put(setToken(savedToken));
  }

  yield takeEvery(at.FETCH_MEDIA, fetchMediaHandler);
  yield takeEvery(at.FETCH_PATIENTS_LIST, fetchPatientsListHandler);
  yield takeEvery(at.ATTACH_MEDIA_TO_PATIENT, attachMediaToPatientHandler);
}
