import PropTypes from 'prop-types';
import React from 'react';
import { faVideoSlash, faVideo } from '@fortawesome/free-solid-svg-icons';
import { CircleButtonWithText } from '../CircleButtonWithText/CircleButtonWithText';

const SwicthVideoOnOffButton = ({ onClick, isActive, isDisabled }) => {
  return (
    <CircleButtonWithText
      onClick={onClick}
      icon={isActive ? faVideoSlash : faVideo}
      text="Video"
      isDisabled={isDisabled}
      isActive={isActive}
    />
  );
};

export default SwicthVideoOnOffButton;

SwicthVideoOnOffButton.propTypes = {
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func
};

SwicthVideoOnOffButton.defaultProps = {
  isActive: false,
  isDisabled: false,
  onClick: () => {}
};
