import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core';
import LeftSideBar from './LeftSideBar';
import { useStyles } from './LeftSideBar-styles';
import { leftSideBarSelector, layoutModeSelector } from '../../selectors/videoRoom';
import { currentUserSelector } from '../../../login/selectors';

const mapStateToProps = (state) => ({
  items: leftSideBarSelector(state),
  user: currentUserSelector(state),
  layoutMode: layoutModeSelector(state)
});

export default compose(
  withStyles(useStyles),
  connect(mapStateToProps, null)
)(LeftSideBar);
