import axios from 'axios';

const { origin } = window.location;
const baseURL = process.env.NODE_ENV === 'development'
  ? 'https://develop-teledent-vc.teledent.com/api' // `https://${hostname}:5001/api`
  : (`${origin}/api`);

export const instance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const api = {
  get: async (url, params) => instance.get(url, { params }),
  post: async (url, data = {}) => instance.post(url, data),
  patch: async (url, data = {}) => instance.patch(url, data),
  postFormData: async (url, data) => {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };

    return instance.post(url, data, config);
  }
};

export default api;
