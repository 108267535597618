import React, { useRef } from 'react';
import Tippy from '@tippyjs/react';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

import styles from './styles.module.scss';

const DisabledTooltip = ({ children, content, placement, enabled }) => {
  const refTrigger = useRef(null);

  if (!enabled) return <>{children}</>;

  function onChildClick(ev) {
    ev.stopPropagation();
  }

  const html = (
    <div className={styles.wrapper}>
      <div className={styles.content}>{content}</div>
    </div>
  );

  return (
    <Tippy
      arrow
      content={html}
      interactive
      placement={placement}
      theme="light"
    >
      <span ref={refTrigger} className={styles.trigger} onClick={onChildClick}>
        {children}
      </span>
    </Tippy>
  );
};

DisabledTooltip.defaultProps = {
  placement: 'top',
  enabled: true,
  disableClickOnChildren: true,
};

export default DisabledTooltip;
