import React, { Component } from 'react';

import Loading from 'components/Loading';
import MediasList from '../MediasList';
import PatientAssignment from '../PatientAssignment';
import Typography from '@material-ui/core/Typography';
import logo from '../../../../assets/teledent_horizontal.png';
import theme from './PastConference.module.css';

class PastConference extends Component {
  constructor(props) {
    super(props);
    const { token, setAuthorizationHeader } = this.props;
    const authToken = token || sessionStorage.getItem('authToken');

    setAuthorizationHeader(authToken);
  }

  componentDidMount() {
    const { fetchMedia, fetchPatientsList, match: { params: { roomId: videoSessionId } } } = this.props;
    fetchMedia(videoSessionId);
    fetchPatientsList();
  }

  handleSkipClick = () => {
    // https://stackoverflow.com/questions/10712906/window-close-doesnt-work-on-ios
    setTimeout(window.close, 300);
  };

  render() {
    const { loading } = this.props;

    if (loading) return <Loading />;

    return (
      <div className={theme.container}>
        <div className={theme.containerWrapper}>
          <div className={theme.headerWrapper}>
            <div>
              <Typography classes={{ root: theme.title }} variant="h5">
                Captured Media from This Call
              </Typography>
              <p>
                Automatically associated to the patient, this appointment and the related case.
              </p>
            </div>
            <span className={theme.skipButton} onClick={this.handleSkipClick}>
              Close
            </span>
          </div>

          <MediasList captured />

          <Typography classes={{ root: theme.title }} variant="h5">
            Unassigned Captured Media
          </Typography>
          <p>These media files are not currently associated to any patients.</p>
          <PatientAssignment />
          <MediasList />
        </div>

        <img className={`${theme.appLogo} app-logo desktop-only`} src={logo} alt="Teledent Logo" />
      </div>
    );
  }
}

export default PastConference;
