import { connect } from 'react-redux';
import PatientAssignment from './PatientAssignment';
import * as s from '../../selectors';
import { setPatient, attachMediaToPatient } from '../../actions';

const mapStateToProps = (state) => ({
  patientsOptions: s.patientsOptionsSelector(state),
  selectedPatient: s.selectedPatientSelector(state),
  selectedMedia: s.selectedMediaSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  onPatientChange: (patient) => dispatch(setPatient(patient)),
  onMediaSubmit: () => dispatch(attachMediaToPatient())
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientAssignment);
