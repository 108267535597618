import * as at from './actionTypes';

export const storeConfigs = (payload) => ({
  type: at.STORE_CONFIG,
  payload
});

export const loadConfig = (payload) => ({
  type: at.LOAD_CONFIG,
  payload
});
