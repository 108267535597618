export const ROOM_LOCAL_DATA = 'ROOM_LOCAL_DATA';
export const ROOM_DATA_CHAT = 'ROOM_DATA_CHAT';
export const ROOM_LOCAL_DATA_ERROR = 'ROOM_LOCAL_DATA_ERROR';
export const LOAD_CHAT = 'LOAD_CHAT';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const LOAD_MESSAGES = 'LOAD_MESSAGES';
export const RESET_UNREAD_MESSAGES = 'RESET_UNREAD_MESSAGES';
export const SET_LOADING = 'SET_LOADING';
export const IOT_CONNECT = 'IOT_CONNECT';
export const IOT_CLOSE = 'IOT_CLOSE';
export const IOT_SET_IS_RECORDING = 'IOT_SET_IS_RECORDING';
export const IOT_SET_FLIPPED_STREAMS = 'IOT_SET_FLIPPED_STREAMS';
export const IOT_SET_MUTED_STREAMS = 'IOT_SET_MUTED_STREAMS';
export const IOT_SET_VIDEO_MUTED_STREAMS = 'IOT_SET_VIDEO_MUTED_STREAMS';
export const IOT_REQUEST_JOIN = 'IOT_REQUEST_JOIN';
export const IOT_HOST_JOINED = 'IOT_HOST_JOINED';
export const IOT_DECLINE_PARTICIPANT = 'IOT_DECLINE_PARTICIPANT';
export const IOT_ACCEPT_PARTICIPANT = 'IOT_ACCEPT_PARTICIPANT';
export const IOT_REMOVE_PARTICIPANT = 'IOT_REMOVE_PARTICIPANT';
export const IOT_UPDATE_HOST = 'IOT_UPDATE_HOST';
export const IOT_END_CALL = 'IOT_END_CALL';
export const IOT_MUTE_PARTICIPANT = 'IOT_MUTE_PARTICIPANT';
export const IOT_ON_WAITING_ROOM = 'IOT_ON_WAITING_ROOM';
export const IOT_LEAVE_WAITING_ROOM = 'IOT_LEAVE_WAITING_ROOM';
