import * as at from './actions/actionTypes';

const initialState = {
  messages: [],
  unreadMessages: 0,
  loading: false,
  isFirstLoading: true,
  isRequested: false,
};
const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case at.ROOM_LOCAL_DATA:
      return {
        ...state,
        messages: [...state.messages, payload],
      };

    case at.ROOM_DATA_CHAT:
      return {
        ...state,
        messages: [...state.messages, payload],
        unreadMessages: state.unreadMessages + 1
      };

    case at.LOAD_MESSAGES:
      return {
        ...state,
        messages: payload,
        isFirstLoading: false
      };

    case at.RESET_UNREAD_MESSAGES:
      return {
        ...state,
        unreadMessages: 0,
      };

    case at.SET_LOADING:
      return {
        ...state,
        loading: payload
      };

    case at.IOT_REQUEST_JOIN: 
      return {
        ...state,
        isRequested: true,
      }
    default:
      return state;
  }
};

export default reducer;
