import * as at from './actions/actionTypes';

import APImethods2, { iotPrefix } from '../../api2';
import { addAcceptingParticipant, receiveEvent, receiveFlippedStreams, receiveMutedStreams, receiveVideoMutedStreams, removeAcceptingParticipant, removeWaitingParticipant, setIotConnected, setIsJoining, setRecording } from 'modules/conference/actions';
import { call, put, select, take, takeEvery, takeLatest } from '@redux-saga/core/effects';
import { connectIoT, loadLocalData, loadLocalError, loadMessages, loadRemoteData, setLoading } from './actions';

import APImethods from '../../api';
import { currentUserSelector } from 'modules/login/selectors';
import { enqueueSnackbar } from '../notifier/actions';
import { eventChannel } from '@redux-saga/core';
import { logger } from 'utils/logger';
import mqtt from 'mqtt';
import { vcInfoSelector } from 'modules/conference/selectors/videoRoom';

let mqttWS = null;
const IOT_RECONNECT_PERIOD = 5000;

function iotInitChannel(roomId, userId) {
  logger.info('Initiating iot channel', {
    step: 'iot.init',
    roomId,
    userId,
  })
  return eventChannel((emitter) => {
    mqttWS.on('connect', () => {
      logger.info('Iot server is connected')
      mqttWS.subscribe([
        `${iotPrefix}/${roomId}`,
        `${iotPrefix}/${roomId}/streams/flipped`,
        `${iotPrefix}/${roomId}/streams/muted`,
        `${iotPrefix}/${roomId}/streams/videoMuted`,
        `${iotPrefix}/${roomId}/recording`,
        `${iotPrefix}/${roomId}/event`,
      ]);
    });

    mqttWS.on('error', (e) => {
      logger.error('Iot server connection is failed', {
        step: 'iot.init.error',
        error: JSON.stringify(e)
      })
      console.log(e);

      setTimeout(() => {
        logger.info('Retrying iot server connection...', {
          step: 'iot.init.error',
          roomId,
        })
        emitter(connectIoT(roomId))
      }, IOT_RECONNECT_PERIOD)
    });

    mqttWS.on('message', (topic, payload) => {
      logger.info('Received the message through the channel', {
        step: 'iot.init.message',
        topic,
        payload
      })
      try {
        const response = JSON.parse(payload.toString('utf8'));
        
        logger.info('Parsed payload into JSON', {
          step: 'iot.init.message',
          response
        })
        switch (topic) {
          case `${iotPrefix}/${roomId}/recording`:
            emitter(setRecording(response));
            break;
          case `${iotPrefix}/${roomId}/streams/flipped`:
            emitter(receiveFlippedStreams(response));
            break;
          case `${iotPrefix}/${roomId}/streams/muted`:
            emitter(receiveMutedStreams(response));
            break;
          case `${iotPrefix}/${roomId}/streams/videoMuted`:
            emitter(receiveVideoMutedStreams(response));
            break;
          case `${iotPrefix}/${roomId}/event`:
            emitter(receiveEvent(response));
            break;
          case `${iotPrefix}/${roomId}`:
            if (response.userId !== userId) {
              emitter(loadRemoteData(response));
            }
            break;
          default:
            break;
        }
      } catch (e) {
        logger.error('Received invalid message through the channel', {
          step: 'iot.init.message',
          topic,
          payload: payload.toString('utf8')
        })
        console.error('Received Error Response', payload.toString('utf8'));
      }
    });

    return () => {
      mqttWS.end();
    };
  });
}

function* iotEventChannel({ payload: { roomId } }) {
  try {
    logger.info('Connecting to iot server', {
      step: 'iotEvent.channel.saga',
      roomId,
    })
    const { id: accountId } = yield select(currentUserSelector);
    logger.info('Got the logged in user account id', {
      step: 'iotEvent.channel.saga',
      accountId,
    })

    const { data: url } = (yield call([APImethods2, 'get'], `/video_conference_rooms/${roomId}/iot`)).data;
    logger.info('Got the iot server url', {
      step: 'iotEvent.channel.saga',
      url,
    })

    mqttWS = mqtt.connect(url, { clientId: `${iotPrefix}-${accountId}-${Date.now()}`, reconnectPeriod: 0 });
    logger.info('Connected the iot server', {
      step: 'iotEvent.channel.saga',
    })

    const channel = yield call(iotInitChannel, roomId, accountId);
    logger.info('Get the channel for the room and account', {
      step: 'iotEvent.channel.saga',
      roomId,
      accountId,
    })

    yield put(setIotConnected(true));
    while (true) {
      const action = yield take(channel);

      logger.info('Received message throught the iot channel', {
        step: 'iotEvent.channel.saga',
        action
      })
      yield put(action);
    }
  } catch (error) {
    logger.error('Failed to connect to the iot server', {
      step: 'iotEvent.channel.saga',
      error: JSON.stringify(error)
    })
    console.warn('Error:', error.message);
  }
}

function iotClose() {
  if (mqttWS) {
    mqttWS.end();
  }
}

function* loadChat({ payload }) {
  yield put(setLoading(true));

  try {
    const { data: chat } = yield call([APImethods, 'get'], `/notes/videoSession/${payload}`);

    const messages = yield chat
      .map(({ body }) => {
        try {
          return JSON.parse(body);
        } catch (e) {
          // eslint-disable-next-line
            console.log(e);
          return false;
        }
      })
      .filter((item) => item);

    yield put(loadMessages(messages));
  } catch (e) {
    yield put(enqueueSnackbar(JSON.stringify(e)));
  } finally {
    yield put(setLoading(false));
  }
}

function* sendMessage({ payload: { messageData } }) {
  const { body } = messageData;

  const { id: roomId } = yield select(vcInfoSelector);

  try {
    mqttWS.publish(`${iotPrefix}/${roomId}`, body);
  } catch (e) {
    console.log(e);
    yield put(loadLocalError(e));
  }
  yield put(loadLocalData(JSON.parse(body)));
}

function* setIsRecordingIoT({ payload: { isRecording } }) {
  const { id: roomId } = yield select(vcInfoSelector);

  try {
    mqttWS.publish(`${iotPrefix}/${roomId}/recording`, JSON.stringify(isRecording));
  } catch (e) {
    console.log(e);
  }
}

function* setFlippedStreamsIoT({ payload: { flippedStreams } }) {
  const { id: roomId } = yield select(vcInfoSelector);

  try {
    mqttWS.publish(`${iotPrefix}/${roomId}/streams/flipped`, JSON.stringify(flippedStreams));
  } catch (e) {
    console.log(e);
  }
}

function* setMutedStreamsIoT({ payload }) {
  const vcInfo = yield select(vcInfoSelector);

  if (vcInfo) {
    try {
      mqttWS.publish(`${iotPrefix}/${vcInfo.id}/streams/muted`, JSON.stringify(payload));
    } catch (e) {
      console.log(e);
    }
  }
}

function* setVideoMutedStreamsIoT({ payload }) {
  const vcInfo = yield select(vcInfoSelector);

  if (vcInfo) {
    try {
      mqttWS.publish(`${iotPrefix}/${vcInfo.id}/streams/videoMuted`, JSON.stringify(payload));
    } catch (e) {
      console.log(e);
    }
  }
}

function* requestJoinIoT() {
  const vcInfo = yield select(vcInfoSelector);
  const userInfo = yield select(currentUserSelector)
  logger.info('Requesting to join the vc through iot', {
    step: 'request.join.iot',
    vcInfo,
    userInfo
  })
  yield put(setIsJoining(true));
  if (vcInfo && userInfo) {
    const payload = {
      eventName: 'request_join',
      participantId: userInfo.id,
    };
    try {
      mqttWS.publish(`${iotPrefix}/${vcInfo.id}/event`, JSON.stringify(payload));
      logger.info('Sent request join iot event successfully', {
        step: 'request.join.iot',
        payload
      })
    } catch (e) {
      logger.error('Sending request to join iot event is failed', {
        step: 'request.join.iot',
        error: JSON.stringify(e)
      })
      console.log(e);
    }
  }
}

function* onWaitingRoomIoT() {
  const vcInfo = yield select(vcInfoSelector);
  const userInfo = yield select(currentUserSelector)
  logger.info('Sending iot event to let host know that user is on waiting room', {
    step: 'on.waiting.room.iot',
    vcInfo,
    userInfo
  })
  if (vcInfo && userInfo) {
    const payload = {
      eventName: 'on_waiting_room',
      participantId: userInfo.id,
    };
    try {
      mqttWS.publish(`${iotPrefix}/${vcInfo.id}/event`, JSON.stringify(payload));
      logger.info('Sent iot event to let host know that user is on waiting room successfully', {
        step: 'on.waiting.room.iot',
        payload
      })
    } catch (e) {
      logger.error('Failed to send iot event to let host know that user is on waiting room', {
        step: 'on.waiting.room.iot',
        error: JSON.stringify(e)
      })
      console.log(e);
    }
  }
}

function* onLeaveWaitingRoomIoT() {
  const vcInfo = yield select(vcInfoSelector);
  const userInfo = yield select(currentUserSelector)
  logger.info('Sending iot event to let host know that user is leaving waiting room', {
    step: 'leave.waiting.room.iot',
    vcInfo,
    userInfo
  })
  if (vcInfo && userInfo) {
    const payload = {
      eventName: 'leave_waiting_room',
      participantId: userInfo.id,
    };
    try {
      mqttWS.publish(`${iotPrefix}/${vcInfo.id}/event`, JSON.stringify(payload));
      logger.info('Sent iot event to let host know that user is leaving waiting room successfully', {
        step: 'leave.waiting.room.iot',
        payload
      })
    } catch (e) {
      logger.error('Failed to send iot event to let host know that user is leaving waiting room', {
        step: 'leave.waiting.room.iot',
        error: JSON.stringify(e)
      })
      console.log(e);
    }
  }
}

function* declineParticipantIoT({ payload: { participantId } }) {
  const vcInfo = yield select(vcInfoSelector);

  if (vcInfo) {
    const payload = {
      eventName: 'decline_join_request',
      participantId,
    };
    try {
      mqttWS.publish(`${iotPrefix}/${vcInfo.id}/event`, JSON.stringify(payload));
    } catch (e) {
      console.log(e);
    }
  }
}

function* muteParticipantIoT({ payload: { participantId } }) {
  const vcInfo = yield select(vcInfoSelector);

  if (vcInfo) {
    const payload = {
      eventName: 'mute_participant',
      participantId,
    };
    try {
      mqttWS.publish(`${iotPrefix}/${vcInfo.id}/event`, JSON.stringify(payload));
    } catch (e) {
      console.log(e);
    }
  }
}

function* hostJoinedIoT() {
  const vcInfo = yield select(vcInfoSelector);

  if (vcInfo) {
    const payload = {
      eventName: 'host_joined',
    };
    try {
      mqttWS.publish(`${iotPrefix}/${vcInfo.id}/event`, JSON.stringify(payload));
    } catch (e) {
      console.log(e);
    }
  }
}

function* removeParticipantIoT({ payload: { participantId } }) {
  const vcInfo = yield select(vcInfoSelector);

  if (vcInfo) {
    const payload = {
      eventName: 'remove_participant',
      participantId
    };
    try {
      mqttWS.publish(`${iotPrefix}/${vcInfo.id}/event`, JSON.stringify(payload));
    } catch (e) {
      console.log(e);
    }
  }
}

function* updateHostIoT({ payload: { hostId } }) {
  const vcInfo = yield select(vcInfoSelector);

  if (vcInfo) {
    try {
      yield call([APImethods2, 'patch'], `/video_conference_rooms/${vcInfo?.id}`, {
        conflict_resolved: true,
        conflict_update_time: null,
        data: {
          host_id: hostId
        }
      });
      const payload = {
        eventName: 'update_host',
        hostId
      };
      try {
        mqttWS.publish(`${iotPrefix}/${vcInfo.id}/event`, JSON.stringify(payload));
      } catch (e) {
        console.log(e);
      }
    } catch (e) {
      yield put(enqueueSnackbar(JSON.stringify(e), { variant: 'error', preventDuplicate: true }));
    }
  }
}

function* endCallIoT() {
  const vcInfo = yield select(vcInfoSelector);

  if (vcInfo) {
    try {
      yield call([APImethods2, 'patch'], `/video_conference_rooms/${vcInfo?.id}`, {
        conflict_resolved: true,
        conflict_update_time: null,
        data: {
          status: 'Closed'
        }
      });
      const payload = {
        eventName: 'end_call',
      };
      try {
        mqttWS.publish(`${iotPrefix}/${vcInfo.id}/event`, JSON.stringify(payload));
      } catch (e) {
        console.log(e);
      }
    } catch (e) {
      yield put(enqueueSnackbar(JSON.stringify(e), { variant: 'error', preventDuplicate: true }));
    }
  }
}

function* acceptParticipantIoT({ payload: { participant } }) {
  const vcInfo = yield select(vcInfoSelector);
  logger.info('Participant joining requested is accepted on host', {
    step: 'accept.participant.iot',
    participant
  })
  yield put(addAcceptingParticipant(participant.id));
  try {
    const {
      data:  {
        data: token
      },
    } = yield call(
      [APImethods2, 'post'],
      `video_conference_rooms/${vcInfo.id}/accept`,
      {
        user_id: participant.id
      }
    );

    logger.info('Token is created for accepted participant', {
      step: 'accept.participant.iot',
    })

    const payload = {
      eventName: 'accept_join_request',
      participantId: participant.id,
      participant,
      token
    };

    try {
      mqttWS.publish(`${iotPrefix}/${vcInfo.id}/event`, JSON.stringify(payload));
      logger.info('Sent approving participant iot event', {
        step: 'accept.participant.iot',
        payload
      })
    } catch (e) {
      console.log(e);
      logger.error('Failed to send approving participant iot event', {
        step: 'accept.participant.iot',
        error: JSON.stringify(e),
        payload
      })
    }
    yield put(removeWaitingParticipant(participant.id))
  } catch ({
    response: {
      data: { error },
    },
  }) {
    logger.error('Failed to get token for approved participant', {
      step: 'accept.participant.iot',
      error: JSON.stringify(error),
    })
    yield put(
      enqueueSnackbar(error, { variant: 'error', preventDuplicate: true })
    );
  } finally {
    yield put(removeAcceptingParticipant(participant.id));
  }
}

export default function* chatSaga() {
  yield takeEvery(at.LOAD_CHAT, loadChat);
  yield takeEvery(at.SEND_MESSAGE, sendMessage);
  yield takeEvery(at.IOT_CONNECT, iotEventChannel);
  yield takeLatest(at.IOT_CLOSE, iotClose);
  yield takeLatest(at.IOT_SET_IS_RECORDING, setIsRecordingIoT);
  yield takeLatest(at.IOT_SET_FLIPPED_STREAMS, setFlippedStreamsIoT);
  yield takeLatest(at.IOT_SET_MUTED_STREAMS, setMutedStreamsIoT);
  yield takeLatest(at.IOT_SET_VIDEO_MUTED_STREAMS, setVideoMutedStreamsIoT);
  yield takeLatest(at.IOT_REQUEST_JOIN, requestJoinIoT);
  yield takeLatest(at.IOT_ON_WAITING_ROOM, onWaitingRoomIoT);
  yield takeLatest(at.IOT_LEAVE_WAITING_ROOM, onLeaveWaitingRoomIoT);
  yield takeEvery(at.IOT_DECLINE_PARTICIPANT, declineParticipantIoT);
  yield takeEvery(at.IOT_ACCEPT_PARTICIPANT, acceptParticipantIoT);
  yield takeEvery(at.IOT_REMOVE_PARTICIPANT, removeParticipantIoT);
  yield takeLatest(at.IOT_UPDATE_HOST, updateHostIoT);
  yield takeLatest(at.IOT_HOST_JOINED, hostJoinedIoT);
  yield takeLatest(at.IOT_END_CALL, endCallIoT);
  yield takeLatest(at.IOT_MUTE_PARTICIPANT, muteParticipantIoT);
}
