import { Card, CardContent } from '@material-ui/core'
import { ChangeDevicesModal, GroupConsentPolicyModal, NoDevicesModal } from '../../../../components/shared/Modal'
import React, { Component } from 'react'
import { apiHost, baseURLs } from '../../../../api2'

import Button from '../../../../components/shared/Button'
import Checkbox from '@material-ui/core/Checkbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import GroupLogo from '../../../../components/shared/GroupLogo/GroupLogo'
import Loading from 'components/Loading'
import MicOffIcon from '@material-ui/icons/MicOff'
import PreviewStream from '../PreviewStream'
import SwitchFacingModeButton from '../SwitchFacingModeButton'
import SwitchMicOnOffButton from '../SwitchMicOnOffButton'
import SwitchVideoOnOffButton from '../SwitchVideoOnOffButton'
import WaitingRoomMessage from './WaitingRoomMessage'
import cn from 'classnames'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { logger } from 'utils/logger'
import userMediaUtils from 'utils/userMedia'

class WaitingRoom extends Component {
  state = {
    initialized: 'loading',
    changeDevicesModalOpen: false,
    cpAgreed: false,
    cpModalOpen: false,
    noDevicesOpen: false,
    deviceId: null,
    label: null,
    ppAgreed: false,
    joinedWaitingRoom: false
  }

  constructor(props) {
    super(props)
    this.menuRef = React.createRef()
  }

  get cameraOptions() {
    const { videoDevices } = this.props

    return videoDevices ? videoDevices.map(({ deviceId, label }) => ({ value: deviceId, label })) : []
  }

  get canJoin() {
    const { isAuthorized, isParticipant, isConnected, isJoining } = this.props
    const { initialized } = this.state
    return isAuthorized && isParticipant && isConnected && this.patientActiveOrAgreed && !isJoining && initialized === 'success'
  }

  get isHost() {
    const { userInfo, vcInfo } = this.props
    return userInfo?.id === vcInfo?.host_id
  }

  get patientActiveOrAgreed() {
    const { isPatientInactive } = this.props
    const { cpAgreed, ppAgreed } = this.state
    return !isPatientInactive || (ppAgreed && cpAgreed)
  }

  get shouldLoad() {
    // don't load this page for host (we'll auto connect)
    const { isAuthorized, vcInfo, vcInfoError } = this.props
    return isAuthorized && (vcInfo || vcInfoError)
  }

  get isWaiting() {
    return this.waitingRoomMsgType === 'waiting' || this.waitingRoomMsgType === 'default'
  }

  get waitingRoomMsgType() {
    const { vcInfo, isDeclined, isRemoved } = this.props
    const { joinedWaitingRoom } = this.state

    if (vcInfo?.status === 'Closed') {
      return 'closed'
    }

    if (isDeclined) {
      return 'denied'
    }

    if (isRemoved) {
      return 'removed'
    }

    if (joinedWaitingRoom) {
      return 'waiting'
    }

    return 'default'
  }

  async componentDidMount() {
    logger.info('WaitingRoom component is mounted', {
      step: 'waitingRoom.componentDidMount'
    })
    
    const { enqueueSnackbar, setDevices, setPreviewStream } = this.props
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await userMediaUtils.getMediaStream();
        setDevices()
        setPreviewStream(stream)
        logger.info('Set the preview stream', {
          step: 'waitingRoom.componentDidMount',
          stream
        })

        // eslint-disable-next-line no-empty
        if (!stream.getVideoTracks() || stream.getVideoTracks().length === 0) {
          logger.debug('Preview stream does not have video tracks', {
            step: 'waitingRoom.componentDidMount',
          })
          localStorage.removeItem('videoInputDeviceId')
          localStorage.removeItem('videoInputLabel')
        } else {
          const videoTrack = stream.getVideoTracks()[0]
          logger.debug('Got the video track', {
            step: 'waitingRoom.componentDidMount',
            videoTrack,
          })
          const deviceId = videoTrack.getSettings().deviceId
          logger.debug('Got the deviceId', {
            step: 'waitingRoom.componentDidMount',
            deviceId
          })
          this.setState({ deviceId: deviceId })
          localStorage.setItem('videoInputDeviceId', deviceId)
          localStorage.setItem('videoInputLabel', videoTrack.label)
        }

        this.setState({ initialized: 'success' })
      } catch (e) {

        this.setState({ initialized: 'error', noDevicesOpen: true })
        console.log('Error on camera/microphone devices, Details:', e.message)
        logger.error('Error on getting the preview stream', {
          step: 'waitingRoom.componentDidMount',
          error: JSON.stringify(e)
        })
        enqueueSnackbar('Problem with video/audio device ',
          { variant: 'error', preventDuplicate: true })

        localStorage.removeItem('videoInputDeviceId')
        localStorage.removeItem('videoInputLabel')
      }

    } else {
      logger.error('Browser does not support webrtc', {
        step: 'waitingRoom.componentDidMount'
      })
      enqueueSnackbar('Sorry your browser does not support webrtc',
        { variant: 'error', preventDuplicate: true })
    }
  }

  componentWillUnmount() {
    this.props.leaveWaitingRoom()
  }

  componentDidUpdate(prevProps, prevState) {
    const { isConnected, twilioToken, previewStream } = this.props
    const { initialized } = this.state

    const currentReady =  this.props.isAuthorized && this.props.isParticipant && this.props.isConnected && this.props.isIotConnected && this.state.initialized === 'success'
    const prevReady = prevProps.isAuthorized && prevProps.isParticipant && prevProps.isConnected && prevProps.isIotConnected && prevState.initialized === 'success'

    if (currentReady !== prevReady && currentReady) {
      this.props.onWaitingRoom()
    }

    if (isConnected === prevProps.isConnected
      && twilioToken === prevProps.twilioToken
      && previewStream === prevProps.previewStream
      && this.props.userInfo === prevProps.userInfo
      && this.props.vcInfo === prevProps.vcInfo
      && initialized === prevState.initialized) {
      return
    }
  }

  getError = () => {
    let error
    const { isAuthorized, isParticipant, vcInfoError } = this.props

    if (isAuthorized === false) {
      error = "You don't have permissions to join to this conference"
    }
    else if (vcInfoError) {
      error = "The link you followed has expired. If you think this is a mistake, please contact your provider"
    }
    else if (!isParticipant) {
      error = 'You are not a registered participant for this video conference'
    }

    return error
  }

  handleChangeCamera = ({ value, label }) => {
    logger.info('Active camera is changed', {
      step: 'waitingRoom.handle.change.camera',
      value,
      label
    })
    this.handleChangeMedia(value)
    localStorage.setItem('videoInputDeviceId', value)
    localStorage.setItem('videoInputLabel', label)
    this.setState({ deviceId: value, label })
  }

  handleChangeMedia = async (deviceId) => {
    const { preview: { stream } } = this.props
    stream.getVideoTracks().forEach((track) => track.stop())
    this.props.setPreviewStream(null)

    const audioDevices = await userMediaUtils.getAudioDevices()
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: audioDevices.length > 0,
        video: { deviceId: { exact: deviceId } }
      })

      this.props.setPreviewStream(stream)

      // eslint-disable-next-line no-empty
      if (!stream.getVideoTracks() || stream.getVideoTracks().length === 0) {
      } else {
        const videoTrack = stream.getVideoTracks()[0]

        this.setState({ deviceId: videoTrack.getCapabilities().deviceId })
      }
    } catch (e) {
      console.log('Error on video devices, Details:', e.message)
      this.props.enqueueSnackbar('Problem with video device',
        { variant: 'error', preventDuplicate: true })
    }
  }

  handleCheckboxChange = (event) => {
    this.setState({
      [event.target.name]: event.target.checked
    })
  }

  handleConsentPolicyClick = (e) => {
    e.preventDefault()
    this.setState({ cpModalOpen: true })
  }

  handleJoin = () => {
    const { joinTwilioRoom } = this.props;
    logger.info('Join button clicked', {
      step: 'waitingRoom.handleJoin',
    })

    if (!this.canJoin) {
      const { isAuthorized, isParticipant, isConnected, isJoining } = this.props
      logger.info('User can\'t join the call', {
        step: 'waitingRoom.handleJoin',
        isAuthorized,
        isParticipant,
        isConnected,
        patientActiveOrAgreed: this.patientActiveOrAgreed,
        isJoining
      })
      return
    }

    this.setState({ joinedWaitingRoom: true })

    if (this.isHost) {
      joinTwilioRoom();
    } else {
      this.props.requestJoinIoT();
    }
  }

  renderMicAndVidBtns = () => {
    const { classes, forMobile, isMuted, setIsMicMuted, switchFacingMode, forLandscape } = this.props
    return (
      <div className={cn(classes.micAndVidBtns, { [classes.micAndVidBtnsLandscape]: forLandscape })}>
        <SwitchMicOnOffButton isActive={isMuted} setActive={setIsMicMuted} />
        {forMobile && <SwitchFacingModeButton onClick={switchFacingMode} />}
        <SwitchVideoOnOffButton />
      </div>
    )
  }

  renderJoinBtn = () => {
    const { classes, isIotConnected } = this.props
    return (
      <Button
        btnClassName={classes.btn}
        disabled={!this.canJoin || !isIotConnected || this.props.isJoining}
        disableElevation
        onClick={this.handleJoin}
      >
        Join
      </Button>
    )
  }

  renderPreviewStream = () => {
    const { classes, isMuted, forMobile, forLandscape } = this.props
    const cardClass = cn(this.props.className, {
      [classes.card]: !forMobile,
      [classes.card_no_video]: false
    })

    return (
      <div className={cn(classes.previewStreamHolder, { [classes.previewStreamHolderLandscape]: forLandscape })}>
        <Card className={cn(cardClass, { [classes.landscapeCard]: forLandscape })}>
          {isMuted && <div className={classes.micOffIcon}><MicOffIcon /></div>}
          <CardContent className={classes.content} style={{ padding: 0 }}>
            <PreviewStream />
          </CardContent>
        </Card>
        {!forLandscape && this.renderMicAndVidBtns()}
      </div>
    )
  }

  renderChangeDevicesBtn = () => {
    const { classes } = this.props
    return (
      <Button
        btnClassName={classes.btn}
        disableElevation
        onClick={() => this.setState({ changeDevicesModalOpen: true })}
        secondary
        variant='contained'
      >
        <FontAwesomeIcon className={classes.cogIcon} size='lg' icon={faCog} />
        Change Your Camera
      </Button>
    )
  }

  renderWelcomeMsgAndDevicesBtn = () => {
    const { classes, isPatientInactive, forLandscape, forMobile, vcInfo, userInfo, appointment } = this.props

    const host = vcInfo?.participants.find(participant => participant.id === vcInfo.host_id)
    const className = cn(
      classes.welcomeMessage,
      {
        [classes.welcomeMessageError]: !this.isWaiting,
        [classes.welcomeMessageLandscape]: forLandscape
      }
    )
    return (
      <div className={classes.welcomeMessageHolder}>
        <div className={forLandscape ? classes.groupLogoContainerLandscape : classes.groupLogoContainer}>
          <GroupLogo apiHost={apiHost} baseURLs={baseURLs} groupId={userInfo?.group_id} />
        </div>
        <WaitingRoomMessage
          className={className}
          error={this.getError()}
          forMobile={forMobile}
          type={this.waitingRoomMsgType}
          host={host}
          isHost={this.isHost}
          show={!!userInfo && !!vcInfo}
          appointment={appointment}
        />
        {this.isWaiting && (
          <>
            {isPatientInactive && this.renderTermsAndConditions()}
            {!forMobile && this.renderChangeDevicesBtn()}
            <div className={cn({ [classes.joinBtn]: !forLandscape })}>
              {this.renderJoinBtn()}
            </div>
          </>
        )}
      </div>
    )
  }

  renderTermsAndConditions = () => {
    const { classes, consentPolicy } = this.props
    const { cpAgreed, cpModalOpen, ppAgreed } = this.state

    return (
      <div className={classes.terms}>
        <FormControlLabel
          checked={ppAgreed}
          classes={{
            root: classes.checkbox,
            label: classes.checkboxLabel,
          }}
          control={<Checkbox color="primary" name="ppAgreed" />}
          label={(
            <div>
              I agree to the&nbsp;
              <a
                className={classes.link}
                href="https://www.mouthwatch.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                privacy policy
              </a>
              &nbsp;and the&nbsp;
              <a
                className={classes.link}
                href="https://www.mouthwatch.com/terms-of-service/"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions
              </a>
            </div>
          )}
          onChange={this.handleCheckboxChange}
        />
        <FormControlLabel
          checked={cpAgreed}
          classes={{
            root: classes.checkbox,
            label: classes.checkboxLabel,
          }}
          control={<Checkbox color="primary" name="cpAgreed" />}
          label={(
            <div>
              I have read and understand the&nbsp;
              <span
                className={classes.link}
                onClick={this.handleConsentPolicyClick}
              >
                consent policy
              </span>
              &nbsp;and, on my own behalf or on behalf of my dependents, agree
              to receive services by Teledent.
            </div>
          )}
          onChange={this.handleCheckboxChange}
        />
        <GroupConsentPolicyModal
          isOpen={cpModalOpen}
          consentPolicies={Array.isArray(consentPolicy) ? consentPolicy : [consentPolicy]}
          close={() => this.setState({ cpModalOpen: false })}
          agree={() => {
            this.setState({ cpModalOpen: false, cpAgreed: true })
          }}
        />
      </div>
    )
  }

  render() {
    const { classes, isMuted, forLandscape } = this.props
    const { changeDevicesModalOpen, deviceId, noDevicesOpen } = this.state
    const rootClassName = cn(
      classes.root,
      {
        [classes.rootError]: !this.isWaiting,
        [classes.rootLandscape]: forLandscape
      }
    )

    return (
      <>
        {
          this.shouldLoad ?
            <div className={rootClassName}>
              {this.renderWelcomeMsgAndDevicesBtn()}
              {this.isWaiting && this.renderPreviewStream()}
              {forLandscape && this.renderMicAndVidBtns()}
              <ChangeDevicesModal
                cameraOptions={this.cameraOptions}
                classes={classes}
                close={() => this.setState({ changeDevicesModalOpen: false })}
                deviceId={deviceId}
                isMuted={isMuted}
                isOpen={changeDevicesModalOpen}
                handleChangeCamera={this.handleChangeCamera}
              />
            </div> :
            <Loading />
        }
        {this.state.initialized === 'error' && (
          <NoDevicesModal
            isOpen={noDevicesOpen}
            close={() => this.setState({ noDevicesOpen: false })}
          />
        )}
      </>

    )
  }
}

export default WaitingRoom
