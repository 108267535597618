import { Field, reduxForm } from 'redux-form';
import React, { Component } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Errors from '../Errors';
import InputBase from '@material-ui/core/InputBase';
import { applicationDomain } from 'api2';
import connect from 'react-redux/es/connect/connect';
import { loginRequest } from '../../actions';
import logo from 'assets/logo_horizontal_2020.png';
import theme from './Login.module.css';
import { withStyles } from '@material-ui/styles';

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    lineHeight: '20px',
    padding: '8px 14px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

const validate = (values) => {
  const errors = {};

  if (!values.username) {
    errors.username = 'Please enter a username';
  }

  if (!values.password) {
    errors.password = 'Please enter a password';
  }
  return errors;
};

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <div className={theme.inputContainer}>
    <label htmlFor={custom.id} className={theme.inputLabel}>
      {label}
    </label>
    <BootstrapInput {...input} {...custom} />
    {touched && error && <p className={theme.errorLabel}>{error}</p>}
  </div>
);

class Login extends Component {
  state = {
    showPassword: false
  }
  submit = (values) => {
    this.props.loginRequest(values);
  };

  toggleShowPassword = (event) => {
    event.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  render() {
    const {
      handleSubmit,
      login: { requesting, errors },
    } = this.props;
    const { showPassword } = this.state

    return (
      <Container maxWidth="xs" className={theme.container}>
        <Box display="flex" alignItems="center" flexDirection="column" mt={4}>
          <div className={theme.logo}>
            <img src={logo} alt="Teledent Logo" />
          </div>
          <div className="auth-messages">
            {!requesting && !!errors.length && (
              <Errors message="Failure to login due to:" errors={errors} />
            )}
          </div>
          <form onSubmit={handleSubmit(this.submit)}>
            <Field
              component={renderTextField}
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="off"
              autoFocus
            />
            <div className={theme.passwordContainer}>
              <Field
                component={renderTextField}
                fullWidth
                id="password"
                label="Password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                autoComplete="current-password"
              />
              <a className={theme.showHide} href="/#" onClick={this.toggleShowPassword}>{showPassword ? 'Hide' : 'Show'}</a>
            </div>
            <p className={theme.forgotAction}>
              Forgot your username?{' '}
              <a href={`${applicationDomain}/forgot-username`}>Click here</a>
            </p>
            <p className={theme.forgotAction}>
              Forgot your password?{' '}
              <a href={`${applicationDomain}/forgot-password`}>Click here</a>
            </p>
            <Button
              type="submit"
              disabled={requesting}
              fullWidth
              variant="contained"
              color="primary"
              style={{ marginTop: 20 }}
            >
              Login
            </Button>
          </form>
        </Box>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  login: state.login,
});

const connected = connect(mapStateToProps, { loginRequest })(Login);
const formed = reduxForm({
  form: 'login',
  validate,
  enableReinitialize: true,
})(connected);

export default formed;
