import { TOKEN_SET, TOKEN_UNSET } from './actions/actionTypes';

const initialSate = {
  id: null,
  token: null,
};

const tokenReducer = function clientReducer(state = initialSate, action) {
  switch (action.type) {
    case TOKEN_SET:
      return {
        token: action.token
      };

    case TOKEN_UNSET:
      return {
        id: null,
        token: null,
      };

    default:
      return state;
  }
};

export default tokenReducer;
