import { connect } from 'react-redux';
import { localFeedsSelector, previewStreamSelector } from '../../selectors/videoRoom';
import { toggleVideo } from '../../actions';
import { enqueueSnackbar } from '../../../notifier/actions';
import SwitchVideoOnOffButtonContainer from './SwitchVideoOnOffButtonContainer';

const mapStateToProps = (state) => {
  const previewStream = previewStreamSelector(state);
  const previewVideoTracks = previewStream?.getVideoTracks();
  const previewVideoTrackEnabled = previewVideoTracks?.length > 0 && previewVideoTracks[0].enabled;

  const localFeeds = localFeedsSelector(state);
  const localFeed = localFeeds[0];

  return ({
    previewStream,
    previewVideoTrackEnabled,
    localFeed
  });
};

const mapDispatchToProps = (dispatch) => ({
  onClick: () => dispatch(toggleVideo()),
  enqueueSnackbar: (message) => dispatch(enqueueSnackbar(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwitchVideoOnOffButtonContainer);
